import { useState, useEffect, useRef } from 'react'
import { differenceInSeconds } from 'date-fns'
// import usePrevious from '../../hooks/usePrevious'
import styles from './chrono.module.scss'
import bell from './bell.mp3'

export default function Chrono({isLeader, durationMin = 6, startChrono, stopChrono, chronoStartAt}){
  const [ countDown, setCountDown ] = useState({
    days : "00",
    hours : "00",
    minutes : durationMin < 10 ? "0"+durationMin : durationMin,
    seconds : "00",
  })
  const [ distanceInSeconds, setDistanceInSeconds ] = useState()
  const [ dashArray, setDashArray ] = useState("")

  const audioBellRef = useRef();
  const timerRef = useRef();
  const circleRef = useRef();

  function end(){
    console.log('end');
  }

  useEffect(()=>{
    if(chronoStartAt){
       if(timerRef.current){
          stop().then(()=>{
            console.log("when stop");
            start()
          })
       }else{
         start()
       }

    }
    if(chronoStartAt === null) stop()
  },[chronoStartAt])

  useEffect(()=>{
    return () => {
      clearInterval(timerRef.current)
    }
  },[])


  //DRAW CHRONO LINE
  useEffect(()=>{
    let circleSVG = circleRef.current
    let totalLength = circleSVG.getTotalLength()
    let secondLength = circleSVG.getTotalLength() / 60

    let secondsLeftPerMinutes;
    secondsLeftPerMinutes = (60+(distanceInSeconds%60))
    if(isNaN(secondsLeftPerMinutes)) secondsLeftPerMinutes = 0

    if(secondsLeftPerMinutes === 60){
      audioBellRef.current.play();
    }

    let dashSecond = secondLength*secondsLeftPerMinutes
    setDashArray(`${dashSecond} ${totalLength - dashSecond}`)
  },[distanceInSeconds%60])

  function start(){

    startChrono()

    // years, month, days, hours, minutes, seconds
    let endChronoDate = chronoStartAt ? new Date(chronoStartAt) : new Date()
    endChronoDate.setMinutes(endChronoDate.getMinutes() + durationMin)

    timerRef.current = setInterval(()=>{

      let nowDate = new Date();
      nowDate.setMilliseconds(0)
      let distanceInSeconds = differenceInSeconds(nowDate, endChronoDate)

      setDistanceInSeconds(distanceInSeconds)

      if( distanceInSeconds <= 0){

        distanceInSeconds = Math.abs(distanceInSeconds)
        let days = Math.floor(distanceInSeconds / 86400)
        let restInSeconds = distanceInSeconds - (days*86400)

        let hours = Math.floor(restInSeconds / 3600)
        restInSeconds = restInSeconds - ( hours * 3600)

        let minutes = Math.floor(restInSeconds / 60)
        let seconds = restInSeconds - ( minutes * 60)

        setCountDown({
          days : days < 10 ? "0"+days : days.toString(),
          hours : hours < 10 ? "0"+hours : hours.toString(),
          minutes : minutes < 10 ? "0"+minutes : minutes.toString(),
          seconds : seconds < 10 ? "0"+seconds : seconds.toString(),
        })

        if(distanceInSeconds === 0){
          end()
          clearInterval(timerRef.current)
        }

      }

    },1000)

  }

  function stop(){

    return new Promise(resolve => {
      console.log('stop');
      clearInterval(timerRef.current)
      timerRef.current = null

      stopChrono()

      setDashArray("")
      setDistanceInSeconds()
      setCountDown({
        days : "00",
        hours : "00",
        minutes : durationMin < 10 ? "0"+durationMin : durationMin,
        seconds : "00",
      })

      resolve()

    })

  }


  return (
    <div className={styles["chrono"]}>
      <div>
        <h2>CHRONO</h2>
        <div className={styles["container-chrono-count"]}>
          {countDown &&
            <p className={styles["chrono-count"]}>{`${countDown.minutes}:${countDown.seconds}`}</p>
          }
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path fill="none" strokeWidth="10" stroke="#F2F2F2"
             d="
              M 100, 100
              m -75, 0
              a 75,75 0 1,0 150,0
              a 75,75 0 1,0 -150,0
              "
            />
            <path
              ref={circleRef}
              fill="none"
              strokeWidth={ dashArray === "" ? 0 : 10}
              stroke={ dashArray === "" ? "transparent" : "#9184BF"}
              strokeDasharray={dashArray}
              d="
                M 100, 100
                m -75, 0
                a 75,75 0 1,0 150,0
                a 75,75 0 1,0 -150,0
              "
            />
          </svg>
        </div>
      </div>

      <div className={isLeader ? `${styles["chrono-controls"]}` : `${styles["chrono-controls"]} ${styles["disabled"]}`}>
        { !timerRef.current ?
          <button onClick={() => start()}>Start</button> :
          <button onClick={() => stop()}>Stop</button>
        }
      </div>
      <audio src={bell} ref={audioBellRef}/>

    </div>
  )
}
