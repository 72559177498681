import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import usePrevious from '../../../hooks/usePrevious'
import { sendBadge } from '../../../actions/teams'
import { editUserTeam, setUserTeam } from '../../../actions/user'
import { API_URL, getData } from '../../../actions'
import { ERROR_TEAMS } from '../../../actions/types'

import { getLeader } from '../../../helpers'

import iconInfoBox from '../../../assets/images/icon-infobox.svg'
import styles from './edit-team.module.scss'
import ContainerTeams from '../ContainerTeams'
import CreateBadge from '../../../components/CreateBadge'
import InputText from '../../../components/InputText'
import Loader from '../../../components/Loader'
import ModalLeader from '../../../components/ModalLeader'

export default function EditTeam({ws}){
  const [ editLoading, setEditLoading ] = useState(false)
  const [ firstCharacter, setFirstCharacter ] = useState(false)
  const [ touchFields, setTouchFields ] = useState({})

  const [ wsUpdatedTeamData, setWsUpdatedTeamData] = useState()
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const editWasLoading = usePrevious( editLoading)
  const userReducer = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const history = useHistory()
  const inputNameRef = useRef()
  const inputBaselineRef = useRef()
  const createBadgeRef = useRef()

  useEffect(()=>{
    inputNameRef.current.setValue(userReducer.team.name);
    inputBaselineRef.current.setValue(userReducer.team.baseline);
  },[])

  useEffect(()=>{
    if(editWasLoading && !editLoading){
      if(userReducer.team.inGame){
        history.goBack()
      }else{
        history.push('/teams')
      }
    }
  },[ editLoading,editWasLoading])

  useEffect(()=>{
    if(!ws) return;

    ws.addEventListener("message", listen);

    function listen(message){
      let data = JSON.parse(message.data)

      if(
        data.teamId === userReducer.user.team &&
        data.userId !== userReducer.user._id
      ){
        setWsUpdatedTeamData(data)
      }
    }

    return function cleanupListener() {
      ws.removeEventListener('message', listen)
    }


  },[ws])

  //UDPATE TEAM WITH WS DATA
  useEffect(()=>{
    if(!wsUpdatedTeamData) return;

    if(wsUpdatedTeamData.type === "vote change"){

      let url = '/team/'+wsUpdatedTeamData.teamId
      let team;
      getData( ERROR_TEAMS, url, dispatch, true).then((response)=>{
        team = response.data.team
        setUserTeam(dispatch,team,wsUpdatedTeamData.type)

        let prevLeader = getLeader(userReducer.team.votes)
        // REDIRECT IF LEADER CHANGE
        if(prevLeader === userReducer.user.email){
          let leader = getLeader(team.votes)
          if(leader !== prevLeader) history.push("/teams")
        }

      })

    }

  },[wsUpdatedTeamData])

  function handleChange(value,name){
    name === "name" && setFirstCharacter(value[0])
    setTouchFields({...touchFields, [name] : value})
  }

  function handleChangeDrop(){
    setTouchFields({...touchFields, badge : true})
  }

  async function submit(){
    if(touchFields.badge){

      delete touchFields.badge

      let formData = new FormData();
      let file = await createBadgeRef.current.getBadge()

      formData.append("file",file)
      formData.append("title", "title")
      formData.append("description", "description")
      formData.append("alt", "alt")

      let badge = await sendBadge(dispatch,formData);

      if(badge._id){
        const obj = {
          ...touchFields,
          picture : badge._id
        }

        setEditLoading(true)
        await editUserTeam(dispatch,obj,userReducer.team._id)
        setEditLoading(false)

      }

    }else{
      delete touchFields.badge

      setEditLoading(true)
      await editUserTeam(dispatch,touchFields,userReducer.team._id)
      setEditLoading(false)

    }


  }

  return (
    <>
      <ContainerTeams>
        { editLoading && <div className={styles["container-loader"]}><Loader /></div>}
        <div className={`${styles["form-team"]} ${ editLoading ? styles["disabled"] : ''}`}>
          <div className={styles["center"]}>
            <h1>Éditer votre équipe</h1>
            <p className={styles["leader-vote"]}>
              Vous êtes leader de l'équipe !
              <img src={iconInfoBox} alt="information leader" onClick={()=>setIsModalOpen(true)}/>
            </p>
          </div>
          <div className={styles["container-inputs"]}>
            <div>
              <InputText name="name" handleChange={handleChange} placeholder="Nom de l'équipe" ref={inputNameRef}/>
            </div>
            <div>
              <InputText name="baseline" handleChange={handleChange} placeholder="Devise de l'équipe" ref={inputBaselineRef}/>
            </div>
          </div>
          <CreateBadge
            ref={createBadgeRef}
            firstCharacter={firstCharacter}
            edit={true}
            handleChangeDrop={handleChangeDrop}
          />
          <button className="circular" style={{marginTop: -50}} onClick={submit}>Ok</button>
        </div>

      </ContainerTeams>
      { isModalOpen &&
        <ModalLeader toggleClose={() => setIsModalOpen(false)} />
      }
    </>
  )
}
