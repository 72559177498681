import axios from 'axios'
import { API_URL, getData, postData, putData, deleteData } from './index.js'
import {
  SIGNIN_USER,
  GET_USER,
  USER_VOTE,
  SET_USER_TEAM,
  UPDATE_USER_TEAM,
  USER_LEAVE_TEAM,
  USER_JOIN_TEAM,
  LOADING_USER,
  ERROR_USER
} from './types'

export async function signIn(dispatch,data){
  let url = '/auth/login'

  dispatch({
    type : LOADING_USER
  })

  await postData( ERROR_USER, url, dispatch, data, false).then((response)=>{
    // console.log(response.data);
  })

  dispatch({
    type : SIGNIN_USER,
    payload : true
  })

}

export async function getUser(dispatch,token){
  let url = '/profile'
  let user;

  dispatch({
    type : LOADING_USER
  })

  const requestUrl = API_URL + url;

  let config = {
     headers: {
        Authorization: `JWT ${token}`
      }
  };

  axios.get(requestUrl, config)
    .then(async (response) => {
      localStorage.setItem("token",token)
      let user = response.data.user
      let team = null;

      if(user.team){
        try {
          let res = await axios.get(API_URL+'/team/'+user.team, config)
          team = res.data.team
          delete team.updatedAt
          delete team.createdAt
          delete team.__v

        } catch (error){
          console.log(error);
        }
      }

      dispatch({
        type : GET_USER,
        payload : { user, team}
      })

    })
    .catch((error) => {
      if(error.response.status === 401){
        localStorage.removeItem('token')
        dispatch({
          type : ERROR_USER,
          payload : 'Unauthorized'
        })
      }
    });

}

export async function joinTeam(dispatch,teamID){
  let url = '/profile'
  let user;
  let team

  await putData( ERROR_USER, url, dispatch, { team : teamID }, true).then((response)=>{
    user = response.user
  })

  if(user.team){

    let config = {
       headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
    };

    try {
      let res = await axios.get(API_URL+'/team/'+user.team, config)
      team = res.data.team
      delete team.updatedAt
      delete team.createdAt
      delete team.__v

    } catch (error){
      console.log(error);
    }
  }

  dispatch({
    type : USER_JOIN_TEAM,
    payload : {teamID, user, team}
  })

}

export async function vote(dispatch,userID){
  let url = '/profile'
  let user;
  let team;

  await putData( ERROR_USER, url, dispatch, { leaderVote : userID }, true).then((response)=>{
    user = response.user
  })

  if(user.team){

    let config = {
       headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`
        }
    };

    try {
      let res = await axios.get(API_URL+'/team/'+user.team, config)
      team = res.data.team
      delete team.updatedAt
      delete team.createdAt
      delete team.__v

    } catch (error){
      console.log(error);
    }
  }

  dispatch({
    type : USER_VOTE,
    payload : {user, team}
  })


}

export async function leaveTeam(dispatch,teamID, deleteTeam){
  let url = '/profile'
  let user;

  if(deleteTeam){

    let urlDelete = '/team/'+teamID

    await deleteData( ERROR_USER, urlDelete, dispatch, true).then((response)=>{
      console.log('leaveTeam deleteData',response);
    })

    await putData( ERROR_USER, url, dispatch, { team : null }, true).then((response)=>{
      console.log('leaveTeam putData',response);

      user = response.user
    })

  }else{

    await putData( ERROR_USER, url, dispatch, { team : null }, true).then((response)=>{
      user = response.user
    })

  }

  dispatch({
    type : USER_LEAVE_TEAM,
    payload : {teamID, user, deleteTeam}
  })


}

export async function editUserTeam(dispatch,data,id){
  let url = '/team/'+id
  let team;

  return new Promise(async (resolve,reject)=>{

    await putData( ERROR_USER, url, dispatch, data, true).then((response)=>{
      team = response.team
      delete team.updatedAt
      delete team.createdAt
      delete team.__v

      dispatch({
        type : UPDATE_USER_TEAM,
        payload : team
      })

      resolve(team)
    }).catch(()=>{
      reject()
    })

  })

}

export async function setUserTeam(dispatch,data,type){
  dispatch({
    type : SET_USER_TEAM,
    payload : data,
    typeAction : type
  })
}
