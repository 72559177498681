import { useState, useEffect } from "react"
import thumb from './thumbs-up-solid.svg'
import styles from './like.module.scss'

export default function Like({likes = []}){
  const [ count, setCount ] = useState(0)

  useEffect(()=>{
    setCount(likes.length)
  },[likes])

  return (
    <div className={styles["like"]}>
      <img src={thumb} alt="like" />
      <label>{count}</label>
    </div>
  )
}
