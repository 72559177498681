import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import InputFile from '../../../components/InputFile'
import ModalHurryUp from '../../../components/ModalHurryUp'

import StepNavigation from '../../../components/StepNavigation'
import Help from '../../../components/Help'
import Loader from '../../../components/Loader'
import styles from './pitch.module.scss'
import gifPitch from '../../../assets/images/pitch.webp'



import imgArrowDown from '../../../assets/images/arrow-down.svg'
import imgBaleine from '../../../assets/images/baleine.png'
import imgMachine from '../../../assets/images/machine.png'

import iconExcel from '../../../assets/images/xcel.svg'
import iconPdf from '../../../assets/images/pdf.svg'
import iconVideo from '../../../assets/images/video.svg'

import docTerrestre from './docs/moodboard-milieu-terrestre.pdf'
import docAqua from './docs/moodboard-milieu-aquatique.pdf'
import docSouterrain from './docs/moodboard-milieu-souterrain.pdf'
import docPitch from './docs/pitch-video-best-practices-a-integrer-crazy-6-etape3-boite-outil.pdf'

import poster from './docs/poster-pitch.png'
import videoPitch from './docs/picth_video_final.mp4'

import imgTerrestre from '../../../assets/images/sur-terre.svg'
import imgAqua from '../../../assets/images/sous-leau.svg'
import imgSouterrain from '../../../assets/images/sous-terre.svg'

import { API_URL, createFile } from '../../../actions'
import { PITCH_LOADING } from '../../../actions/types'

import { editUserTeam } from '../../../actions/user'
import { getPathCurrentStep} from '../../../helpers'
import useHurryUp from '../../../hooks/useHurryUp'

export default function Pitch({isLeader}){
  const userReducer = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const history = useHistory()
  const inputFileRef = useRef()
  const modalRef = useRef()
  const [ pitch, setPitch ] = useState({})
  const [ isLoading, setIsLoading ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ preview, setPreview ] = useState()

  useHurryUp(userReducer.team,15,modalRef,"pitch")
  // useHurryUp(userReducer.team,15,modalRef,"pitch")

  // REDIRECT TO CURRENT STEP
  // useEffect(()=>{
  //   let pathCurrentStep = getPathCurrentStep(userReducer.team)
  //   if(history.location.pathname !== pathCurrentStep){
  //     console.log("redirect to pathCurrentStep", pathCurrentStep);
  //     history.push(pathCurrentStep)
  //   }
  // },[])

  //UDPATE STEP WHEN PAGE MOUNT
  useEffect(()=>{
    if(isLeader === null || isLeader === false) return
    let pitch = userReducer.team.pitch ? userReducer.team.pitch : null

    // DETECT IF WE OPEN PITCH STEP FOR THE FIRST TIME
    if(userReducer.team.step !== "pitch"){
      editUserTeam(dispatch, { step : "pitch", pitch, stepStart : new Date() } ,userReducer.team._id)
    }else{
      editUserTeam(dispatch, { step : "pitch", pitch } ,userReducer.team._id)
    }

  },[isLeader])

  //INIT PITCH
  useEffect(()=>{
    if(!userReducer.team.pitch) return;
    let pitch = userReducer.team.pitch
    setPitch(pitch)

    //SCROLL TO TOP ON MOUNT
    let container = document.querySelector(".App")
    container.scrollTo(0, 0);

  },[])

  //INIT PITCH
  useEffect(()=>{
    //SCROLL TO TOP ON MOUNT
    let container = document.querySelector(".App")
    container.scrollTo(0, 0);
  },[])

  //UPDATE PITCH
  useEffect(()=>{
    if(!userReducer.team.pitch || isLeader  === null || isLeader) return;
    let pitch = userReducer.team.pitch
    setPitch(pitch)
  },[userReducer.lastUpdate])

  function goToHelp(){
    let target = document.getElementById("3");
    let elementPosition = target.offsetTop;
    let headerOffset = 50;
    let offsetPosition = elementPosition - headerOffset;

    let containerElt = document.querySelector(".App")

    containerElt.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
  }

  async function handleChangeFile(value,name){

    let formData = new FormData()

    formData.append('title',`pitch, équipe ${userReducer.team.name}`)
    formData.append('file',value)
    formData.append('alt',`pitch, équipe ${userReducer.team.name}`)

    setIsLoading(true)
    setError(null)

    try {
      let res = await createFile(formData,dispatch,PITCH_LOADING)
      if(res.message === "file saved"){

        let updatedPitch = res.files[0]._id
        setIsLoading(false)
        setPreview({path : res.files[0].path, alt : res.files[0].alt, type : res.files[0].mimetype, id : res.files[0]._id})
        editUserTeam(dispatch, { pitch : updatedPitch } ,userReducer.team._id)
        setError(null)

      }

    } catch (err) {

      setIsLoading(false)
      inputFileRef.current.resetFile();

      if((err && err.data) && err.data?.error === "Extension not allowed."){
        setError("Le format de la vidéo n'est pas accepté.")
      }else{
        setError("Votre connexion ne vous permet d'envoyer un fichier aussi lourd !")
      }


    }

  }

  function goToProjects(){
    isLeader && editUserTeam(dispatch, { step : "result" } ,userReducer.team._id)
    history.push("/result")
  }

  function isDisabled(){

    if(
      (preview && Object.keys(preview).length > 0) ||
      Object.keys(pitch).length > 0
    ){
      return  "secondary"
    }else{
      return  "secondary disabled"
    }

  }


  // WIP : HAVE TO CHANGE PREVIEW TYPE TO VIDEO, when create file will accept video type
  // <InputFile ref={inputFileRef} accept={"video/*"} name="pitch" handleChange={handleChangeFile}/>

  return (
    <div className={styles["pitch"]} >
      <ModalHurryUp ref={modalRef}/>
      <div className={styles["background"]}>
      </div>
      <div className={styles["global"]}>
        <div className={styles["container"]}>
          <div className={"center"}>
            <h1>Pitch <span>3/3 - 20min</span></h1>
          </div>
          <StepNavigation/>
        </div>
        <section id="1">
          <div className={styles["container"]}>
            <div className={styles["col-2"]}>
              <video controls poster={poster}>
                <source src={videoPitch}
                type="video/mp4"/>
                Sorry, your browser doesn't support embedded videos.
              </video>
            </div>
            <div className={styles["col-2"]}>
              <h3>Comment ça marche ?</h3>
              <p>
                Le pitch est une courte vidéo de moins d’une minute maxi, qui synthétise de manière claire, concise et séduisante son projet.
              </p>
              <p>
                Le scénario type :<br/>
                1 – La mission :<br/>
                Choisir une accroche pour expliquer la mission qui vous a été confiée<br/>
                2 – La solution :<br/>
                Quelle solution vous apportez pour répondre à cet objectif et aux contraintes de votre univers ? Quelle est sa valeur ajoutée, ses atouts pour s’intégrer à cet univers ?<br/>
                Les options, les autres caractéristiques ? On veut tout savoir&nbsp;:)<br/>
                3 – L’équipe :<br/>
                Un mot sur votre équipe de choc ?
              </p>
              <p><b>1 Minute maxi</b></p>
              <p><b>ATTENTION, la plateforme n’accepte que des vidéos au format mp4. Utilisez un téléphone Android svp.</b></p>
              <button onClick={goToHelp}>Besoin d'aide ?</button>
            </div>
          </div>
        </section>

        <section id="2" className={styles["inputs-section"]}>
          <img src={imgMachine} alt={"machine"}/>
          <div className={styles["container"]}>
            <h3>Rendu pour cette phase : </h3>
            <p>(Ce contenu sera intégré à la présentation de votre concept, et viendra alimenter le plateau de jeu)</p>

            <div className={styles["container-inputs"]}>
              <label>Joindre votre pitch vidéo</label>
                { isLeader &&
                  <InputFile ref={inputFileRef} accept={"video/mp4"} name="pitch" handleChange={handleChangeFile}/>
                }

                { isLoading &&
                  <div className={styles["container-loader"]}>
                    <p className={styles["progress"]}>{userReducer.pitchLoading}%</p>
                    <Loader/>
                  </div>
                }
                { error && <p className={styles["error"]} >{error}</p> }

                {isLeader &&
                  <button className={isDisabled()} onClick={() => goToProjects()}>Finaliser le projet</button>
                }

                {preview &&
                  <div className={styles["preview"]}>
                    <Preview source={`${API_URL}/${preview.path}`} type={preview.type} id={preview.id}/>
                  </div>
                }

                {(!preview && pitch.path) &&
                  <div className={styles["preview"]}>
                    <Preview source={`${API_URL}/${pitch.path}`} alt={pitch.alt} type={'video/mp4'} id={pitch._id}/>
                  </div>
                }

            </div>
          </div>
        </section>

        <section id="3" className={styles["section-help"]}>
          <img src={imgBaleine} alt={"baleine"}/>
          <Help documents={[
            {type : "pdf", label : "Réussir son pitch vidéo", file : docPitch},
            {type : "", label : ""},
            {type : "discussion", label : "Joignez nos experts", experts : <ListExpert/>},
            {type : "pdf", label : "L'univers terrestre", file : docTerrestre, img : imgTerrestre },
            {type : "pdf", label : "L'univers aquatique", file : docAqua, img : imgAqua },
            {type : "pdf", label : "L'univers sous-terrain", file : docSouterrain, img : imgSouterrain }
          ]}/>
        </section>
      </div>
    </div>
  )
}

const Preview = ({type = "image", source, id, alt = "preview"}) => {
  return (
    <>
      { /^image/.test(type) &&
        <img src={source} alt={alt}/>
      }
      { /^video/.test(type)  &&
        <>
          <video key={`video-${id}`} controls>
            <source src={source} type={type} />
            Votre navigateur ne supporte pas les vidéos.
          </video>
        </>


      }
    </>
  )
}

const ListExpert = () => {
  return (
    <ul>
      <li><a target="_blank"  href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_MDkwYTkxMWQtY2ZkMi00MWJjLTk1MTItYTE0NjFkYmExZTlj%40thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%2522d183af0c-d17a-41b8-a3b1-13e45bf37a7b%2522%252c%2522Oid%2522%253a%2522a444d608-8ca9-4274-8070-44d345d37e02%2522%257d%26anon%3Dtrue&type=meetup-join&deeplinkId=a423533f-9fcc-4b12-9e8e-cc2960fd82e3&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true">Expert 1</a></li>
      <li><a target="_blank"  href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_MDdmMmQ4YWItZjc3ZS00YWEyLTg2OTctZDliMTkyNTUzMTZl%40thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%2522d183af0c-d17a-41b8-a3b1-13e45bf37a7b%2522%252c%2522Oid%2522%253a%2522a12d62b8-1020-4aa3-b0d0-79f0b4bc7fe8%2522%257d%26anon%3Dtrue&type=meetup-join&deeplinkId=917c0a55-5e5e-417d-832d-e9ddecb66818&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true">Expert 2</a></li>
      <li><a target="_blank"  href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_Y2RjYWRlM2YtYmM4NS00ZWYzLWEyOWItMDg2MzI4OTU5ZDc1%40thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%2522d183af0c-d17a-41b8-a3b1-13e45bf37a7b%2522%252c%2522Oid%2522%253a%2522060c043a-ff9f-4928-89b5-448c45778afe%2522%257d%26anon%3Dtrue&type=meetup-join&deeplinkId=8539f4d4-dbcb-46c6-9abb-6be35696222a&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true">Expert 3</a></li>
    </ul>
  )
}
