import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, NavLink, Redirect, useHistory, useParams } from "react-router-dom";
import { differenceInSeconds } from 'date-fns';
import { useTransition, animated } from 'react-spring';
import styles from "./game.module.scss";

import { dateStart } from '../../config';
import { WS_URL, getData } from '../../actions';
import { ERROR_TEAMS } from '../../actions/types';

import { doesProjectCompleted } from '../../helpers'

import { setUserTeam } from '../../actions/user'

import Badge from '../../components/Badge';
import Loader from '../../components/Loader';

import Launch from './Launch';
import Waiting from './Waiting';
import Problematic from './Problematic';
import Brainstorming from './Brainstorming';
import Crazy6 from './Crazy6';
import Pitch from './Pitch';

export default function Game ({ws}){
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const userReducer = useSelector(state => state.userReducer)
  const [remainingSteps, setRemainingSteps] = useState(null);
  const [isLeader, setIsLeader] = useState(null)
  const [firstMount, setFirstMount] = useState(true);
  const [isLaunched, setIsLaunched] = useState(false);
  const [wasLaunched, setWasLaunched] = useState(null);

  const userConnectedToWs = useRef(false);

  // IF PROJECT IS COMPLETED
  // REDIRECT TO PROJECTS
  useEffect(()=>{
    setFirstMount(false)
    // let completed = doesProjectCompleted(userReducer.team)
    if((userReducer.team && userReducer.team.step === "result")) history.push("/result")
  },[])

  // IS LAUNCHED ?
  useEffect(()=>{
    if(differenceInSeconds(new Date,dateStart) >= 0){
      setIsLaunched(true)
      setWasLaunched(true)
    }else{
      setWasLaunched(false)
    }
  },[])

  // DETERMINE WHO IS THE LEADER
  useEffect(()=>{
    if(!userReducer.team?.votes) return
    let leader = _.maxBy(Object.entries(userReducer.team.votes),_.last)
    if(leader){
      setIsLeader(userReducer.user.email === leader[0])
    }
  },[userReducer.team?.votes])


  //HANDLE MESSAGE
  useEffect(()=>{
    if(!ws) return;

    ws.addEventListener("message", listen);

    function listen (message) {
     if(message.data === `user connected` && !userConnectedToWs.current){
       // console.log('user connected');
       userConnectedToWs.current = true
       return;
     }

     if(
       message.data && message.data !== "message received" &&
       message.data !== `${userReducer.user._id} connected`
     ){

       let data = JSON.parse(message.data)

       if(data.type === "vote change" && userReducer.user.team === data.teamId){

         let url = '/team/'+data.teamId
         let team;
         getData( ERROR_TEAMS, url, dispatch, true).then((response)=>{
           team = response.data.team
           console.log(team);
           setUserTeam(dispatch,team,data.type)
         })

       }

       if(!isLeader){
         if(
           (data.type !== "likes updated" && data.type !== "vote change" && data.step !== "launching") &&
           userReducer.user.team === data.teamId
        ){
           setUserTeam(dispatch,data)
         }
       }

     }
   }

   return function cleanupListener() {
     ws.removeEventListener('message', listen)
   }

  },[ws,isLeader])

  // HANDLE RemainingSteps
  useEffect(()=>{
    if(!userReducer.team?.step){
      setRemainingSteps(["problematic"])
    }else if(userReducer.team?.step === "result"){
      setRemainingSteps(["result"])
    }else{
      let steps = [ 'launching', 'problematic' , 'idea', 'crazy6', 'pitch' ]
      let findIndexStep = steps.indexOf(userReducer.team?.step)
      let arrSteps = steps.slice(findIndexStep, steps.length)
      setRemainingSteps(arrSteps)
    }

  },[userReducer.team?.step])

  // WHEN remainingSteps CHANGE ACCORDING TO userReducer.team.step
  // REDIRECT THE NONE LEADER USER
  useEffect(()=>{
    if(isLeader === false){
      if(remainingSteps && remainingSteps[0] === "result") history.push("/result")
      if(!["problematic","idea",'crazy6','pitch'].includes(userReducer.team?.step)) return;
      let arrPathname = history.location.pathname.replace("/game/",'').split("/")

        // HANDLE CONTROL VIEW
      if(arrPathname[0] !== userReducer.team?.step){
        let path = getPathFromTeamData(userReducer.team);
        console.log("redirect to", path);
        history.push(path)
      }
    }
  },[remainingSteps,isLeader])

  // WIP
  // REDIRECT  LEADER USER
  // useEffect(()=>{
  //   if(isLeader){
  //     var group = _.groupBy(locationHistory, function (pathname) {return pathname});
  //
  //     if(group){
  //       console.log(Object.values(group));
  //       let duplicate = Object.values(group).find(d => d.length>1)
  //       console.log(duplicate);
  //       if(duplicate){
  //         let path = getPathFromTeamData(userReducer.team);
  //         history.push(path)
  //
  //       }
  //     }
  //
  //   }
  // },[isLeader,locationHistory])

  function getPathFromTeamData(data){
    let path;
    if(data.step === 'problematic'){
      let obj = data.action ? data.action : data

      let step = obj.problematic?.step ? obj.problematic.step : "action"
      path = `/game/${data.step}/${step}`
    }else{
      path = `/game/${data.step}`
    }
    return path
  }

  const transitions = useTransition(isLaunched, null, {
    from: {
      position: 'absolute',
      width: '100%',
      height : '100%',
      opacity: firstMount ? 1 : 0,
      transform: firstMount ? 'translate(0,0)' : 'translate(100%,0)'
    },
    enter: { opacity: 1, transform: 'translate(0%,0)' },
    leave: { opacity: 0, transform: 'translate(-50%,0)' }
  });

  return (
    <div className={styles["game"]} >
      <div className={styles["container-badge"]}>
        <Badge ws={ws} teamBadge/>
      </div>
      <Switch location={history.location}>
        <Route exact path='/game'>
          { typeof wasLaunched === "boolean" &&
            <>
              { wasLaunched === true ?
                <Launch ws={ws}/> :
                <>
                  {transitions.map(({ item, key, props }) => {
                    return (
                      <React.Fragment key={key}>
                        {item ?
                          <animated.div style={props}><Launch ws={ws}/></animated.div> :
                          <animated.div style={props}><Waiting launchGame={() => {setIsLaunched(true)}}/></animated.div>
                        }
                      </React.Fragment>
                    )
                  })}
                </>
              }
            </>
          }
        </Route>

        { (remainingSteps && userReducer.team) ?
          <>
            <Route exact path='/game/problematic/(action|creation|world|result)'>
              {(!remainingSteps.includes('problematic') && userReducer.team.step !== "launching") &&
                <>
                  <Redirect push to={`/game/${userReducer.team.step}`} />
                </>
              }
              <Problematic isLeader={isLeader}/>
            </Route>

            <Route exact path='/game/idea'>
              {!remainingSteps.includes('idea') &&
                <Redirect push to={`/game/${userReducer.team.step}`} />
              }
              <Brainstorming isLeader={isLeader}/>
            </Route>

            <Route exact path='/game/crazy6'>
              {!remainingSteps.includes('crazy6') &&
                <Redirect push to={`/game/${userReducer.team.step}`} />
              }
              <Crazy6 isLeader={isLeader}/>
            </Route>

            <Route exact path='/game/pitch'>
              {!remainingSteps.includes('pitch') &&
                <Redirect push to={`/game/${userReducer.team.step}`} />
              }
              <Pitch isLeader={isLeader}/>
            </Route>
          </> :  <Loader/>
        }

      </Switch>

    </div>
  )
}
