export default function Back7 ({color}){
	return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">
			<g>
				<g id="badge">
					<path style={{fill : color }} d="M49.79,99.45L6.91,74.78V25.44L49.79,0.77l42.87,24.67v49.34L49.79,99.45L49.79,99.45z M8.21,74.03
						l41.58,23.92l41.58-23.92V26.19L49.79,2.26L8.21,26.19V74.03L8.21,74.03z"/>
					<path style={{fill : color }} d="M85.42,50.11c0,19.68-15.95,35.63-35.64,35.63c-19.68,0-35.63-15.95-35.63-35.63
						S30.1,14.47,49.78,14.47C69.47,14.47,85.42,30.43,85.42,50.11z M49.78,15.5c-19.08,0-34.61,15.53-34.61,34.61
						s15.52,34.61,34.61,34.61c19.08,0,34.61-15.53,34.61-34.61S68.87,15.5,49.78,15.5z"/>
					<g>
						<path style={{fill : color }} d="M49.79,7.35c-0.72,0-1.3,0.58-1.3,1.3c0,0.71,0.58,1.3,1.3,1.3c0.72,0,1.3-0.58,1.3-1.3
							C51.08,7.93,50.5,7.35,49.79,7.35z"/>
						<path style={{fill : color }} d="M49.79,90.28c-0.72,0-1.3,0.58-1.3,1.3c0,0.71,0.58,1.3,1.3,1.3c0.72,0,1.3-0.58,1.3-1.3
							C51.08,90.86,50.5,90.28,49.79,90.28z"/>
						<path style={{fill : color }} d="M14.52,28.25c-0.62-0.36-1.41-0.14-1.77,0.47c-0.36,0.62-0.14,1.41,0.47,1.77
							c0.62,0.36,1.41,0.14,1.77-0.47C15.36,29.4,15.14,28.61,14.52,28.25z"/>
						<path style={{fill : color }} d="M86.34,69.72c-0.62-0.36-1.41-0.14-1.77,0.48c-0.36,0.62-0.15,1.41,0.47,1.77
							c0.62,0.36,1.41,0.14,1.77-0.48C87.18,70.87,86.96,70.08,86.34,69.72z"/>
						<path style={{fill : color }} d="M86.34,30.5c0.62-0.36,0.83-1.15,0.47-1.77s-1.15-0.83-1.77-0.47c-0.62,0.36-0.83,1.15-0.47,1.77
							C84.93,30.64,85.73,30.85,86.34,30.5z"/>
						<path style={{fill : color }} d="M13.23,69.72c-0.62,0.36-0.83,1.15-0.47,1.77c0.36,0.62,1.15,0.83,1.77,0.48
							c0.62-0.36,0.83-1.15,0.47-1.77C14.64,69.57,13.85,69.36,13.23,69.72z"/>
					</g>
				</g>
			</g>
		</svg>
	)
}
