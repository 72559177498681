export default function Front1({color}){
	return(
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">
		<path style={{fill : color}} id="icon_3_" d="M73.3,48.71l-1.02-0.19L73,44.68L54.92,57.04c-0.02,0.2-0.05,0.42-0.09,0.65c0,0,1.43,3.67,1.73,4.78
			c0,0,0.09,0.71,0.1,1.83l2.67,1.82l3.04-0.9l0.29,1l-2.23,0.66l0.71,0.49l-0.03-0.09l3.14-0.93l0.3,0.99l-2.4,0.71l0.68,0.46
			l2.92-0.86l0.3,0.99l-2.72,0.81l0.28,2.76l-1.03,0.1l-0.3-2.92l-0.66-0.45l0.17,2.31l-1.03,0.08l-0.23-3.06l0.1-0.01l-0.79-0.54
			l0.18,2.31L59,70.12l-0.25-3.14l-2.11-1.44c-0.16,3.53-1.28,9.2-6.39,11.86c0,0-7.79-2.8-7.44-12.07c0,0,0-0.01,0-0.01l-2.43,1.66
			l-0.25,3.14l-1.03-0.08l0.18-2.31l-0.79,0.54l0.1,0.01l-0.23,3.06l-1.03-0.08l0.18-2.31l-0.66,0.45l-0.3,2.92l-1.03-0.1l0.28-2.76
			l-2.73-0.81l0.3-0.99l2.93,0.86l0.68-0.46l-2.4-0.71l0.3-0.99L38,67.28l-0.03,0.09l0.71-0.49l-2.23-0.66l0.3-1l3.04,0.9l3.13-2.14
			c0.43-3.7,1.61-4.72,1.57-6.6c0-0.05,0-0.1,0-0.14L26.12,44.68l0.72,3.84l-1.02,0.19l-1.03-5.48l5.35-1l0.19,1.02l-3.51,0.66
			L44.51,56c0.06-1.19,0.24-1.82,0.24-1.82s-0.25-1.07-1.22-1.37c-0.97-0.3-3.92,0.05-4.79-3.26c0,0-0.56-1.83,1.22-0.76
			c1.79,1.07,4.43,0.76,4.43,0.76s0.17-0.62,0.64-0.99c-0.11-0.31-0.52-0.59-0.52-0.59c-9.77-1.78-11.56-10.59-11.61-11.66
			c-0.05-1.07,0.1-3.21-0.46-3.82c-1.48-1.58-2.34-3.11-2.5-5.35c-0.15-2.24,0.76-2.24,0.76-2.24c-0.56,4.33,2.75,6.57,2.75,6.57
			c0.36-3.62,4.08-5.5,5.4-5.6c1.32-0.1,1.02,0.46,1.02,0.46c-1.88,0.46-3.61,2.14-3.61,2.14s1.68-0.21,2.44-0.51
			c1.43-0.56,1.27-0.15,1.27-0.15c-0.51,0.86-3.87,1.68-4.53,2.29c-0.66,0.61-0.82,3.72-0.82,3.72c0.46-0.66,4.89-2.34,5.3-2.24
			c0.41,0.1-0.26,0.87-0.82,1.12c-2.75,1.32-4.48,3.16-4.48,3.16c0.05,1.93,2.29,5.19,2.29,5.19s0.96-2.8,1.02-3.61
			c0.05-0.81,0.36-0.51,0.36-0.51c0.97,0.82-0.31,5.2-0.31,5.2c0.36,1.27,3.72,2.95,4.02,2.9c0.31-0.05,0.61-2.24,0.71-3.31
			c0.1-1.07,0.31-1.73,0.61-1.63c0.31,0.1,0.21,1.43,0.31,3.77c0.1,2.34,1.53,1.68,1.53,1.68s-0.15-4.79-0.67-6.06
			c-0.5-1.27-0.15-1.12-0.15-1.12c1.78,0.36,2.44,7.49,2.44,7.49c0.46,0.12,0.79,0.81,0.98,1.34c0.32-0.05,0.6,0.01,0.79,0.29
			c0,0,0,0.61,0.56,0.71c0.56,0.1,0.67-0.46,1.12-0.77c0.15-0.1,0.4-0.08,0.68-0.02c0.17-0.54,0.53-1.41,1.05-1.55
			c0,0,0.66-7.13,2.44-7.49c0,0,0.36-0.15-0.15,1.12c-0.51,1.27-0.66,6.06-0.66,6.06s1.43,0.66,1.53-1.68c0.1-2.34,0-3.66,0.31-3.77
			c0.31-0.1,0.51,0.56,0.61,1.63c0.1,1.07,0.41,3.26,0.71,3.31c0.31,0.05,3.67-1.63,4.03-2.9c0,0-1.27-4.38-0.31-5.2
			c0,0,0.31-0.3,0.36,0.51c0.05,0.81,1.02,3.61,1.02,3.61s2.24-3.26,2.29-5.19c0,0-1.73-1.84-4.48-3.16
			c-0.56-0.25-1.22-1.02-0.82-1.12c0.41-0.1,4.84,1.58,5.3,2.24c0,0-0.15-3.1-0.81-3.72c-0.66-0.61-4.03-1.43-4.53-2.29
			c0,0-0.15-0.41,1.27,0.15c0.77,0.3,2.45,0.51,2.45,0.51s-1.73-1.68-3.62-2.14c0,0-0.31-0.56,1.02-0.46c1.33,0.1,5.04,1.99,5.4,5.6
			c0,0,3.31-2.24,2.75-6.57c0,0,0.92,0,0.77,2.24c-0.15,2.24-1.02,3.77-2.5,5.35c-0.56,0.61-0.41,2.75-0.46,3.82
			c-0.05,1.07-1.83,9.88-11.61,11.66c0,0-0.6,0.41-0.54,0.79c0.26,0.18,0.71,0.53,1.05,1.14c0,0,3.52-0.71,5.04-1.43
			c0,0,0.87,0.3,0.05,1.33c-0.81,1.02-1.73,3.36-4.53,3.36c0,0-0.97,0.87-0.87,1.38c0.06,0.29,0.27,0.69,0.39,1.29L72.3,43.91
			l-3.51-0.66l0.19-1.02l5.35,1L73.3,48.71z"/>
		</svg>

	)
}
