export default function Back2 ({color}){
  return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">
		<g>
			<g>
				<g>
					<g>
						<g>
							<path style={{fill:"#FFFFFF"}} d="M49.9,3.04l-3.1,3.6l-3.58-3.13l-2.56,4.01l-3.99-2.59l-1.96,4.33l-4.32-1.99l-1.32,4.57l-4.56-1.35
								l-0.66,4.71l-4.7-0.69l0.02,4.76l-4.76-0.02l0.69,4.7l-4.71,0.66l1.35,4.56l-4.57,1.32l1.99,4.32l-4.33,1.96l2.59,3.99
								l-4.01,2.56l3.13,3.58L2.94,50l3.6,3.1l-3.13,3.58l4.01,2.56l-2.59,3.99l4.33,1.96l-1.99,4.32l4.57,1.32l-1.35,4.56l4.71,0.66
								l-0.69,4.7l4.76-0.02l-0.02,4.76l4.7-0.69l0.66,4.71l4.56-1.35l1.32,4.57l4.32-1.99l1.96,4.33l3.99-2.59l2.56,4.01l3.58-3.13
								l3.1,3.6l3.1-3.6l3.58,3.13l2.56-4.01l3.99,2.59l1.96-4.33l4.32,1.99l1.32-4.57l4.56,1.35l0.66-4.71l4.7,0.69l-0.02-4.76
								l4.76,0.02l-0.69-4.7l4.71-0.66l-1.35-4.56l4.57-1.32l-1.99-4.32l4.33-1.96l-2.59-3.99l4.01-2.56l-3.13-3.58l3.6-3.1l-3.6-3.1
								l3.13-3.58l-4.01-2.56l2.59-3.99l-4.33-1.96l1.99-4.32l-4.57-1.32l1.35-4.56l-4.71-0.66l0.69-4.7l-4.76,0.02l0.02-4.76
								l-4.7,0.69l-0.66-4.71l-4.56,1.35l-1.32-4.57l-4.32,1.99l-1.96-4.33l-3.99,2.59l-2.56-4.01L53,6.65L49.9,3.04L49.9,3.04z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<path style={{fill: color }} d="M49.9,5.28l1.16,1.35l1.79,2.08l2.07-1.81l1.34-1.17l0.96,1.5l1.48,2.32l2.31-1.5l1.49-0.97l0.73,1.62
									l1.13,2.5l2.5-1.15l1.61-0.74l0.49,1.71l0.76,2.64l2.64-0.78l1.71-0.51l0.25,1.76l0.38,2.72l2.72-0.4l1.76-0.26l-0.01,1.78
									l-0.01,2.75l2.75-0.01l1.78-0.01l-0.26,1.76l-0.4,2.72l2.72,0.38l1.76,0.25l-0.51,1.71l-0.78,2.64l2.64,0.76l1.71,0.49
									l-0.74,1.61l-1.15,2.5l2.5,1.13l1.62,0.73l-0.97,1.49l-1.5,2.31l2.32,1.48l1.5,0.96l-1.17,1.34l-1.81,2.07l2.08,1.79L94.61,50
									l-1.35,1.16l-2.08,1.79l1.81,2.07l1.17,1.34l-1.5,0.96l-2.32,1.48l1.5,2.31l0.97,1.49l-1.62,0.73l-2.5,1.13l1.15,2.5
									l0.74,1.61l-1.71,0.49l-2.64,0.76l0.78,2.64l0.51,1.71l-1.76,0.25l-2.72,0.38l0.4,2.72l0.26,1.76l-1.78-0.01l-2.75-0.01
									l0.01,2.75l0.01,1.78l-1.76-0.26l-2.72-0.4l-0.38,2.72l-0.25,1.76l-1.71-0.51l-2.64-0.78l-0.76,2.64l-0.49,1.71l-1.61-0.74
									l-2.5-1.15l-1.13,2.5l-0.73,1.62l-1.49-0.97l-2.31-1.5l-1.48,2.32l-0.96,1.5l-1.34-1.17l-2.07-1.81l-1.79,2.08l-1.16,1.35
									l-1.16-1.35l-1.79-2.08l-2.07,1.81l-1.34,1.17l-0.96-1.5l-1.48-2.32l-2.31,1.5l-1.49,0.97l-0.73-1.62l-1.13-2.5l-2.5,1.15
									l-1.61,0.74l-0.49-1.71l-0.76-2.64l-2.64,0.78l-1.71,0.51l-0.25-1.76l-0.38-2.72l-2.72,0.4l-1.76,0.26l0.01-1.78l0.01-2.75
									l-2.75,0.01l-1.78,0.01l0.26-1.76l0.4-2.72l-2.72-0.38l-1.76-0.25l0.51-1.71l0.78-2.64l-2.64-0.76l-1.71-0.49l0.74-1.61
									l1.15-2.5l-2.5-1.13L6.99,62.6l0.97-1.49l1.5-2.31l-2.32-1.48l-1.5-0.96l1.17-1.34l1.81-2.07l-2.08-1.79L5.18,50l1.35-1.16
									l2.08-1.79l-1.81-2.07l-1.17-1.34l1.5-0.96l2.32-1.48l-1.5-2.31L6.99,37.4l1.62-0.73l2.5-1.13l-1.15-2.5l-0.74-1.61l1.71-0.49
									l2.64-0.76l-0.78-2.64l-0.51-1.71l1.76-0.25l2.72-0.38l-0.4-2.72l-0.26-1.76l1.78,0.01l2.75,0.01l-0.01-2.75l-0.01-1.78
									l1.76,0.26l2.72,0.4l0.38-2.72l0.25-1.76l1.71,0.51l2.64,0.78l0.76-2.64l0.49-1.71l1.61,0.74l2.5,1.15l1.13-2.5l0.73-1.62
									l1.49,0.97l2.31,1.5l1.48-2.32l0.96-1.5l1.34,1.17l2.07,1.81l1.79-2.08L49.9,5.28L49.9,5.28z M49.9,1.1l-3.23,3.75l-3.73-3.26
									l-2.67,4.17l-4.15-2.7l-2.04,4.51l-4.5-2.07l-1.37,4.76l-4.75-1.41l-0.68,4.9l-4.9-0.72L17.9,18l-4.95-0.02l0.72,4.9
									l-4.9,0.68l1.41,4.75l-4.76,1.37l2.07,4.5l-4.51,2.04l2.7,4.15l-4.17,2.67l3.26,3.73L1,50l3.75,3.23l-3.26,3.73l4.17,2.67
									l-2.7,4.15l4.51,2.04l-2.07,4.5l4.76,1.37l-1.41,4.75l4.9,0.68l-0.72,4.9L17.89,82l-0.02,4.95l4.9-0.72l0.68,4.9l4.75-1.41
									l1.37,4.76l4.5-2.07l2.04,4.51l4.15-2.7l2.67,4.17l3.73-3.26l3.23,3.75l3.23-3.75l3.73,3.26l2.67-4.17l4.15,2.7l2.04-4.51
									l4.5,2.07l1.37-4.76l4.75,1.41l0.68-4.9l4.9,0.72L81.9,82l4.95,0.02l-0.72-4.9l4.9-0.68l-1.41-4.75l4.76-1.37l-2.07-4.5
									l4.51-2.04l-2.7-4.15l4.17-2.67l-3.26-3.73L98.8,50l-3.75-3.23l3.26-3.73l-4.17-2.67l2.7-4.15l-4.51-2.04l2.07-4.5l-4.76-1.37
									l1.41-4.75l-4.9-0.68l0.72-4.9L81.9,18l0.02-4.95l-4.9,0.72l-0.68-4.9l-4.75,1.41l-1.37-4.76l-4.5,2.07l-2.04-4.51l-4.15,2.7
									l-2.67-4.17l-3.73,3.26L49.9,1.1L49.9,1.1z"/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<polygon style={{fill: color }} points="47.09,89.12 43.86,91.95 41.55,88.33 37.95,90.67 36.19,86.76 32.29,88.56 31.1,84.43
								26.99,85.65 26.4,81.4 22.15,82.03 22.16,77.73 17.87,77.75 18.49,73.5 14.24,72.91 15.46,68.8 11.34,67.6 13.14,63.71
								9.23,61.94 11.56,58.35 7.95,56.04 10.78,52.8 7.52,50 10.78,47.2 7.95,43.96 11.56,41.65 9.23,38.06 13.14,36.29 11.34,32.39
								15.46,31.2 14.24,27.09 18.49,26.5 17.87,22.25 22.17,22.27 22.15,17.97 26.4,18.6 26.99,14.34 31.1,15.56 32.29,11.44
								36.19,13.24 37.95,9.33 41.55,11.67 43.86,8.05 47.09,10.88 49.9,7.62 52.7,10.88 55.93,8.05 58.24,11.67 61.84,9.33
								63.61,13.24 67.5,11.44 68.69,15.57 72.81,14.35 73.4,18.6 77.65,17.97 77.63,22.27 81.93,22.25 81.3,26.5 85.55,27.09
								84.33,31.2 88.45,32.4 86.66,36.29 90.56,38.06 88.23,41.65 91.85,43.96 89.02,47.2 92.28,50 89.02,52.8 91.85,56.04
								88.23,58.35 90.57,61.94 86.66,63.71 88.45,67.61 84.33,68.8 85.55,72.91 81.3,73.5 81.92,77.75 77.63,77.73 77.65,82.03
								73.4,81.4 72.81,85.66 68.69,84.44 67.5,88.56 63.61,86.76 61.84,90.67 58.24,88.33 55.93,91.95 52.7,89.12 49.9,92.38
								"/>
						</g>
					</g>
				</g>
			</g>
			<circle style={{fill:"#FFFFFF"}} cx="49.82" cy="49.92" r="37.11"/>
		</g>
		</svg>
	)
}
