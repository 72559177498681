import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { animated, useSpring, config } from 'react-spring'
import styles from './modal.module.scss'
import imgCloseModal from '../../assets/images/close-modal.svg'

const Modal = ({children,clickableOverlay = true, defaultState = false},ref) => {
  const [ open, setOpen ] = useState(defaultState);
  const [ toggleAnimation, setToggleAnimation ] = useState(false);

  useImperativeHandle(ref,()=>({
    toggleModal : () => setOpen(!open),
    closeModal : () => setToggleAnimation(false),
    openModal : () => setOpen(true)
  }))


  const spring = useSpring({
    x: toggleAnimation ? 1 : .5,
    opacity: toggleAnimation ? 1 : 0,
    config : config.stiff,
    onRest : () => { if(!toggleAnimation) setOpen(false)}
  })

  // LOGIC USE TO FIRST RENDER THE ANIMATED PART WHEN open === true
  // THEM SET toggleAnimation to true to trigger the animation
  useEffect(()=>{
    if(open) setToggleAnimation(true)
  },[open])

  return (
    <>
      { open &&
        <>
          <animated.div style={{
              opacity: spring.opacity.interpolate(opacity => opacity)
            }}
            className={styles["modal-overlay"]}
            onClick={clickableOverlay ? () => setToggleAnimation(false) : () => {}}
          >
          </animated.div>
          <div className={styles["container-modal"]}>
            <div className={styles["modal"]}>
              <animated.div style={{
                opacity: spring.opacity.interpolate(opacity => opacity),
                transform: spring.x.interpolate(x => `scale(${x})`)
                }}
                >
                <img src={imgCloseModal} alt="close-modal" onClick={() => setToggleAnimation(false)}/>
                <div className={styles["content"]}>
                  {children}
                </div>
              </animated.div>
            </div>
          </div>


        </>
      }
    </>

  )

}

export default forwardRef(Modal)
