import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styles from './modal.module.scss'

import Modal from '../Modal'

import gif1 from './gif/hurry_up_1.gif'
import gif2 from './gif/hurry_up_2.gif'
import gif3 from './gif/hurry_up_3.gif'
import gif4 from './gif/hurry_up_4.gif'

const arrGif = [ gif1, gif2, gif3, gif4 ]

const ModalHurryUp = ({},ref) => {
  const modalRef = useRef()
  const [ indexGif , setIndexGif ] = useState(null)

  useImperativeHandle(ref,()=>({
    toggleModal : () => modalRef.current.toggleModal(),
    closeModal : () => modalRef.current.closeModal(),
    openModal : () => modalRef.current.openModal(),
  }))

  useEffect(()=>{
    let rndm = Math.floor(Math.random() * (arrGif.length-1))
    setIndexGif(rndm)
  },[])

  return (
    <>
      { indexGif !== null &&
        <Modal ref={modalRef} defaultState={false} clickableOverlay={false} >
          <div className={styles["content-modal"]}>
            <h2>Hurry up !</h2>
            <p>
              Hop hop hop,  dépêchez vous un peu,
              la communauté a hâte de voir votre travail !
            </p>
            <div className={styles["container-gif"]}>
              <img src={arrGif[indexGif]} alt="gif"/>
            </div>
          </div>
        </Modal>
      }
    </>

  )

}

export default forwardRef(ModalHurryUp)
