export default function Front5({color}) {
	return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">
			<g>
				<path style={{fill : color}} d="M63.89,55.01l3.06,2.74l-0.35,0.81c-2.64,6.09-9.38,7.23-11.9,7.44l-1.32,0.11V41.17h3.26v-3.64h-13.4v3.64h3.2v24.94
					L45.11,66c-2.52-0.21-9.31-1.34-11.94-7.44l-0.35-0.81l3.06-2.74l-10.75-5.64c0.08,4.86,0.52,11.3,1.53,14.41l3.22-3.72l0.75,1.87
					c0.03,0.07,2.75,6.71,9.67,9.98c1.55,0.73,3.2,1.64,4.93,2.69c0.94,0.58,1.88,1.19,2.79,1.81c0.63,0.43,1.26,0.87,1.88,1.31
					c0.61-0.44,1.23-0.88,1.87-1.31c0.92-0.63,1.86-1.23,2.79-1.81c1.73-1.06,3.39-1.97,4.93-2.69c6.89-3.26,9.65-9.92,9.67-9.98
					l0.75-1.87l3.22,3.72c1.01-3.11,1.45-9.55,1.53-14.41L63.89,55.01z"/>
				<path style={{fill : color}} d="M46.43,32.6v3.41h6.94v-3.52c1.72-1.15,2.86-3.11,2.86-5.34c0-3.54-2.87-6.42-6.41-6.42s-6.42,2.87-6.42,6.42
					C43.4,29.45,44.61,31.47,46.43,32.6z M49.82,24.55c1.44,0,2.6,1.17,2.6,2.61c0,0.83-0.38,1.56-0.98,2.04
					c-0.45,0.36-1.01,0.57-1.62,0.57s-1.18-0.21-1.62-0.57c-0.6-0.48-0.98-1.21-0.98-2.04C47.21,25.72,48.38,24.55,49.82,24.55z"/>
			</g>
		</svg>
	)
}
