import { useHistory } from "react-router-dom";
import styles from './backward.module.scss'
import img from './backward.svg'

export default function Backward ({to}){
  const history = useHistory()

  function goBack(){
    if(to){
      history.push(to)
    }else{
      history.goBack()
    }
  }

  return (
    <a className={styles["backward"]} onClick={goBack}>
      <img src={img} alt="retour"/>
      <span>
        Retour
      </span>
    </a>
  )
}
