import {
  BrowserRouter as Router
} from "react-router-dom";

import { Provider } from 'react-redux';
import { createStore } from 'redux'
import rootReducer from './reducers'

import Routes from './Routes/index.js'

// const store = createStore(
//     rootReducer,
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// )

const store = createStore(
    rootReducer
)


function App() {

  return (
    <Provider store={store}>
      <div className="App">
        <Router>
          <Routes/>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
