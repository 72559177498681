import { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import styles from "./textarea.module.scss";

const Textarea = ({ name, disabled = false, placeholder = "", handleChange = () => {} , maxlength = null}, ref) => {
  const [ value, setValue ] = useState("")
  const textareaRef = useRef()
  const [ style, setStyle ] = useState({})

  useEffect(()=>{
    autosize()
  },[])

  function autosize(){
    let elt = textareaRef.current
    elt.style.cssText = 'height:auto; padding:0';
    elt.style.cssText = 'height:' + elt.scrollHeight + 'px';
    setStyle({
      padding : "10px",
      height : `${elt.scrollHeight}px`
    })
  }

  useImperativeHandle(ref,()=>({
    getValue : () => value,
    getObject : () => ({ [name] : value}),
    getElement : () => ref.current,
    setValue : (str) => setValue(str)
  }))

  function handleChangeValue(e,value){
    setValue(value)
    autosize()
    handleChange && handleChange(value,name)
  }

  return (
    <textarea
      disabled={disabled}
      ref={textareaRef}
      maxLength={maxlength}
      rows='9'
      value={value}
      style={style}
      className={styles["textarea"]}
      onChange={e => handleChangeValue(e,e.target.value)}
      placeholder={placeholder}
    >
    </textarea>
  )
}

export default forwardRef(Textarea)
