import _ from 'lodash'
import { zonedTimeToUtc, utcToZonedTime, format, parseFromTimeZone } from 'date-fns-timezone'

export function parseFromTimeZoneParis(date){
  return parseFromTimeZone(date, { timeZone: 'Europe/Paris' });
}

export function getLeader(votes){

  let leader;
  if(votes){
    leader = _.maxBy(Object.entries(votes),_.last)
    leader ? leader = leader[0] : leader = null
  }
  return leader;

}

export function doesProjectCompleted(team){
  if(!team ) return false
  let completed = false

  let keywords = team.idea ? team.idea : []
  keywords = Object.values(keywords).filter(keyword => keyword !== "")

  if(
    keywords.length === 6 &&
    (team.crazy6.title && team.crazy6.description) &&
    team.pitch
  ){
    completed = true
  }

  return completed

}


export function getPathCurrentStep(team){
  let keywords = team.idea ? team.idea : []
  keywords = Object.values(keywords).filter(keyword => keyword !== "")

  let path;

  if(team.step === "problematic"){
    let step = team.problematic?.step ? team.problematic?.step : "action"
    path = '/game/problematic/'+step
  }else if(team.step){
    path = '/game/'+team.step
  }

  // if(
  //   (!team.problematic?.creation || team.problematic?.creation === "") ||
  //   (!team.problematic?.world || team.problematic?.world === "") ||
  //   (!team.problematic?.action || team.problematic?.action === "")
  // ){
  //   let step = team.problematic?.step ? team.problematic?.step : "action"
  //   path = "/game/problematic/"+step
  // }else if(keywords.length !== 6){
  //   path = "/game/idea"
  // }else if(
  //   (!team.crazy6?.title || team.crazy6?.title === "") ||
  //   (!team.crazy6?.description || team.crazy6?.description === "")
  // ){
  //   path = "/game/crazy6"
  // }else if(!team.pitch){
  //   path = "/game/pitch"
  // }


  return path
}
