import { useHistory } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import styles from './sign-in.module.scss'

import { signIn, getUser } from '../../actions/user'

import InputText from '../../components/InputText'
import Loader from '../../components/Loader'
import Backward from '../../components/Backward'
import logo from '../../assets/images/logo.svg'


export default function SignIn (){
  const history = useHistory()
  const dispatch = useDispatch()
  const userReducer = useSelector(state => state.userReducer)

  const emailRef = useRef()
  const [ isValid, setIsValid ] = useState(null)

  //INIT VALIDATION
  useEffect(()=>{
    !localStorage.getItem('token') && handleFormValidation()
  },[])

  //HANDLE ALREADY LOG
  useEffect(()=>{
    let token = localStorage.getItem('token')
    if(token) getUser(dispatch,token)
  },[])


  useEffect(()=>{
    if(userReducer.user){
      history.push('/game')
    }
  },[userReducer.user])

  function handlePressEnter(e){
    if(e.key !== "Enter" || !isValid) return;
    submit()
  }

  function submit(){
    const formData = {
      ...emailRef.current.getObject(),
    }

    signIn(dispatch,formData)

  }

  function back(){
    document.location.reload();
  }

  function handleFormValidation(name,bool){
    const stateValidation = {
      ...emailRef.current.getIsValid(),
    }

    setIsValid(Object.values(stateValidation).find(d => d === false) === undefined)
  }

  return (
    <div className={styles["sign-in"]}>
      <img src={logo} alt="logo keran"/>
      { userReducer.isLoading ?
        <Loader /> :
        <>
          { userReducer.error?.status ?
            <div className={styles["infos"]}>
              {userReducer.error?.status === 401 ?
                <>
                  <div onClick={back}>
                    <Backward to={"/"} />
                  </div>
                  <h1 style={{textAlign:'center'}}>Votre email ne semble pas être autorisé à se connecter.<br/>Merci de joindre Bleuenn RAGOT au 02.51.17.81.50</h1>
                </>
                :
                <p className={`error ${styles["error"]}`}>Une erreur est survenu</p>
              }
            </div> :
            <>
              { !localStorage.getItem('token') &&
                <>
                  { !userReducer.emailSended &&
                    <>
                      <h1>Merci de vous identifier</h1>
                      <div className={styles["container-email"]} onKeyPress={handlePressEnter}>
                        <InputText
                          name="email"
                          ref={emailRef}
                          handleFormValidation={handleFormValidation}
                          type={"email"}
                          placeholder={"Votre email"}
                        />
                      </div>

                      <button onClick={submit} className={isValid === true ? "secondary" : `secondary ${styles["disabled"]}`} >Vérifier</button>
                    </>
                  }
                  { userReducer.emailSended &&
                    <div className={`${styles["infos"]} ${styles["confirmation"]}`}>
                      <h1>Un email contenant un lien de connexion vient de vous être envoyé.</h1>
                      <h1 className={styles["highlight"]} style={{textAlign:'center'}}>À tout de suite !</h1>
                    </div>
                  }
                </>
              }
            </>
          }
        </>
      }

    </div>
  )
}
