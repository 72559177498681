import { useEffect, useState, useLayoutEffect } from 'react';
import _ from "lodash"

import { useDispatch, useSelector } from 'react-redux'

import { Link } from 'react-router-dom'
import { getTeams } from '../../actions/teams'
import { API_URL } from '../../actions'
import styles from './projects.module.scss'
import Loader from '../../components/Loader'
import Slider from '../../components/Slider'
import Badge from '../../components/Badge';
import Like from '../../components/Like';

import logo from '../../assets/images/logo.svg'

const Projects = ({showIFrame, ws}) => {
  const dispatch = useDispatch()
  const teamsReducer = useSelector(state => state.teamsReducer)
  const userReducer = useSelector(state => state.userReducer)

  const [ teams, setTeams ] = useState()
  const [ projects, setProjects ] = useState({})
  const [ onEarthHeight, setOnEarthHeight ] = useState(0)
  const [ belowSeaHeight, setBelowSeaHeight ] = useState(0)
  const [ belowEarthHeight, setBelowEarthHeight ] = useState(0)
  const [ wsUpdatedTeamData, setWsUpdatedTeamData] = useState()

  const [ perChunk, setPerChunk ] = useState(18)

  const [ offsetFrise, setOffsetFrise ] = useState(0)

  useEffect(()=>{
    getTeams(dispatch);
  },[])

  useEffect(()=>{
    let container = document.querySelector(".App")
    if(container){
      container.addEventListener('scroll',scroll)
    }

    return () => {
      container.removeEventListener('scroll',scroll)
    }

  },[])

  //HANDLE MESSAGE
  useEffect(()=>{
    if(!ws) return;

    ws.addEventListener("message", listen);

    function listen (message) {
     if(message.data === `user connected`){
       return;
     }

     if(
       message.data && message.data !== "message received" &&
       message.data !== `${userReducer.user._id} connected`
     ){

       let data = JSON.parse(message.data)
       if(data.type === "likes updated"){
         setWsUpdatedTeamData(data)
       }

     }
   }

   return function cleanupListener() {
     ws.removeEventListener('message', listen)
   }

  },[ws])

  //UPDATE TEAM WHEN WEBSOCKET CHANGE wsUpdatedTeamData
  useEffect(()=>{
    if(!teams || !wsUpdatedTeamData) return;
    if(wsUpdatedTeamData.type === "likes updated"){
      let updatedTeams = teams.map(team=>{
        if(team._id === wsUpdatedTeamData.teamId){
          return { ...team, likes : wsUpdatedTeamData.action.likes}
        }else{
          return team
        }
      })
      setWsUpdatedTeamData()
      setTeams(updatedTeams)
    }
  },[teams, wsUpdatedTeamData])


  useEffect(()=>{
    //SCROLL TO TOP ON MOUNT
    let container = document.querySelector(".App")
    container.scrollTo(0, 0);
  },[])

  useEffect(()=>{
    setTeams(teamsReducer.teams)
  },[teamsReducer.teams])

  useEffect(()=>{
    if(!teams || !perChunk) return;
    let teamsEnded = teams.filter(team => {
      return (
        ( team.problematic.creation !== "" && team.problematic.world !== "" && team.problematic.action !== "") &&
        ( team.crazy6.title !== "" && team.crazy6.description !== "" && team.crazy6.picture !== "") &&
        team.pitch
      )
    })

    if(teamsEnded){
      let obj = {
        onEarth : [],
        belowSea : [],
        belowEarth : []
      }

      teamsEnded.forEach((project)=>{
        let world;
        switch (project.problematic.world) {
          case "sur terre":
            world = "onEarth"
            break;
          case "sur-terre":
            world = "onEarth"
            break;
          case "sur ou sous l'eau":
            world = "belowSea"
            break;
          case "sous-terre":
            world = "belowEarth"
            break;
        }

        obj[world].push(project)

      })

      // SPLIT INTO CHUNK
      Object.keys(obj).forEach((key)=>{
        let arr = obj[key]
        // let arr = [ ...obj[key], ...obj[key], ...obj[key], ...obj[key], ...obj[key], ...obj[key], ...obj[key], ...obj[key], ...obj[key], ...obj[key], ...obj[key], ...obj[key], ...obj[key]]
        arr = _.chunk(arr,perChunk)
        obj[key] = arr
      })

      setProjects(obj)

    }


  },[teams,perChunk])

  useLayoutEffect(()=>{
    if(Object.values(projects).length === 0) return;

    let onEarth = document.getElementById("onEarth")
    if(onEarth){
      let heigth = onEarth.offsetHeight
      setOnEarthHeight(heigth)
    }

    let belowSea = document.getElementById("belowSea")
    if(belowSea){
      let heigth = belowSea.offsetHeight
      setBelowSeaHeight(heigth)
    }

    let belowEarth = document.getElementById("belowEarth")
    if(belowEarth){
      let heigth = belowEarth.offsetHeight
      setBelowEarthHeight(heigth)
    }

  },[projects])

  function scroll(e){
    setOffsetFrise(e.target.scrollTop)
  }

  return (
    <div className={styles["projects"]} onScroll={(e) => scroll(e)}>
      {userReducer.team &&
        <div className={styles["container-badge"]}>
          <Badge noLink linkProject/>
        </div>
      }

      <section className={styles["section-1"]}>
        <img src={logo} alt="logo keran"/>
        <h3>Univers terrestre</h3>
        {teamsReducer.isLoading && <Loader />}
        { (projects.onEarth && projects.onEarth.length > 0) &&
          <div className={styles["container-list-projects"]}>
            <Slider
              height={onEarthHeight + 40}
              slides={ projects.onEarth.map((arr,i) => (
                <div key={`onEarth-${i}`}>
                  <ul className={styles["list-projects"]} id="onEarth">
                    { arr.map(project => (
                      <li key={`onEarth-project-${project._id}`}>
                        <Link to={`project/${project._id}`}>
                          <div className={styles["badge"]}>
                            <img src={`${API_URL}/${project.picture.path}`} alt={project.baseLine}/>
                          </div>
                          <p className={styles["name-team"]}>{project.name}</p>
                          {/*<p className={styles["baseline-team"]}>{project.baseline}</p>*/}
                          <Like likes={project.likes}/>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            />
          </div>
        }
      </section>
      <section className={styles["section-2"]}>
        <h3>Univers aquatique</h3>
        <div className={styles["frise"]} style={{backgroundPosition : `${offsetFrise/15}px center`}}></div>
        {teamsReducer.isLoading && <Loader />}
        { (projects.belowSea && projects.belowSea.length > 0) &&
          <div className={styles["container-list-projects"]}>
            <Slider
              height={belowSeaHeight + 40}
              slides={ projects.belowSea.map((arr,i) => (
                <div key={`belowSea-${i}`}>
                  <ul className={styles["list-projects"]} id="belowSea">
                    { arr.map(project => (
                      <li key={`belowSea-project-${project._id}`}>
                        <Link to={`project/${project._id}`}>
                          <div className={styles["badge"]}>
                            <img src={`${API_URL}/${project.picture.path}`} alt={project.baseLine}/>
                          </div>
                          <p className={styles["name-team"]}>{project.name}</p>
                          {/*<p className={styles["baseline-team"]}>{project.baseline}</p>*/}
                          <Like likes={project.likes}/>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            />

          </div>
        }
      </section>
      <section className={styles["section-3"]}>
        <h3>Univers souterrain</h3>
        <div className={`${styles["frise-ground"]} ${styles["top"]}`} style={{backgroundPosition : `${(-offsetFrise/15)}px center`}}></div>
        <div className={`${styles["frise-ground"]} ${styles["bottom"]}`} style={{backgroundPosition : `${offsetFrise/15}px center`}}></div>
        {teamsReducer.isLoading && <Loader />}
        { (projects.belowEarth && projects.belowEarth.length > 0) &&
          <div className={styles["container-list-projects"]}>
            <Slider
              height={belowEarthHeight + 40}
              slides={ projects.belowEarth.map((arr,i) => (
                <div key={`belowEarth-${i}`}>
                  <ul className={styles["list-projects"]} id="belowEarth" >
                    { arr.map(project => (
                      <li key={`belowEarth-project-${project._id}`}>
                        <Link to={`project/${project._id}`}>
                          <div className={styles["badge"]}>
                            <img src={`${API_URL}/${project.picture.path}`} alt={project.baseLine}/>
                          </div>
                          <p className={styles["name-team"]}>{project.name}</p>
                          {/*<p className={styles["baseline-team"]}>{project.baseline}</p>*/}
                          <Like likes={project.likes}/>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            />
          </div>
        }
      </section>
      <label onClick={() => showIFrame()} className={styles["button-iframe-video"]}>Ouvrir la fenêtre streaming</label>

    </div>
  )
}

export default Projects
