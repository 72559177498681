const arrActions = [
  {value : "Les bars et les restos", match : "se nourrir"},
  {value : "Le travail", match : "travailler, échanger, troquer"},
  {value : "La culture", match : "s'informer, s'instruire, éduquer"},
  {value : "La communication & les interactions sociales", match : "communiquer, rencontrer des gens"},
  {value : "Les voyages", match : "se déplacer"},
  {value : "La nature, les balades ?", match : "se détendre / bien-être - santé"}
]

const arrSituations= [
  {value : "Fan de spéléo, vous êtes fasciné.e par les cavités souterraines et êtes incollable sur la formation des stalactites et des stalagmites", match : "sous-terre"},
  {value : "Vous avez le pied marin et êtes toujours prêt.e à affronter les éléments. Votre uniforme préféré c’est ciré jaune et marinière", match : "sur ou sous l'eau"},
  {value : "Sac sur le dos et godillots aux pieds, c’est dans la nature foisonnante que vous aimez vous ressourcer", match : "sur terre"}
]

const arrPurposes = [
  {value : "Créer une technique / un service", match : "un service"},
  {value : "Créer un espace / un lieu", match : "un espace / un lieu"},
  {value : "Créer un objet / un produit", match : "un objet / un produit"}
]
// const date = parse(
//   '15/01/2021 14:30:10',
//   'dd/MM/yyyy HH:mm:ss',
//   new Date()
// )


// DEMAIN 10H
// EMAIL LIEN TEST
//

export const actions = arrActions
export const situations = arrSituations
export const purposes = arrPurposes
