import axios from 'axios'
import {
  ERROR_USER
} from "./types"

// export const API_URL = "https://api.sce.bakasable.io/api"
// export const WS_URL = "ws://149.202.167.93:3036"

//PROD
export const API_URL = "https://api.keranevent.bakasable.fr/api"
export const WS_URL = "wss://ws.keranevent.bakasable.fr"

export function getData(errorType, url, dispatch, isAuthReq) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;
    let token = localStorage.getItem('token');

    let config = {};

    if (isAuthReq) {
      let token = localStorage.getItem('token');
      config.headers = {
         Authorization: `JWT ${token}`
       };
    }

    axios.get(requestUrl, config)
    .then((response) => {
      resolve(response)
    })
    .catch((error) => {
      reject()
      errorHandler(dispatch, error, errorType);
    });

  });

}

export function postFormData(errorType, url, dispatch, data, isAuthReq) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;

    let config = {
      timeout: 1000 * 60
    };

    if (isAuthReq) {
      let token = localStorage.getItem('token');
      config.headers = {
         Authorization: `JWT ${token}`,
         "Content-Type": 'multipart/form-data'
       };
    }

    axios.post(requestUrl, data, config)
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      errorHandler(dispatch, error, errorType);
      reject()
    });

  })

}

export function postData(errorType, url, dispatch, data, isAuthReq) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;

    let config = {
      timeout: 1000 * 60
    };

    if (isAuthReq) {
      let token = localStorage.getItem('token');
      config.headers = {
         Authorization: `JWT ${token}`
       };
    }

    axios.post(requestUrl, data, config)
    .then((response) => {
      setTimeout(()=>{
        resolve(response.data)
      },5000)
    })
    .catch((error) => {
      errorHandler(dispatch, error, errorType);
      reject()
    });

  })

}

export function putData(errorType, url, dispatch, data, isAuthReq) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;

    let config = {
      timeout: 1000 * 60
    };

    if (isAuthReq) {
      let token = localStorage.getItem('token');
      config.headers = {
         Authorization: `JWT ${token}`
       };
    }

    axios.put(requestUrl, data, config)
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject()
      errorHandler(dispatch, error, errorType);
    });

  })

}

export function deleteData(errorType, url, dispatch, isAuthReq) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;
    let token = localStorage.getItem('token');

    let config = {
       headers: {
          Authorization: `JWT ${token}`
        }
    };

    axios.delete(requestUrl, config)
    .then((response) => {
      resolve(response)
    })
    .catch((error) => {
      reject(error)
      errorHandler(dispatch, error, errorType);
    });

  });

}


export async function createFile(data,dispatch,actionLoading){
  return new Promise(function(resolve, reject) {

    let url = '/file'

    const requestUrl = API_URL + url;
    let token = localStorage.getItem('token');
    let config = {
      timeout: 5000 * 60,
      headers : {
         Authorization: `JWT ${token}`,
         "Content-Type": 'multipart/form-data'
       }
    };

    if(actionLoading && dispatch){
      config.onUploadProgress = progressEvent => {
        let progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);

        dispatch({
          type: actionLoading,
          payload: progress
        });

      }
    }

    axios.post(requestUrl, data, config)
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      console.log("error",error);
      console.log("error.data",error.data);
      console.log("error.response",error.response);
      reject(error.response)
    });

  })

}

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);
  console.log(error);

  if(type === ERROR_USER && error.response !== undefined && error.response.status === 401){

    if(localStorage.getItem('token')){
      localStorage.removeItem('token')
    }

  }

  dispatch({
    type,
    payload: error.response,
  });


}
