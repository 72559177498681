import { useState, useEffect, useRef } from 'react';
import {
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import styles from './routes.module.scss'

import { useTransition, animated } from 'react-spring';

import Game from '../pages/Game'
import Teams from '../pages/Teams'
import Projects from '../pages/Projects'
import Project from '../pages/Project'
import SignIn from '../pages/SignIn'
import Redirect from '../pages/Redirect'

import ProtectedRoute from '../components/ProtectedRoute'
import { WS_URL } from '../actions';

export default function Routes(){
  const location = useLocation();
  const [firstMount, setFirstMount] = useState(true);
  const [ cloudyBackground, setCloudyBackground] = useState(null)
  const [ws, setWs] = useState(null);
  const timeout = useRef(250)
  const firstConnect = useRef(true)

  useEffect(()=>{
    setFirstMount(false)
  },[])

  useEffect(()=>{
    if(
      location.pathname !== "/game/idea" &&
      location.pathname !== "/game/crazy6" &&
      location.pathname !== "/game/pitch" &&
      !/^\/project/.test(location.pathname)
    ){
      setCloudyBackground(true)
    }else{
      setCloudyBackground(false)
    }
  },[location])


  // CONNECT TO WS
  useEffect(()=>{
    if(location.pathname !== "/" && location.pathname !== "" && !ws){
      connect()
    }

  },[location,ws])



  function connect(){
    var connectInterval;

    const wsClient = new WebSocket(WS_URL);

    wsClient.onopen = () => {
      let str = JSON.stringify({action : "connect"})
      console.log("ws open");
      wsClient.send(str)
      setWs(wsClient);
    };

    wsClient.onclose = (e) => {
      console.log(
             `Socket is closed. Reconnect will be attempted in ${Math.min(
                 10000 / 1000,
                 (timeout.current + timeout.current) / 1000
             )} second.`,
             e.reason
         );

        timeout.current = timeout.current + timeout.current; //increment retry interval
        connectInterval = setTimeout(check, Math.min(10000, timeout.current)); //call check function after timeout
        //
        function check (){
          console.log('wsClient',wsClient.readyState, WebSocket.CLOSED);
          // const { ws } = this.state;
          if (!wsClient || wsClient.readyState == WebSocket.CLOSED){
            connect()
          } //check if websocket instance is closed, if so call `connect` function.
        }

        console.log('ws closed');
    }

    wsClient.onerror = () => console.log('ws error');
  }

  useEffect(()=>{
    if(cloudyBackground === null) return
    if(cloudyBackground){
      document.body.classList.add('cloudy')
    }else{
      document.body.classList.remove('cloudy')
    }
  },[cloudyBackground])

  const transitions = useTransition(location, location => location.pathname, {
    from: {
      position: 'absolute',
      width: '100%',
      height : '100%',
      opacity: firstMount ? 1 : 0,
      transform: firstMount ? 'translate(0,0)' : 'translate(100%,0)'
    },
    enter: { opacity: 1, transform: 'translate(0%,0)' },
    leave: { opacity: 0, transform: 'translate(-50%,0)' }
  });

  return (
    <Switch>
      <Route exact path="/"><SignIn/></Route>
      <Route exact path="/game/:page?/:subpage?">
        <ProtectedRoute><Game ws={ws} /></ProtectedRoute>
      </Route>
      <Route exact path="/teams/:page?">
        <ProtectedRoute><Teams ws={ws}/></ProtectedRoute>
      </Route>
      <Route exact path="/result">
        <ProtectedRoute><Projects ws={ws}/></ProtectedRoute>
      </Route>
      <Route exact path="/project/:id">
        <ProtectedRoute><Project ws={ws}/></ProtectedRoute>
      </Route>

      <Route exact path="/redirect/:token?"><Redirect/></Route>
    </Switch>
  )

  // {transitions.map(({ item, props, key }) => (
  //    <animated.div key={key} style={props}>
  //      <Switch location={item}>
  //        <Route exact path="/"><SignIn/></Route>
  //        <Route exact path="/game/:page?/:page?">
  //          <ProtectedRoute><Game/></ProtectedRoute>
  //        </Route>
  //        <Route exact path="/teams/:page?">
  //          <ProtectedRoute><Teams/></ProtectedRoute>
  //        </Route>
  //        <Route exact path="/redirect/:token?"><Redirect/></Route>
  //      </Switch>
  //    </animated.div>
  // ))}


}
