import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react"
import html2canvas from 'html2canvas'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { SliderPicker } from 'react-color';

import { sendBadge } from '../../actions/teams'

import styles from './create-badge.module.scss'

import Back0 from './logos-teams/back_00.js';
import Back1 from './logos-teams/back_01.js';
import Back2 from './logos-teams/back_02.js';
import Back3 from './logos-teams/back_03.js';
import Back4 from './logos-teams/back_04.js';
import Back5 from './logos-teams/back_05.js';
import Back6 from './logos-teams/back_06.js';
import Back7 from './logos-teams/back_07.js';
import Back8 from './logos-teams/back_08.js';

import Front0 from './logos-teams/front_00.js';
import Front1 from './logos-teams/front_01.js';
import Front2 from './logos-teams/front_02.js';
import Front3 from './logos-teams/front_03.js';
import Front4 from './logos-teams/front_04.js';
import Front5 from './logos-teams/front_05.js';
import Front6 from './logos-teams/front_06.js';
import Front7 from './logos-teams/front_07.js';
import Front8 from './logos-teams/front_08.js';

const CreateBadge = ({firstCharacter, edit = false, handleChangeDrop = () => {}},ref) => {
  const canvasRef = useRef()
  const badgeRef = useRef()
  const [ color, setColor ] = useState("#988080")
  const [ canvasElt, setCanvasElt ] = useState()
  const [ badge, setBadge ] = useState({})

  useImperativeHandle(ref, ()=>({
    getBadge : async () => {
      let badge = await getBadge()
      return badge
    }
  }))


  function handleChangeColor(color){
    setColor(color.hex)
  }

  function getBadge() {

    let elt = badgeRef.current

    let options = {
      scale : 1,
      width : 200,
      height : 200
    }

    return new Promise((resolve) => {
      html2canvas(elt,options).then(canvas => {

        // canvasRef.current.appendChild(canvas);

        canvas.toBlob(function(blob) {
          if(!blob) return;
          let file = new File([blob],"badge", { lastModified : Date.now(), type : blob.type})
          resolve(file)
        });

      });
    })


  }

  function selectItem(item){
    handleChangeDrop()
    setBadge({ ...badge, [item.type] : item})
  }

  function getItemFront(){
    if(!badge.front) return null
    let item;
    switch (badge.front.id) {
      case "front-0":
        item = <Front0 color={color}/>
        break;
      case "front-1":
        item = <Front1 color={color}/>
        break;
      case "front-2":
        item = <Front2 color={color}/>
        break;
      case "front-3":
        item = <Front3 color={color}/>
        break;
      case "front-4":
        item = <Front4 color={color}/>
        break;
      case "front-5":
        item = <Front5 color={color}/>
        break;
      case "front-6":
        item = <Front6 color={color}/>
        break;
      case "front-7":
        item = <Front7 color={color}/>
        break;
      case "front-8":
        item = <Front8 color={color}/>
        break;
    }
    return <div className={styles["front"]}>{item}</div>
  }

  function getItemBack(){
    if(!badge.back) return null
    let item;
    switch (badge.back.id) {
      case "back-0":
        item = <Back0 color={color}/>
        break;
      case "back-1":
        item = <Back1 color={color}/>
        break;
      case "back-2":
        item = <Back2 color={color}/>
        break;
      case "back-3":
        item = <Back3 color={color}/>
        break;
      case "back-4":
        item = <Back4 color={color}/>
        break;
      case "back-5":
        item = <Back5 color={color}/>
        break;
      case "back-6":
        item = <Back6 color={color}/>
        break;
      case "back-7":
        item = <Back7 color={color}/>
        break;
      case "back-8":
        item = <Back8 color={color}/>
        break;
    }
    return <div className={styles["back"]}>{item}</div>
  }

  function getStyleContainerBadge(){
    if(!badge.back){
      return {  backgroundColor : '#F2F2F2' }
    }else{
      return {}
    }
  }

  return (
    <div className={styles["create-badge"]}>
      <div>
        <div className={styles["container"]}>
          <div className={styles["grid-backgrounds"]}>
            <div onClick={() => selectItem({type : "back", id : "back-0"})}><Back0 color={color} /></div>
            <div onClick={() => selectItem({type : "back", id : "back-1"})}><Back1 color={color} /></div>
            <div onClick={() => selectItem({type : "back", id : "back-2"})}><Back2 color={color} /></div>
            <div onClick={() => selectItem({type : "back", id : "back-3"})}><Back3 color={color} /></div>
            <div onClick={() => selectItem({type : "back", id : "back-4"})}><Back4 color={color} /></div>
            <div onClick={() => selectItem({type : "back", id : "back-5"})}><Back5 color={color} /></div>
            <div onClick={() => selectItem({type : "back", id : "back-6"})}><Back6 color={color} /></div>
            <div onClick={() => selectItem({type : "back", id : "back-7"})}><Back7 color={color} /></div>
            <div onClick={() => selectItem({type : "back", id : "back-8"})}><Back8 color={color} /></div>
          </div>
          <div className={styles["container-dropzone"]}>
            <label>Votre logo</label>
            <div className={styles["container-badge"]} ref={badgeRef} style={getStyleContainerBadge()}>
              {getItemFront()}
              {getItemBack()}
              { (!badge.front && !badge.back) && <p>Créez votre logo</p>}
            </div>
            {firstCharacter && <div className={styles["first-character"]} style={{color}}><span>{firstCharacter}</span></div>}
          </div>
          <div className={styles["grid-pictos"]}>
            <div onClick={() => selectItem({type : "front", id : "front-0"})}><Front0 color={color}/></div>
            <div onClick={() => selectItem({type : "front", id : "front-1"})}><Front1 color={color}/></div>
            <div onClick={() => selectItem({type : "front", id : "front-2"})}><Front2 color={color}/></div>
            <div onClick={() => selectItem({type : "front", id : "front-3"})}><Front3 color={color}/></div>
            <div onClick={() => selectItem({type : "front", id : "front-4"})}><Front4 color={color}/></div>
            <div onClick={() => selectItem({type : "front", id : "front-5"})}><Front5 color={color}/></div>
            <div onClick={() => selectItem({type : "front", id : "front-6"})}><Front6 color={color}/></div>
            <div onClick={() => selectItem({type : "front", id : "front-7"})}><Front7 color={color}/></div>
            <div onClick={() => selectItem({type : "front", id : "front-8"})}><Front8 color={color}/></div>
          </div>
        </div>
      </div>
      <div className={styles["container-colorpicker"]}>
        <SliderPicker onChange={handleChangeColor} color={color}/>
      </div>
      {/*<div ref={canvasRef}>
      </div>*/}
    </div>
  )
}


const DropZone = ({color, edit, handleChangeDrop},ref) => {
  const badgeRef = useRef()
  const [ items, setItems ] = useState([])

  useImperativeHandle(ref,()=>({
    getContentHTML : () => {
      return badgeRef.current
    }
  }))

  const [{ canDrop, isOver, collect }, drop] = useDrop({
      accept: ["front","back"],
      drop: (item) => {
        let arr = items.filter( d=> d.type !== item.type)
        handleChangeDrop()
        setItems([...arr, item])
      },
      collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
          getDropResult : monitor.getDropResult()
      })
  });

  const isActive = canDrop && isOver;

  function renderBadge(){

    return (
      <div ref={badgeRef} className="container-badge">
        {items.map(item => (
          <div key={item.id} style={{position : "absolute", zIndex : item.type === "front" ? 2 : 1, height : "100%", width: "100%"}}>
            {React.cloneElement(item.component,{ color }, null)}
          </div>
        ))}
      </div>
    )

  }

  function getSentence(){
    // let sentence = edit ? 'Regénérer<br/>votre logo' : 'Générer<br/>votre logo'
    let sentence = 'Glisser / déposer<br/>votre logo'
    return isActive ? <span className={styles["drop-zone-sentence"]}>Déposer ici</span> : <span className={styles["drop-zone-sentence"]} dangerouslySetInnerHTML={{ __html : sentence}}/>
  }

  function getStyle (){

    let backgroundColor = '#F2F2F2';
    let border = ``;

    if (isOver) {
      backgroundColor = '#F2F2F2';
      border = `solid 3px #D2D2D2`;
    }

    let badgeBorder = items.find(item => item.type === "back")
    if(badgeBorder){
      backgroundColor = '#FFFFFF';
    }

    return { backgroundColor, border}
  }

  return (
    <div className={styles["drop-zone"]} ref={drop} style={getStyle()}>

      { (items && items.length > 0) ?
        renderBadge() :
        <>{getSentence()}</>
      }
    </div>
  )
}

const ForwardedDropZone = forwardRef(DropZone)

const Draggable = ({children,id, type}) => {
  // const [{ opacity }, dragRef] = useDrag({
  //   item: { type: ItemTypes.CARD, text },
  //   collect: (monitor) => ({
  //     opacity: monitor.isDragging() ? 0.5 : 1
  //   })
  // })

  const [collectedProps, dragRef] = useDrag({
    item: { id ,component : children, type}
  })

  return (
    <div className={`${styles["drag-item"]} ${styles[type]}`} ref={dragRef}>
      {children}
    </div>
  )

}

export default forwardRef(CreateBadge)
