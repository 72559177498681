import { useEffect } from 'react'
import { useParams, useHistory } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import styles from './redirect.module.scss'
import { getUser } from '../../actions/user'

// https://sce.bakasable.io/redirect/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfX3YiOjAsInVwZGF0ZWRBdCI6IjIwMjAtMTItMDFUMTE6MTI6NDUuMTc0WiIsImNyZWF0ZWRBdCI6IjIwMjAtMTItMDFUMTE6MTI6NDUuMTc0WiIsImVtYWlsIjoicnVsaWVyLmhAYmFrYXNhYmxlLmZyIiwiX2lkIjoiNWZjNjI1MmQ4ZThjNTk2YzVkMDBiNGE2Iiwicm9sZSI6Ik1lbWJlciIsImlhdCI6MTYwNjgyMTE2NSwiZXhwIjoxNjA3NDI1OTY1fQ.5Hrlo2OejCC525vfnPkWPij-luX6yL10waf-bDojSWs

export default function SignIn (){
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const userReducer = useSelector(state => state.userReducer)

  useEffect(()=>{
    if(params.token){
      getUser(dispatch,params.token)
    }else{
      history.push('/')
    }
  },[])

  useEffect(()=>{
    if(userReducer.user){
      history.push('/game')
    }
  },[userReducer.user])

  return (
    <div className={styles["redirect"]}>
    </div>
  )
}
