import { cloneElement, useState, useEffect, useRef } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import {useSpring, animated, config} from 'react-spring';
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getUser } from '../../actions/user'
import usePrevious from '../../hooks/usePrevious'
import { dateEndStream2, dateStartStream2 } from '../../config'
import iconClose from '../../assets/images/close-modal.svg'

import Loader from '../../components/Loader'
import styles from './protected-route.module.scss'

// eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI1ZmM2MjUyZDhlOGM1OTZjNWQwMGI0YTYiLCJ1cGRhdGVkQXQiOiIyMDIwLTEyLTAxVDExOjEyOjQ1LjE3NFoiLCJjcmVhdGVkQXQiOiIyMDIwLTEyLTAxVDExOjEyOjQ1LjE3NFoiLCJlbWFpbCI6InJ1bGllci5oQGJha2FzYWJsZS5mciIsIl9fdiI6MCwicm9sZSI6Ik1lbWJlciIsImlhdCI6MTYwNjgzNDQ3OSwiZXhwIjoxNjA5NDYyNDc5fQ.oVenhhOl8FzdzFuxhYBGQYJWyl9ytLE6O1mw5VZCMI8

export default function ProtectedRoute({children}){
  const history = useHistory();
  const dispatch = useDispatch()
  const userReducer = useSelector(state => state.userReducer)
  const [ isMount, setIsMount ] = useState()
  const [ seconds, setSeconds] = useState(0)

  const [ showIFrame, setShowIFrame ] = useState(null)
  const [ animateIn, setAnimateIn] = useState(false)
  const [ closedByUser, setClosedByUser ] = useState(false)
  const timerSeconds = useRef()

  const prevShowIFrame = usePrevious(showIFrame)

  const props = useSpring({
    transform: !animateIn ? 'translate3d(0,100%,0)' : 'translate3d(0,0%,0)',
    onRest : (d) => {
      if(d.transform === 'translate3d(0,100%,0)' && prevShowIFrame){
        setShowIFrame(false)
      }
    }
  })

  //TIMER
  useEffect(()=>{
    if(closedByUser){
      clearInterval(timerSeconds.current)
      return;
    };

    let dateStart = Math.floor(dateStartStream2.getTime()/1000)
    let dateEnd = Math.floor(dateEndStream2.getTime()/1000)

    let now = Math.floor(Date.now()/1000)
    let bool = (dateStart <= now && dateEnd >= now)
    if(bool){
      setShowIFrame(bool)
    }
    if(!bool) setAnimateIn(false)

    if(dateStart >= now || bool ){

      timerSeconds.current = setTimeout(()=>{
        setSeconds(state => state + 1)
        clearInterval(timerSeconds.current)
      },1000)

    }

  },[seconds,closedByUser])


  // LOGIC USE TO FIRST RENDER THE ANIMATED PART WHEN showIFrame === true
  // THEM SET animationIn to true to trigger the animation
  useEffect(()=>{
    // console.log(showIFrame ? "open modal" : "open close",showIFrame);
    if(showIFrame){
      setAnimateIn(true)
    }
  },[showIFrame])

  useEffect(()=>{
    return () =>{
      clearInterval(timerSeconds.current)
    }
  },[])

  useEffect(()=>{
    if(isMount) return;

    let token = localStorage.getItem('token')
    if(!userReducer.user && token){
      getUser(dispatch,token)
      setIsMount(true)
    }else if(!token){
      history.push('/')
    }

  },[userReducer.user,isMount])

  function fnShowIframe(){
    setShowIFrame(true)
  }

  function closeIframe(){
    setClosedByUser(true)
    setAnimateIn(false)
  }

  function addPropsToggleIframe(children){
    return cloneElement(children,{ showIFrame : fnShowIframe })
  }

  return (
    <>
      { showIFrame &&
        <animated.div style={props} className={styles["content-iframes"]}>
          { history.location.pathname === "/result" &&
            <div className={styles["close"]} onClick={() => closeIframe()}>
              <img src={iconClose} alt="close" />
            </div>
          }
          <iframe src="https://www.creacast.com/play.php?su=videlio-paris-ch6" width="100%" height="100%" scrolling="no" frameBorder="0" allowFullScreen></iframe>
          <iframe src="https://app.sli.do/event/hkckcyj9" height="100%" width="400px" frameBorder="0"></iframe>
        </animated.div>
      }
      { (!userReducer.user || userReducer.isLoading) ?
        <Loader/> : <>{ addPropsToggleIframe(children) }</>
      }
      { userReducer.error && <Redirect to="/" />}
    </>
  )
}
