import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import InputText from '../../../components/InputText'
import Textarea from '../../../components/Textarea'
import InputFile from '../../../components/InputFile'
import ModalHurryUp from '../../../components/ModalHurryUp'

import StepNavigation from '../../../components/StepNavigation'
import Help from '../../../components/Help'
import Chrono from '../../../components/Chrono'
import styles from './crazy-6.module.scss'
import imgCrazy6 from '../../../assets/images/crazy6_V2.gif'
import imgArrowDown from '../../../assets/images/arrow-down.svg'
import imgMachine from '../../../assets/images/machine.png'
import imgBaleine from '../../../assets/images/baleine.png'

import iconExcel from '../../../assets/images/xcel.svg'
import iconPdf from '../../../assets/images/pdf.svg'
import iconVideo from '../../../assets/images/video.svg'

import { API_URL, createFile } from '../../../actions'
import { editUserTeam } from '../../../actions/user'
import { getPathCurrentStep } from '../../../helpers'
import useHurryUp from '../../../hooks/useHurryUp'

import docTerrestre from './docs/moodboard-milieu-terrestre.pdf'
import docAqua from './docs/moodboard-milieu-aquatique.pdf'
import docSouterrain from './docs/moodboard-milieu-souterrain.pdf'
import docPliage from './docs/crazy_final.mp4'
import docBestPractices from './docs/crazy6-best-practices.pdf'
import poster from './docs/poster-crazy6.png'


import imgTerrestre from '../../../assets/images/sur-terre.svg'
import imgAqua from '../../../assets/images/sous-leau.svg'
import imgSouterrain from '../../../assets/images/sous-terre.svg'


export default function Crazy6({isLeader}){
  const userReducer = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const history = useHistory()
  const titleRef = useRef()
  const modalRef = useRef()
  const descriptionRef = useRef()
  const inputFileRef = useRef()
  const [ project, setProject ] = useState({ title : "", description : "", picture : null})
  const [ previewImage, setPreviewImage ] = useState()


  // useHurryUp(userReducer.team,20,modalRef,"crazy6")
  useHurryUp(userReducer.team,15,modalRef,"crazy6")

  // REDIRECT TO CURRENT STEP
  // useEffect(()=>{
  //   let pathCurrentStep = getPathCurrentStep(userReducer.team)
  //   if(history.location.pathname !== pathCurrentStep){
  //     console.log("redirect to pathCurrentStep", pathCurrentStep);
  //     history.push(pathCurrentStep)
  //   }
  // },[])

  //UDPATE STEP WHEN PAGE MOUNT
  useEffect(()=>{
    if(isLeader === null || isLeader === false) return
    let crazy6 = userReducer.team.crazy6 ? userReducer.team.crazy6 : {}

    // DETECT IF WE OPEN CRAZY6 STEP FOR THE FIRST TIME
    if(userReducer.team.step !== "crazy6"){
      editUserTeam(dispatch, { step : "crazy6", crazy6, stepStart : new Date() } ,userReducer.team._id)
    }else{
      editUserTeam(dispatch, { step : "crazy6", crazy6 } ,userReducer.team._id)
    }

  },[isLeader])

  //INIT CRAZY 6
  useEffect(()=>{
    if(!userReducer.team.crazy6) return;
    let crazy6 = userReducer.team.crazy6
    setProject(crazy6)
    crazy6.title && titleRef.current.setValue(crazy6.title)
    crazy6.description && descriptionRef.current.setValue(crazy6.description)
  },[])

  useEffect(()=>{
    //SCROLL TO TOP ON MOUNT
    let container = document.querySelector(".App")
    container.scrollTo(0, 0);
  },[])

  //ON LEAVE PAGE STOP CHRONO
  // DONT UNCOMMENT THIS IT'S LEADING TO A BUG
  // useEffect(()=>{
  //   return () => {
  //     if(project.title !== "" && project.description !== "" && project.picture !== null){
  //       let updatedProject = { ...project, chronoStart : null}
  //       console.log("here qzdpokqzdpok", updatedProject);
  //       isLeader && editUserTeam(dispatch, { crazy6 : updatedProject } ,userReducer.team._id)
  //     }
  //   }
  // },[project])

  //UPDATE CRAZY 6
  useEffect(()=>{
    if(!userReducer.team.crazy6 || isLeader) return;
    let crazy6 = userReducer.team.crazy6

    setProject(crazy6)
    crazy6.title && titleRef.current.setValue(crazy6.title)
    crazy6.description && descriptionRef.current.setValue(crazy6.description)
  },[userReducer.lastUpdate])

  function goToHelp(){
    let target = document.getElementById("3");
    let elementPosition = target.offsetTop;
    let headerOffset = 50;
    let offsetPosition = elementPosition - headerOffset;

    let containerElt = document.querySelector(".App")

    containerElt.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
  }

  function handleChangeProject(value,name){
    let updatedProject = { ...project, [name] : value}

    editUserTeam(dispatch, { crazy6 : updatedProject } ,userReducer.team._id)
    setProject(updatedProject)
  }

  async function handleChangeFile(value,name){

    let formData = new FormData()

    formData.append('title',"crazy 6 image")
    formData.append('file',value)
    formData.append('alt',`crazy 6 image, équipe ${userReducer.team.name}`)

    let res = await createFile(formData)

    if(res.message === "file saved"){

      let updatedProject = { ...project, picture : res.files[0]._id}

      setPreviewImage(res.files[0].path)

      editUserTeam(dispatch, { crazy6 : updatedProject } ,userReducer.team._id)
      setProject(updatedProject)

    }


  }

  function startChrono(){
    if(!isLeader) return;
    let updatedProject = { ...project, chronoStart : new Date()}
    editUserTeam(dispatch, { crazy6 : updatedProject } ,userReducer.team._id)
  }

  function stopChrono(){
    if(!isLeader || !userReducer.team.crazy6 || userReducer.team.crazy6.chronoStart === null) return;
    let updatedProject = { ...project, chronoStart : null}
    editUserTeam(dispatch, { crazy6 : updatedProject } ,userReducer.team._id)
  }

  function getNextClassName(){
    if(
      (project.title === "" || !project.title) ||
      (project.description === "" || !project.description) ||
      (project.picture === "" || !project.picture)
    ){
      return 'secondary disabled'
    }else{
      return "secondary"
    }
  }

  return (
    <div className={styles["crazy-6"]} >
      <ModalHurryUp ref={modalRef}/>
      <div className={styles["background"]}>
      </div>
      <div className={styles["global"]}>
        <div className={styles["container"]}>
          <div className={"center"}>
            <h1>Crazy 6 <span>2/3 - 20min</span></h1>
          </div>
          <StepNavigation/>
        </div>


        <section id="1" className={styles["first-section"]}>
          <img src={imgMachine} alt={"machine"}/>
          <div className={styles["container"]}>
            <div className={styles["col-2"]} style={{verticalAlign: 'top', marginTop: 36}}>

              <video controls poster={poster}>
                <source src={docPliage}
                type="video/mp4"/>
                Sorry, your browser doesn't support embedded videos.
              </video>
            </div>
            <div className={styles["col-2"]}>
              <h3 style={{marginBottom:10}}>Comment ça marche ?</h3>
              <p>
                Votre objectif en tête, vous devez maintenant trouver des solutions pour y répondre.  Développez vos idées autour des mots-clés sélectionnés à la fin du Brainstorming.<br/><br/>
                <b>Un atelier de co-construction en 2 étapes :</b><br/><br/>
                <b>- 1 étape en solo : 6 idées/concepts en 6 minutes : </b>
                Pliez votre feuille en 6, dépliez et couchez vos idées.
                L’étape est chronométrée.<br/>
                <p>Le leader lance le chrono</p>
                <b>- 1 étape en équipe : débattez et votez pour LE meilleur concept</b><br/>
                Il vous est possible de croiser plusieurs idées !
              </p>
              <button onClick={goToHelp}>Besoin d'aide ?</button>
            </div>
            <div className={styles["container-chrono"]}>
              <Chrono
                isLeader={isLeader}
                startChrono={startChrono}
                stopChrono={stopChrono}
                chronoStartAt={project.chronoStart}
              />
            </div>
          </div>
        </section>
        <section id="2" className={styles["inputs-section"]}>
          <div className={styles["container"]}>
            <h3>Rendu pour cette phase : </h3>
            <p>(Ce contenu sera intégré à la présentation de votre concept, et viendra alimenter le plateau de jeu)</p>
            <div className={styles["container-inputs"]}>
              <div>
                <label>Donner un nom à votre projet *</label>
                <InputText disabled={!isLeader} name="title" placeholder="Titre de mon projet" ref={titleRef} handleChange={handleChangeProject}/>
              </div>
              <div>
                <label>Décrivez votre projet (400 caractères maxi) *</label>
                <Textarea disabled={!isLeader} name="description" ref={descriptionRef} handleChange={handleChangeProject} maxlength={400}/>
                <label>Illustrer votre projet avec une image</label>
                { isLeader &&
                  <InputFile ref={inputFileRef} name="picture" handleChange={handleChangeFile}/>
                }

                {previewImage &&
                  <div className={styles["preview"]}>
                    <img src={`${API_URL}/${previewImage}`} alt={'preview'}/>
                  </div>
                }

                {(!previewImage && project.picture?.path) &&
                  <div className={styles["preview"]}>
                    <img src={`${API_URL}/${project.picture.path}`} alt={project.picture.alt}/>
                  </div>
                }

                {isLeader &&
                  <Link to="/game/pitch">
                      <button className={getNextClassName()}>Étape suivante</button>
                  </Link>
                }
              </div>
            </div>
          </div>
        </section>


        <section id="3" className={styles["section-help"]}>
          <img src={imgBaleine} alt={"baleine"}/>
          <Help documents={[
            {type : "video", label : "Pliage de la feuille A4", file : docPliage},
            {type : "pdf", label : "Best practices", file : docBestPractices},
            {type : "discussion", label : "Joignez nos experts", experts : <ListExpert/>},
            {type : "pdf", label : "L'univers terrestre", file : docTerrestre, img : imgTerrestre },
            {type : "pdf", label : "L'univers aquatique", file : docAqua, img : imgAqua },
            {type : "pdf", label : "L'univers sous-terrain", file : docSouterrain, img : imgSouterrain }
          ]}
          tool="Pour que votre Crazy 6 soit un tremplin d’idées :"/>
        </section>
      </div>
    </div>
  )
}

const ListExpert = () => {
  return (
    <ul>
      <li><a target="_blank"  href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_MDkwYTkxMWQtY2ZkMi00MWJjLTk1MTItYTE0NjFkYmExZTlj%40thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%2522d183af0c-d17a-41b8-a3b1-13e45bf37a7b%2522%252c%2522Oid%2522%253a%2522a444d608-8ca9-4274-8070-44d345d37e02%2522%257d%26anon%3Dtrue&type=meetup-join&deeplinkId=a423533f-9fcc-4b12-9e8e-cc2960fd82e3&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true">Expert 1</a></li>
      <li><a target="_blank"  href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_MDdmMmQ4YWItZjc3ZS00YWEyLTg2OTctZDliMTkyNTUzMTZl%40thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%2522d183af0c-d17a-41b8-a3b1-13e45bf37a7b%2522%252c%2522Oid%2522%253a%2522a12d62b8-1020-4aa3-b0d0-79f0b4bc7fe8%2522%257d%26anon%3Dtrue&type=meetup-join&deeplinkId=917c0a55-5e5e-417d-832d-e9ddecb66818&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true">Expert 2</a></li>
      <li><a target="_blank"  href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_Y2RjYWRlM2YtYmM4NS00ZWYzLWEyOWItMDg2MzI4OTU5ZDc1%40thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%2522d183af0c-d17a-41b8-a3b1-13e45bf37a7b%2522%252c%2522Oid%2522%253a%2522060c043a-ff9f-4928-89b5-448c45778afe%2522%257d%26anon%3Dtrue&type=meetup-join&deeplinkId=8539f4d4-dbcb-46c6-9abb-6be35696222a&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true">Expert 3</a></li>
    </ul>
  )
}
