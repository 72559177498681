export default function Back1 ({color}){
  return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">
		<g id="XMLID_1_">
			<path style={{fill : color}} id="XMLID_1090_" d="M93.77,52.89l3.83-4.06l-3.94-3.87l4.14-6.99l-6.97-4.29l1.69-5.32l-5.23-1.77l0.68-8.09l-8.14-0.85
				l-0.78-5.52l-5.48,0.67l-2.89-7.6L62.98,8l-3.1-4.64l-4.65,2.99l-5.9-5.58L43.6,6.6l-4.8-2.84l-2.89,4.71l-7.75-2.48l-2.62,7.75
				l-5.56-0.46l-0.56,5.49l-8.05,1.12l0.99,8.13l-5.21,1.99l1.88,5.2l-6.76,4.51l4.42,6.88l-3.83,4.06l3.94,3.87l-4.14,6.99l6.97,4.29
				l-1.69,5.32l5.23,1.77l-0.68,8.09l8.14,0.85l0.78,5.52l5.48-0.67l2.89,7.6l7.7-2.78l3.1,4.64l4.65-2.99l5.9,5.58l5.74-5.84
				l4.8,2.84l2.89-4.71l7.75,2.48l2.62-7.75l5.56,0.46l0.56-5.49l8.05-1.12l-0.99-8.13l5.21-1.99l-1.88-5.2l6.76-4.51L93.77,52.89z
				 M86.53,77.63l-11.85,1.65l-3.84,11.33l-11.39-3.64l-8.39,8.53l-8.69-8.23l-11.25,4.06l-4.26-11.18l-11.9-1.24l1.01-11.92
				L5.8,60.75l6.1-10.29L5.43,40.38l9.95-6.64l-1.45-11.87l11.85-1.65l3.84-11.33l11.39,3.64l8.38-8.53l8.69,8.23l11.25-4.06
				l4.25,11.18l11.9,1.24l-1.01,11.92l10.19,6.27l-6.09,10.29l6.46,10.07l-9.95,6.64L86.53,77.63z"/>
		</g>
		</svg>
	)
}
