import styles from "./container-teams.module.scss";

export default function ContainerTeams({children}){
  return (
    <div className={styles["container-teams"]}>
      <p className={styles["info-backward"]}>Cliquez sur retour pour continuer à suivre l’évènement.</p>
      <div className={styles["container"]}>
        <div className={styles["container-scroll"]}>
          {children}
        </div>
      </div>
    </div>

  )
}
