import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Switch,
  Route
} from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux'
import styles from './teams.module.scss'

import CreateTeam from './CreateTeam'
import EditTeam from './EditTeam'
import ListTeams from './ListTeams'
import ViewTeam from './ViewTeam'

import { doesProjectCompleted, getPathCurrentStep } from '../../helpers'

import Backward from '../../components/Backward'
import Badge from '../../components/Badge';

import { setUserTeam } from '../../actions/user'


export default function Teams ({ws}){
  const userReducer = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const history = useHistory()
  const [ pathBackward, setPathBackward] = useState()

  useEffect(()=>{

    let path;
    if(!userReducer.team){
      path = "/game"
    }else{
      if(userReducer.team.inGame && userReducer.team.step !== "result"){
        path = getPathCurrentStep(userReducer.team)
      }else if(userReducer.team.inGame && userReducer.team.step === "result"){
        path = "/result"
      }else{
        path = "/teams"
        if(history.location.pathname === "/teams") path = "/game"
      }
    }

    setPathBackward(path)

  },[userReducer.team,history.location.pathname])

  //HANDLE MESSAGE
  useEffect(()=>{
    if(!ws || !userReducer.user) return;

    ws.addEventListener("message", listen);

    function listen (message) {

      if(
        message.data && message.data !== "message received" &&
        message.data !== `${userReducer.user._id} connected`
      ){

        let data = JSON.parse(message.data)

        if(data.type === "" && data.step !== "problematic" &&
          userReducer.user.team === data.teamId
        ){
          setUserTeam(dispatch,data)
        }

        if(data.type === "" && data.step === "problematic" &&
          userReducer.user.team === data.teamId
        ){
          setUserTeam(dispatch,data)
          history.push("/game/problematic/action")
        }

     }
   }

   return function cleanupListener() {
     ws.removeEventListener('message', listen)
   }

  },[ws,userReducer.user])

  return (
    <div className={styles["teams"]}>
      <Backward to={pathBackward}/>
      {userReducer.team &&
        <div className={styles["container-badge"]}><Badge ws={ws} teamBadge/></div>
      }
      <Switch>
        <Route exact path="/teams">
          <ListTeams ws={ws}/>
        </Route>
        <Route exact path="/teams/create">
          <CreateTeam/>
        </Route>
        <Route exact path="/teams/edit">
          <EditTeam ws={ws}/>
        </Route>
        <Route exact path="/teams/:id">
          <ViewTeam ws={ws}/>
        </Route>
      </Switch>
    </div>
  )
}
