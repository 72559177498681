export default function Back4 ({color}){
	return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
				 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">
			<g id="XMLID_17_">
				<path id="XMLID_1082_" style={{fill : color}} d="M49.82,8.54c-22.61,0-40.94,18.33-40.94,40.94s18.33,40.94,40.94,40.94s40.94-18.33,40.94-40.94
					C90.76,26.87,72.43,8.54,49.82,8.54z M89.49,49.29c0,22.01-17.85,39.86-39.86,39.86S9.77,71.3,9.77,49.29S27.62,9.43,49.63,9.43
					S89.49,27.27,89.49,49.29z"/>
				<polygon id="XMLID_1091_" style={{fill : color}} points="48.33,5.54 49.82,4.46 51.31,5.54 50.74,3.79 52.23,2.71 50.39,2.71 49.82,0.96 49.25,2.71
					47.41,2.71 48.9,3.79 	"/>
				<polygon id="XMLID_1092_" style={{fill : color}} points="40.73,6.46 42,5.14 43.66,5.95 42.79,4.32 44.07,3 42.26,3.32 41.4,1.69 41.14,3.51 39.33,3.83
					40.98,4.64 	"/>
				<polygon id="XMLID_1093_" style={{fill : color}} points="33.33,6.86 33.39,8.7 34.42,7.17 36.19,7.68 35.06,6.23 36.09,4.7 34.36,5.33 33.23,3.88
					33.29,5.72 31.56,6.35 	"/>
				<polygon id="XMLID_1094_" style={{fill : color}} points="26.18,10.37 26.56,12.17 27.31,10.49 29.14,10.68 27.77,9.45 28.52,7.77 26.93,8.69 25.56,7.46
					25.94,9.26 24.35,10.18 	"/>
				<polygon id="XMLID_1095_" style={{fill : color}} points="19.75,15.07 20.44,16.77 20.88,14.99 22.72,14.86 21.16,13.89 21.6,12.1 20.19,13.28
					18.63,12.31 19.32,14.01 17.91,15.2 	"/>
				<polygon id="XMLID_1096_" style={{fill : color}} points="14.23,20.81 15.2,22.37 15.33,20.54 17.12,20.09 15.41,19.4 15.54,17.57 14.36,18.98
					12.65,18.29 13.63,19.85 12.44,21.26 	"/>
				<polygon id="XMLID_1097_" style={{fill : color}} style={{fill : color}} points="8.11,28.18 9.79,27.43 11.02,28.8 10.83,26.97 12.51,26.22 10.71,25.84 10.52,24.01 9.6,25.6
					7.8,25.22 9.03,26.59 	"/>
				<polygon id="XMLID_1098_" style={{fill : color}} style={{fill : color}} points="5.05,35.75 6.57,34.72 8.02,35.85 7.51,34.08 9.04,33.05 7.2,32.99 6.69,31.22 6.06,32.95
					4.22,32.88 5.67,34.02 	"/>
				<polygon id="XMLID_1099_" style={{fill : color}} style={{fill : color}} points="3.34,43.73 4.66,42.45 6.29,43.32 5.48,41.66 6.81,40.38 4.98,40.64 4.18,38.99 3.86,40.8
					2.04,41.05 3.66,41.92 	"/>
				<polygon id="XMLID_1100_" style={{fill : color}} style={{fill : color}} points="3.05,51.89 4.13,50.4 5.88,50.97 4.8,49.48 5.88,47.99 4.13,48.56 3.05,47.07 3.05,48.91
					1.3,49.48 3.05,50.05 	"/>
				<polygon id="XMLID_1101_" style={{fill : color}} style={{fill : color}} points="3.86,58.16 4.18,59.97 4.98,58.32 6.81,58.58 5.48,57.3 6.29,55.64 4.66,56.51 3.34,55.23
					3.66,57.04 2.04,57.91 	"/>
				<polygon id="XMLID_1102_" style={{fill : color}} style={{fill : color}} points="6.69,67.74 7.2,65.97 9.04,65.91 7.51,64.88 8.02,63.11 6.57,64.24 5.05,63.21 5.67,64.94
					4.22,66.08 6.06,66.01 	"/>
				<polygon id="XMLID_1103_" style={{fill : color}} style={{fill : color}} points="9.6,73.36 10.52,74.95 10.71,73.12 12.51,72.74 10.83,71.99 11.02,70.16 9.79,71.53 8.11,70.78
					9.03,72.38 7.8,73.74 	"/>
				<polygon id="XMLID_1104_" style={{fill : color}} style={{fill : color}} points="15.33,78.42 15.2,76.59 14.23,78.15 12.44,77.7 13.63,79.11 12.65,80.67 14.36,79.98
					15.54,81.39 15.41,79.56 17.12,78.87 	"/>
				<polygon id="XMLID_1105_" style={{fill : color}} style={{fill : color}} points="20.88,83.97 20.44,82.19 19.75,83.89 17.91,83.76 19.32,84.95 18.63,86.65 20.19,85.68
					21.6,86.86 21.16,85.07 22.72,84.1 	"/>
				<polygon id="XMLID_1106_" style={{fill : color}} style={{fill : color}} points="27.31,88.47 26.56,86.79 26.18,88.59 24.35,88.78 25.94,89.7 25.56,91.5 26.93,90.27
					28.52,91.19 27.77,89.51 29.14,88.28 	"/>
				<polygon id="XMLID_1107_" style={{fill : color}} style={{fill : color}} points="34.36,93.63 36.09,94.26 35.06,92.73 36.19,91.28 34.42,91.79 33.39,90.26 33.33,92.1
					31.56,92.61 33.29,93.24 33.23,95.08 	"/>
				<polygon id="XMLID_1108_" style={{fill : color}} style={{fill : color}} points="41.4,97.27 42.26,95.64 44.07,95.96 42.79,94.64 43.66,93.01 42,93.82 40.73,92.5 40.98,94.32
					39.33,95.13 41.14,95.45 	"/>
				<polygon id="XMLID_1109_" style={{fill : color}} style={{fill : color}} points="49.25,96.25 49.82,98.01 50.39,96.25 52.23,96.25 50.74,95.17 51.31,93.42 49.82,94.5
					48.33,93.42 48.9,95.17 47.41,96.25 	"/>
				<polygon id="XMLID_1110_" style={{fill : color}} style={{fill : color}} points="57.39,95.64 58.25,97.27 58.51,95.45 60.32,95.13 58.66,94.32 58.92,92.5 57.64,93.82
					55.99,93.01 56.85,94.64 55.57,95.96 	"/>
				<polygon id="XMLID_1111_" style={{fill : color}} style={{fill : color}} points="65.29,93.63 66.42,95.08 66.36,93.24 68.08,92.61 66.32,92.1 66.25,90.26 65.22,91.79
					63.45,91.28 64.59,92.73 63.56,94.26 	"/>
				<polygon id="XMLID_1112_" style={{fill : color}} style={{fill : color}} points="73.47,88.59 73.08,86.79 72.34,88.47 70.51,88.28 71.87,89.51 71.12,91.19 72.72,90.27
					74.09,91.5 73.7,89.7 75.3,88.78 	"/>
				<polygon id="XMLID_1113_" style={{fill : color}} style={{fill : color}} points="79.45,85.68 81.01,86.65 80.32,84.95 81.73,83.76 79.9,83.89 79.21,82.19 78.76,83.97
					76.93,84.1 78.49,85.07 78.04,86.86 	"/>
				<polygon id="XMLID_1114_" style={{fill : color}} points="85.29,79.98 87,80.67 86.02,79.11 87.2,77.7 85.42,78.15 84.44,76.59 84.31,78.42 82.53,78.87
					84.23,79.56 84.11,81.39 	"/>
				<polygon id="XMLID_1115_" style={{fill : color}} points="89.85,71.53 88.62,70.16 88.82,71.99 87.13,72.74 88.93,73.12 89.13,74.95 90.05,73.36
					91.85,73.74 90.62,72.38 91.54,70.78 	"/>
				<polygon id="XMLID_1116_" style={{fill : color}} points="94.6,63.21 93.08,64.24 91.63,63.11 92.13,64.88 90.61,65.91 92.45,65.97 92.95,67.74
					93.58,66.01 95.42,66.08 93.97,64.94 	"/>
				<polygon id="XMLID_1117_" style={{fill : color}} points="96.31,55.23 94.98,56.51 93.36,55.64 94.16,57.3 92.84,58.58 94.66,58.32 95.47,59.97
					95.79,58.16 97.61,57.91 95.99,57.04 	"/>
				<polygon id="XMLID_1118_" style={{fill : color}} points="96.6,47.07 95.52,48.56 93.77,47.99 94.85,49.48 93.77,50.97 95.52,50.4 96.6,51.89 96.6,50.05
					98.35,49.48 96.6,48.91 	"/>
				<polygon id="XMLID_1119_" style={{fill : color}} points="92.84,40.38 94.16,41.66 93.36,43.32 94.98,42.45 96.31,43.73 95.99,41.92 97.61,41.05
					95.79,40.8 95.47,38.99 94.66,40.64 	"/>
				<polygon id="XMLID_1120_" style={{fill : color}} points="92.13,34.08 91.63,35.85 93.08,34.72 94.6,35.75 93.97,34.02 95.42,32.88 93.58,32.95
					92.95,31.22 92.45,32.99 90.61,33.05 	"/>
				<polygon id="XMLID_1121_" style={{fill : color}} points="88.82,26.97 88.62,28.8 89.85,27.43 91.54,28.18 90.62,26.59 91.85,25.22 90.05,25.6
					89.13,24.01 88.93,25.84 87.13,26.22 	"/>
				<polygon id="XMLID_1122_" style={{fill : color}} points="84.31,20.54 84.44,22.37 85.42,20.81 87.2,21.26 86.02,19.85 87,18.29 85.29,18.98 84.11,17.57
					84.23,19.4 82.53,20.09 	"/>
				<polygon id="XMLID_1123_" style={{fill : color}} points="78.76,14.99 79.21,16.77 79.9,15.07 81.73,15.2 80.32,14.01 81.01,12.31 79.45,13.28 78.04,12.1
					78.49,13.89 76.93,14.86 	"/>
				<polygon id="XMLID_1124_" style={{fill : color}} points="72.34,10.49 73.08,12.17 73.47,10.37 75.3,10.18 73.7,9.26 74.09,7.46 72.72,8.69 71.12,7.77
					71.87,9.45 70.51,10.68 	"/>
				<polygon id="XMLID_1125_" style={{fill : color}} points="65.22,7.17 66.25,8.7 66.32,6.86 68.08,6.35 66.36,5.72 66.42,3.88 65.29,5.33 63.56,4.7
					64.59,6.23 63.45,7.68 	"/>
				<polygon id="XMLID_1126_" style={{fill : color}} points="55.99,5.95 57.64,5.14 58.92,6.46 58.66,4.64 60.32,3.83 58.51,3.51 58.25,1.69 57.39,3.32
					55.57,3 56.85,4.32 	"/>
				<rect id="XMLID_1158_" x="34.57" y="76.66" style={{fill : "#FFFFFF" }} width="30.5" height="1.45"/>
			</g>
		</svg>

	)
}
