export default function Back6 ({color}){
	return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">
			<g>
				<g id="badge_1_">
					<g>
						<g>
							<g>
								<g>
									<path style={{fill : color }} d="M88.98,51.44H71.42c-0.4,0-0.72-0.2-0.72-0.46c0-0.25,0.32-0.46,0.72-0.46h17.57
										c0.4,0,0.72,0.21,0.72,0.46C89.7,51.23,89.38,51.44,88.98,51.44z"/>
								</g>
								<g>
									<path style={{fill : color }} d="M84.93,48.85H71.42c-0.4,0-0.72-0.21-0.72-0.46s0.32-0.46,0.72-0.46h13.51
										c0.4,0,0.72,0.21,0.72,0.46S85.33,48.85,84.93,48.85z"/>
								</g>
								<g>
									<path style={{fill : color }} d="M84.93,54.03H71.42c-0.4,0-0.72-0.21-0.72-0.46s0.32-0.46,0.72-0.46h13.51
										c0.4,0,0.72,0.21,0.72,0.46S85.33,54.03,84.93,54.03z"/>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<path style={{fill : color }} d="M11,51.44h17.57c0.4,0,0.72-0.2,0.72-0.46c0-0.25-0.32-0.46-0.72-0.46H11
										c-0.4,0-0.72,0.21-0.72,0.46C10.29,51.23,10.61,51.44,11,51.44z"/>
								</g>
								<g>
									<path style={{fill : color }} d="M15.06,48.85h13.51c0.4,0,0.72-0.21,0.72-0.46s-0.32-0.46-0.72-0.46H15.06
										c-0.4,0-0.72,0.21-0.72,0.46S14.66,48.85,15.06,48.85z"/>
								</g>
								<g>
									<path style={{fill : color }} d="M15.06,54.03h13.51c0.4,0,0.72-0.21,0.72-0.46s-0.32-0.46-0.72-0.46H15.06
										c-0.4,0-0.72,0.21-0.72,0.46S14.66,54.03,15.06,54.03z"/>
								</g>
							</g>
						</g>
					</g>
					<path style={{fill : color }} d="M74.31,4.85l-0.17-0.33H25.85L1.71,50.98l24.14,46.46h48.29l23.97-46.13l0.17-0.33L74.31,4.85z
						 M73.45,96.12H26.54L3.08,50.98L26.54,5.84h46.91l23.46,45.14L73.45,96.12z"/>
					<g>
						<path style={{fill : color }} d="M71.9,93.15H28.09L6.17,50.98l0.09-0.16L28.09,8.8H71.9l21.92,42.18l-0.08,0.16L71.9,93.15
							L71.9,93.15z M28.43,92.5h43.13l21.58-41.52L71.56,9.46H28.43L6.85,50.98L28.43,92.5L28.43,92.5z"/>
					</g>
				</g>
			</g>
		</svg>
	)
}
