import iconExcel from '../../assets/images/xcel.svg'
import iconPdf from '../../assets/images/pdf.svg'
import iconVideo from '../../assets/images/player.svg'
import iconDiscussion from '../../assets/images/discussion.svg'
import imgArrowDown from '../../assets/images/arrow-down.svg'

import styles from './help.module.scss'

export default function Help({documents, tool, experts = null}){

  function getHalfArray(part){
    let half_length = Math.ceil(documents.length / 2);
    let arr = [ ...documents ]
    if(part === 1){
      arr = arr.splice(0,half_length)
    }else if (part === 2) {
      arr = arr.splice(half_length,documents.length)
    }
    return arr
  }

  return (
    <div className={styles["container-help"]}>
      <h3>Besoin d’aide / d’inspiration ?<img src={imgArrowDown} alt="fleche" /></h3>
      <div className={styles["col-2"]}>
        <h3>Boîte à outils</h3>
        <p>
          {tool}
        </p>
        { documents.length>0 &&
          <ul>
           {
             getHalfArray(1).map((doc,i) => (
               <li key={`doc-1-${doc.type}-${i}`} style={doc.type === "" ? { display : "none"} : {}}>
                 { doc.type === "excel" &&
                  <a href={doc.file} target="_blank" rel="noopener noreferrer" download>
                    <div className={styles["doc-container"]}>
                      <img src={doc.img ? doc.img : iconExcel} alt="document téléchargeable excel" />
                    </div>
                    <label>{doc.label}</label>
                  </a>
                 }
                 { doc.type === "pdf" &&
                   <a href={doc.file} without rel="noopener noreferrer" target="_blank">
                     <div className={styles["doc-container"]}>
                       <img src={doc.img ? doc.img : iconPdf} alt="document téléchargeable pdf" />
                     </div>
                     <label>{doc.label}</label>
                   </a>
                 }
                 { doc.type === "video" &&
                  <a href={doc.file} without rel="noopener noreferrer" target="_blank">
                    <div className={styles["doc-container"]}>
                      <img src={doc.img ? doc.img : iconVideo} alt="video téléchargeable" />
                    </div>
                    <label>{doc.label}</label>
                  </a>
                 }
                 { doc.type === "discussion" &&
                  <a href={doc.file} without rel="noopener noreferrer" target="_blank">
                    <div className={styles["doc-container"]}>
                      <img src={doc.img ? doc.img : iconDiscussion} alt="lien discussion" />
                    </div>
                    <label>{doc.label}</label>
                    { doc.experts && <div className={styles["list-experts"]}>{doc.experts}</div>}
                  </a>
                 }


               </li>
             ))
           }
          </ul>
        }
      </div>
      <div className={styles["col-2"]}>
        <h3>Inspirations</h3>
        <p>
          Planches d'inspiration pour vous donner des idées.
        </p>
        { documents.length>0 &&
          <ul>
           {
             getHalfArray(2).map((doc,i) => (
               <li key={`doc-2-${doc.type}-${i}`}>
                 { doc.type === "excel" &&
                    <a href={doc.file} target="_blank" rel="noopener noreferrer" download>
                      <div className={styles["doc-container"]}>
                        <img src={doc.img ? doc.img : iconExcel} alt="document téléchargeable excel" />
                      </div>
                      <label>{doc.label}</label>
                    </a>

                 }
                 { doc.type === "pdf" &&
                  <a href={doc.file} without rel="noopener noreferrer" target="_blank">
                    <div className={styles["doc-container"]}>
                      <img src={doc.img ? doc.img : iconPdf} alt="document téléchargeable pdf" />
                    </div>
                    <label>{doc.label}</label>
                  </a>
                 }
                 { doc.type === "video" &&
                   <>
                     <div className={styles["doc-container"]}>
                       <img src={doc.img ? doc.img : iconVideo} alt="video téléchargeable" />
                     </div>
                      <label>{doc.label}</label>
                    </>
                 }



               </li>
             ))
           }
          </ul>
        }
      </div>
    </div>
  )
}
