export default function Front7({color}) {
	return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">
				<g id="XMLID_13_">
					<polygon style={{fill : color}} id="XMLID_1127_" points="70.25,29.19 72.17,31.11 73.96,25.01 67.86,26.81 69.78,28.72 50.68,47.82 31.59,28.72
						33.5,26.81 27.41,25.01 29.2,31.11 31.12,29.19 50.21,48.29 34.46,64.04 31.08,63.46 27.41,67.13 31.18,67.79 31.84,71.57
						35.52,67.89 34.93,64.51 50.68,48.76 66.44,64.51 65.85,67.89 69.53,71.57 70.18,67.79 73.96,67.13 70.28,63.46 66.91,64.04
						51.15,48.29 	"/>
				</g>
		</svg>
	)
}
