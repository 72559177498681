import {
  USER_JOIN_TEAM,
  USER_LEAVE_TEAM,
  GET_TEAMS,
  GET_TEAM,
  CREATE_TEAM,
  LOADING_TEAMS,
  ERROR_TEAMS
} from '../actions/types'

const initialState = {
  team : null,
  teams : null,
  isLoading : false,
  error : null
}

let updatedTeams;

const teams = (state = initialState, action) => {
    switch(action.type){
      case USER_JOIN_TEAM :
        updatedTeams = []
        state.teams.forEach(team => {
          if(team._id === action.payload.teamID){
            updatedTeams.push({...team, users : [ ...team.users, action.payload.user ] })
          }else{
            updatedTeams.push({...team})
          }
        })
        return { ...state, teams : updatedTeams , isLoading : false }
      case USER_LEAVE_TEAM :
        updatedTeams = []

        if(action.payload.deleteTeam){
          updatedTeams = state.teams.filter(team => team._id !== action.payload.teamID)
        }else{
          state.teams.forEach(team => {
            if(team._id === action.payload.teamID){
              updatedTeams.push({...team, users : team.users.filter(user => user._id !== action.payload.user._id) })
            }else{
              updatedTeams.push({...team})
            }
          })
        }

        return { ...state, teams : updatedTeams , isLoading : false }
      case GET_TEAM :
        return { ...state, team : action.payload, isLoading : false}
      case GET_TEAMS :
        return { ...state, teams : action.payload, isLoading : false }
      case CREATE_TEAM :
        return { ...state, team : action.payload, isLoading : true }
      case LOADING_TEAMS :
        return { ...state, isLoading : true}
      case ERROR_TEAMS :
        return { ...state, error : action.payload }
      default:
          return state
    }
}

export default teams
