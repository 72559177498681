import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { API_URL } from '../../actions'
import { getTeam } from '../../actions/teams'
import styles from './project.module.scss'
import Loader from '../../components/Loader'
import Badge from '../../components/Badge'
import Backward from '../../components/Backward'
import ButtonLike from '../../components/ButtonLike'

import imgMachine from '../../assets/images/machine.png'
import imgBaleine from '../../assets/images/baleine.png'

const Project = ({width, ws}) => {
  const params = useParams()
  const dispatch = useDispatch()
  const teamsReducer = useSelector(state => state.teamsReducer)
  const userReducer = useSelector(state => state.userReducer)
  const [ positionKeywords , setPositionKeywords ] = useState([])
  const [ wsUpdatedTeamData, setWsUpdatedTeamData] = useState()
  const [ project, setProject ] = useState()

  useEffect(()=>{
    getTeam(dispatch,params.id)
  },[])

  useEffect(()=>{
    console.log(project);
  },[project])

  useEffect(()=>{
    if(!teamsReducer.team || teamsReducer.isLoading) return;
    setProject(teamsReducer.team)
  },[teamsReducer.team,teamsReducer.isLoading])

  useEffect(()=>{
    return () => {
      setProject(null)
    }
  },[])


  // HANDLE MESSAGE
  useEffect(()=>{
    if(!ws) return;

    ws.addEventListener("message", listen);

    function listen (message) {
     if(message.data === `user connected`){
       return;
     }

     if(
       message.data && message.data !== "message received" &&
       message.data !== `${userReducer.user._id} connected`
     ){

      let data = JSON.parse(message.data)
      if(data.type === "likes updated" && data.teamId === params.id &&
        userReducer.user._id !== data.userId
      ){
        setWsUpdatedTeamData(data)
      }

     }
   }

   return function cleanupListener() {
     ws.removeEventListener('message', listen)
   }

  },[ws])

  //UPDATE TEAM WHEN WEBSOCKET CHANGE wsUpdatedTeamData
  useEffect(()=>{
    if(!project || !wsUpdatedTeamData) return;
    if(wsUpdatedTeamData.type === "likes updated"){
      setProject({ ...project, likes : wsUpdatedTeamData.action.likes})
      setWsUpdatedTeamData()
    }
  },[project, wsUpdatedTeamData])

  useEffect(()=>{
    if(!project || positionKeywords.length > 0) return;
    let container = document.querySelector(`#brainstorming`);
    if(!container) return;

    let containerBound = container.getBoundingClientRect()
    let min = containerBound.left
    let max = containerBound.right
    let positions = []
    let timer = setTimeout(()=>{
      container.childNodes.forEach((child)=>{
        let childBound = child.getBoundingClientRect()
        let minChild = containerBound.left + childBound.width
        let maxChild = containerBound.right - childBound.width
        let position = generateRandom(minChild,maxChild)
        position = childBound.left - position
        positions.push(position)
      })

      setPositionKeywords(positions)
      clearTimeout(timer)
    },100)

    return () => {
      timer && clearTimeout(timer)
    }

  },[project,positionKeywords])

  function generateRandom(min, max) {
    return min + Math.random()*(max + 1 - min)
  }


  return (
    <div className={styles["project"]}>
      <Backward to="/result"/>
      <ButtonLike project={project}/>
      <div className={styles["background"]}></div>
      <div className={styles["global"]}>

          {teamsReducer.isLoading && <Loader/>}
          { (!teamsReducer.isLoading && project && project.step === "result") &&
            <>
              {console.log("render",project)}
              <div className={styles["container"]}>
                <div className={`center ${styles["header"]}`}>
                  <Badge team={project} noLink large noProblematic/>
                  <h1>{project.name}</h1>
                  {project.baseline && <h3>{project.baseline}</h3>}
                </div>

                <div id={"brainstorming"} className={styles["brainstorming"]}>
                  {project.idea.map((keyword,i) =>
                    <label
                      key={`keyword-${i}`}
                      className={`keyword-${i}`}
                      style={{ transform : `translateX(${positionKeywords[i] ? positionKeywords[i] : 0}px)`}}
                    >
                      {Object.values(keyword)[0]}
                    </label>
                  )}
                </div>
              </div>

              <div className={styles["crazy6"]}>
                <img src={imgMachine} alt={"machine"} className={styles["machine"]}/>
                <img src={imgBaleine} alt={"baleine"} className={styles["whale"]}/>
                <div className={styles["container"]}>
                  <div className="center">
                    <h2>{project.crazy6.title}</h2>
                  </div>
                  <p>{project.crazy6.description}</p>
                  { project.crazy6.picture &&
                    <img src={`${API_URL}/${project.crazy6.picture.path}`} alt={project.crazy6.picture.alt}/>
                  }
                </div>
              </div>

              { project.pitch &&
                <div className={styles["pitch"]}>
                  <div className={styles["container"]}>
                    <video key={`video`} controls>
                      <source src={`${API_URL}/${project.pitch.path}`} type={'video/mp4'} />
                      Votre navigateur ne supporte pas les vidéos.
                    </video>
                  </div>
                </div>
              }


            </>
          }
      </div>
    </div>
  )
}

export default Project
