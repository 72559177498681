export default function Front4({color}) {
	return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<path style={{fill : color }} d="M42.92,71.8c-1.67,0-3.31-0.27-4.89-0.8l-0.21-0.07l-3.16-22.84c-0.22-1.6,0.19-3.19,1.17-4.48
										c0.86-1.14,1.23-2.54,1.03-3.95v-0.04l-0.27-7.29l-0.5,0.07l-0.41-4.96l6.31-0.87l0.95,4.88l-0.5,0.07l1.73,7.1v0.02
										c0.2,1.41,0.93,2.66,2.07,3.52c1.29,0.97,2.12,2.39,2.34,3.99L51.74,69l-0.18,0.12C49.01,70.87,46.02,71.8,42.92,71.8
										L42.92,71.8z"/>
								</g>
							</g>
							<g>
								<g>
									<path style={{fill : "#FFFFFF" }} d="M41.71,26.97l0.82,4.19L42,31.23l1.82,7.47c0.21,1.5,1,2.85,2.2,3.76s2,2.26,2.2,3.76
										l3.13,22.62c-2.51,1.73-5.46,2.62-8.44,2.62c-1.6,0-3.22-0.26-4.77-0.79l0,0l-3.13-22.62c-0.21-1.5,0.19-3.01,1.1-4.22
										c0.91-1.2,1.31-2.72,1.1-4.22l-0.28-7.68L36.41,32l-0.35-4.25L41.71,26.97L41.71,26.97z M42.27,26.18l-0.66,0.09l-5.65,0.78
										l-0.66,0.09l0.06,0.67l0.35,4.25l0.06,0.75l0.49-0.07l0.25,6.9v0.04v0.04c0.18,1.32-0.16,2.63-0.96,3.69
										c-1.03,1.36-1.47,3.05-1.24,4.74l3.13,22.62l0.06,0.43l0.41,0.14c1.61,0.55,3.3,0.82,5,0.82c3.17,0,6.22-0.95,8.84-2.74
										l0.36-0.25l-0.06-0.43l-3.13-22.62c-0.23-1.69-1.11-3.19-2.48-4.23c-1.06-0.8-1.75-1.97-1.93-3.29v-0.04l-0.01-0.03l-1.63-6.7
										l0.49-0.07l-0.14-0.73l-0.82-4.19L42.27,26.18L42.27,26.18z"/>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path style={{fill : "#FFFFFF" }} d="M38.68,47.53l-1.47,0.2c-0.15-1.11,0.13-2.22,0.81-3.12c0.33-0.44,0.61-0.91,0.83-1.4l1.35,0.61
									c-0.27,0.6-0.6,1.16-1,1.69C38.77,46.1,38.58,46.81,38.68,47.53z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path style={{fill : "#FFFFFF" }} d="M37.53,49.99l2.42,17.46c1.48,0.33,3.02,0.39,4.51,0.19c1.5-0.21,2.96-0.69,4.29-1.41
									l-2.42-17.46L37.53,49.99z"/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<path style={{fill : color }} d="M52.76,77.42c-3.86,0-7.56-1.18-10.71-3.42l-0.18-0.13l4.3-27.65c0.3-1.92,1.33-3.61,2.89-4.75
										c1.42-1.03,2.34-2.56,2.61-4.29l0.01-0.04l2.26-8.66l-0.62-0.1l1.23-5.77l7.51,1.17l-0.58,5.87l-0.62-0.1l-0.48,8.95v0.02
										c-0.27,1.73,0.15,3.47,1.19,4.88c1.15,1.57,1.61,3.49,1.32,5.41l-4.3,27.65l-0.21,0.07C56.56,77.12,54.67,77.42,52.76,77.42
										L52.76,77.42z"/>
								</g>
							</g>
							<g>
								<g>
									<path style={{fill : "#FFFFFF" }} d="M54.83,23.03l6.85,1.06l-0.51,5.16l-0.63-0.1l-0.5,9.33c-0.28,1.81,0.17,3.66,1.25,5.15
										c1.08,1.48,1.53,3.33,1.25,5.15L58.28,76.2c-1.81,0.58-3.67,0.86-5.52,0.86c-3.72,0-7.4-1.14-10.5-3.35l0,0l4.26-27.43
										c0.28-1.81,1.27-3.44,2.75-4.52s2.47-2.71,2.75-4.52l2.36-9.04l-0.63-0.1L54.83,23.03L54.83,23.03z M54.27,22.23l-0.14,0.66
										l-1.08,5.07l-0.16,0.73l0.6,0.09l-2.16,8.28l-0.01,0.03l-0.01,0.04c-0.25,1.64-1.13,3.08-2.47,4.06
										c-1.64,1.2-2.72,2.97-3.04,4.99l-4.26,27.43l-0.07,0.43l0.36,0.25c3.21,2.28,6.98,3.48,10.91,3.48c1.95,0,3.88-0.3,5.74-0.89
										l0.42-0.13l0.07-0.43l4.26-27.43c0.31-2.01-0.18-4.03-1.38-5.67c-0.98-1.34-1.38-2.98-1.12-4.62l0.01-0.04v-0.04l0.46-8.55
										l0.6,0.09l0.07-0.75l0.51-5.16l0.07-0.67l-0.66-0.1l-6.85-1.06L54.27,22.23L54.27,22.23z"/>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path style={{fill : "#FFFFFF" }} d="M50.97,46.98l-1.78-0.28c0.21-1.35,0.93-2.54,2.04-3.35c0.54-0.39,1.03-0.84,1.46-1.34
									l1.36,1.18c-0.52,0.6-1.11,1.14-1.76,1.62C51.57,45.33,51.1,46.1,50.97,46.98z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path style={{fill : "#FFFFFF" }} d="M48.76,49.43L45.47,70.6c1.61,0.9,3.37,1.51,5.19,1.8c1.82,0.28,3.69,0.23,5.49-0.14l3.29-21.17
									L48.76,49.43z"/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}
