
import styles from './step-navigation.module.scss'

export default function StepNavigation(){

  function scrollTo(targetId){
    let target = document.getElementById(targetId);
    let elementPosition = target.offsetTop;
    let headerOffset = 50;
    let offsetPosition = elementPosition - headerOffset;

    let containerElt = document.querySelector(".App")

    containerElt.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });

  }

  return (
    <ul className={styles["step-navigation"]}>
      <li><a onClick={() => scrollTo("1")}>Comment ça marche</a></li>
      <li><a onClick={() => scrollTo("2")}>Rendu de phase</a></li>
      <li><a onClick={() => scrollTo("3")}>Besoin d’aide / d’inspiration</a></li>
    </ul>
  )
}
