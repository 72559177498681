export default function Back3 ({color}){
  return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground: "new 0 0 100 100"}} xmlSpace="preserve">
			<g id="XMLID_9_">
				<path id="XMLID_x5F_2_x5F_" style={{ fill : "none"}} d="M6.3,50.8C6.3,75,26,94.7,50.2,94.7S94.1,75,94.1,50.8"/>
				<path id="XMLID_1143_" style={{ fill : color}} d="M86,24.3l-0.1-0.1l-2.5-0.3l-0.7,2.4l0.1,0.2c4.7,6.4,7.3,14,7.7,21.9h-1.8v0.8h1.8c0,0.3,0,0.6,0,1 c0,0.3,0,0.7,0,1h-1.8V52h1.8c-0.3,7.9-3,15.4-7.7,21.9l-0.1,0.2l0.7,2.4l2.5-0.3l0.1-0.1c5.1-7.1,7.9-15.3,8.3-24h2V51h-2 c0-0.3,0-0.7,0-1s0-0.6,0-1h2v-0.8h-2C93.9,39.6,91.1,31.3,86,24.3z M93.5,50c0,0.3,0,0.7,0,1h-2.3c0-0.3,0-0.7,0-1s0-0.6,0-1h2.3 C93.5,49.4,93.5,49.7,93.5,50z M83.9,75.4L83.5,74c4.7-6.5,7.3-14.1,7.7-22.2h2.3c-0.3,8.5-3.1,16.5-8,23.4L83.9,75.4z M91.2,48.3 c-0.3-8-3-15.7-7.7-22.2l0.4-1.4l1.6,0.2c4.9,6.9,7.7,15,8,23.4H91.2L91.2,48.3z"/>
				<path id="XMLID_1151_" style={{ fill : color}} d="M14.3,75.8l0.1,0.1l2.5,0.3l0.7-2.4l-0.1-0.2c-4.7-6.4-7.3-14-7.6-21.9h1.9V51H9.9c0-0.3,0-0.7,0-1 s0-0.6,0-1h1.9v-0.8H10c0.3-7.9,3-15.5,7.6-21.9l0.1-0.2L17,23.8l-2.5,0.3l-0.1,0.1c-5.1,7.1-7.9,15.3-8.3,24H4.2V49h1.9 c0,0.3,0,0.6,0,1c0,0.3,0,0.7,0,1H4.2v0.8h1.9C6.4,60.5,9.3,68.7,14.3,75.8z M6.8,50c0-0.3,0-0.6,0-1h2.3c0,0.3,0,0.6,0,1 c0,0.3,0,0.7,0,1H6.8C6.8,50.7,6.8,50.4,6.8,50z M16.4,24.7l0.4,1.4c-4.7,6.5-7.4,14.2-7.7,22.2H6.8c0.3-8.5,3.1-16.5,8-23.4 L16.4,24.7z M9.2,51.8c0.3,8,3,15.6,7.7,22.2l-0.4,1.4l-1.6-0.2c-4.9-6.9-7.7-14.9-8-23.4L9.2,51.8L9.2,51.8z"/>
				<path id="XMLID_1154_" style={{ fill : color}} d="M20.6,28.5c6.7-9.2,17.5-15.1,29.7-15.1s23,6,29.7,15.1l2.1-6.1l6.6-0.3C80,10.3,66,2.6,50.2,2.6 s-29.8,7.7-38.4,19.5l6.6,0.3L20.6,28.5z"/>
				<path id="XMLID_1155_" style={{ fill : color}} d="M82,77.6l-2.1-6.1c-6.7,9.2-17.5,15.1-29.7,15.1s-23-6-29.7-15.1l-2.1,6.1l-6.6,0.3 c8.6,11.8,22.6,19.5,38.4,19.5S80,89.7,88.6,77.9L82,77.6z"/>
			</g>
		</svg>
	)
}
