import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import InputText from '../../../components/InputText'
import StepNavigation from '../../../components/StepNavigation'
import ModalHurryUp from '../../../components/ModalHurryUp'
import Help from '../../../components/Help'

import styles from './brainstorming.module.scss'
import imgBrainstorming from '../../../assets/images/brainstorm.jpg'
import imgBrainstorming2 from '../../../assets/images/brainstorming-2.jpg'
import imgMachine from '../../../assets/images/machine.png'
import imgBaleine from '../../../assets/images/baleine.png'

import useHurryUp from '../../../hooks/useHurryUp'
import { editUserTeam } from '../../../actions/user'
import { getPathCurrentStep } from '../../../helpers'

import docTerrestre from './docs/moodboard-milieu-terrestre.pdf'
import docAqua from './docs/moodboard-milieu-aquatique.pdf'
import docSouterrain from './docs/moodboard-milieu-souterrain.pdf'

import imgTerrestre from '../../../assets/images/sur-terre.svg'
import imgAqua from '../../../assets/images/sous-leau.svg'
import imgSouterrain from '../../../assets/images/sous-terre.svg'

import docBestPractices from './docs/brainstorming-best-practices.pdf'
import brainstormingXLSX from './docs/memo-recap-Brainstorming.xlsx'
import brainstormingVideo from './docs/brainstorming.mp4'

export default function Brainstorming({isLeader}){
  const userReducer = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const history = useHistory()
  const [ keywords, setKeywords ] = useState({})
  const keyword1Ref = useRef()
  const keyword2Ref = useRef()
  const keyword3Ref = useRef()
  const keyword4Ref = useRef()
  const keyword5Ref = useRef()
  const keyword6Ref = useRef()

  const modalRef = useRef()

  useHurryUp(userReducer.team,10,modalRef,"idea")
  // useHurryUp(userReducer.team,15,modalRef,"idea")

  //UDPATE STEP WHEN PAGE MOUNT
  useEffect(()=>{
    if(isLeader === null || isLeader === false) return
    let idea = userReducer.team.idea ? userReducer.team.idea : []


    // DETECT IF WE OPEN IDEA STEP FOR THE FIRST TIME
    if(userReducer.team.step !== "idea"){
      editUserTeam(dispatch, { step : "idea", idea, stepStart : new Date() } ,userReducer.team._id)
    }else{
      editUserTeam(dispatch, { step : "idea", idea } ,userReducer.team._id)
    }
  },[isLeader])

  // useEffect(()=>{
  //   if(isLeader === null) return
  //   let idea = userReducer.team.idea ? userReducer.team.idea : []
  //   if(isLeader){
  //     initStep()
  //     async function initStep(){
  //       await editUserTeam(dispatch, { step : "idea", idea } ,userReducer.team._id)
  //       redirectToStep()
  //     }
  //
  //   }else if(isLeader === false){
  //     redirectToStep()
  //   }
  // },[isLeader])

  // function redirectToStep(){
  //   let pathCurrentStep = getPathCurrentStep(userReducer.team)
  //   if(history.location.pathname !== pathCurrentStep){
  //     console.log("redirect to pathCurrentStep", pathCurrentStep);
  //     history.push(pathCurrentStep)
  //   }
  // }

  //INIT KEYWORDSVALUES
  useEffect(()=>{
    if(!userReducer.team.idea) return;
    let initKeywords = {}
    userReducer.team.idea.forEach(keyword => {
      let key = Object.keys(keyword)[0]
      initKeywords.[key] = keyword[key]
      setKeyword(key,keyword[key])
    })
    setKeywords(initKeywords)
  },[])

  useEffect(()=>{
    //SCROLL TO TOP ON MOUNT
    let container = document.querySelector(".App")
    container.scrollTo(0, 0);
  },[])

  useEffect(()=>{
    if(!userReducer.team.idea || isLeader) return;
    userReducer.team.idea.forEach(keyword => {
      let key = Object.keys(keyword)[0]
      setKeyword(key,keyword[key])
    })
  },[userReducer.lastUpdate])

  function setKeyword(key,value){
    let target;
    switch (key) {
      case "keyword1":
        target = keyword1Ref
        break;
      case "keyword2":
        target = keyword2Ref
        break;
      case "keyword3":
        target = keyword3Ref
        break;
      case "keyword4":
        target = keyword4Ref
        break;
      case "keyword5":
        target = keyword5Ref
        break;
      case "keyword6":
        target = keyword6Ref
        break;
    }
    target.current.setValue(value)
  }

  function handleKeywordChange(value,name){
    let updatedKeywords = { ...keywords, [name] : value}
    let arrKeywords = Object.keys(updatedKeywords).map(key => ({ [key] : updatedKeywords[key] }))
    editUserTeam(dispatch, { idea : arrKeywords } ,userReducer.team._id)
    setKeywords(updatedKeywords)
  }

  function goToHelp(){
    let target = document.getElementById("3");
    let elementPosition = target.offsetTop;
    let headerOffset = 50;
    let offsetPosition = elementPosition - headerOffset;

    let containerElt = document.querySelector(".App")

    containerElt.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
  }

  function getNextClassName(){
    let filterKeywords = Object.values(keywords).filter(keyword => keyword !== "")
    if(!filterKeywords || (filterKeywords && filterKeywords.length < 6)){
      return 'secondary disabled'
    }else{
      return "secondary"
    }
  }

  return (
    <div className={styles["brainstorming"]} >
      <ModalHurryUp ref={modalRef}/>
      <div className={styles["background"]}>
      </div>
      <div className={styles["global"]}>
        <div className={styles["container"]}>
          <div className={"center"}>
            <h1>Foire aux idées <span>1/3 - 15min</span></h1>
          </div>
          <StepNavigation/>
        </div>

        <section id="1" className={styles["first-section"]}>
          <img src={imgMachine} alt={"machine"}/>
          <div className={styles["container"]}>
            <div className={styles["col-2"]}>
              <img src={imgBrainstorming} alt="brainstorm" />
            </div>
            <div className={styles["col-2"]}>
              <h3>Comment ça marche ?</h3>
              <p>
                Cette étape a pour but d’échanger entre vous et de choisir au final les 6 mots-clés qui vous aideront à synchroniser l’équipe autour de l’objectif et des grands axes de réflexion.
              </p>
              <p>
                <b>Vous avez bien votre mission en tête ?<br/></b>
                Voici les étapes à suivre :
              </p>
              <ul>
                <li>
                  Chacun note des mots, des expressions ou dessine ses idées.
                </li>
                <li>
                  Mettez en commun en faisant un tour de table.
                </li>
                <li>
                  Echangez et choisissez les 6 mots forts à conserver pour vous aider à construire le concept phare !
                </li>
              </ul>
              <p>
                <a onClick={goToHelp} style={{cursor: "pointer", textDecoration : "underline", fontWeight : "bold"}}>Consultez les outils et les planches d'inspiration pour vous aider ! </a>
              </p>
              <button onClick={goToHelp}>Besoin d'aide ?</button>
            </div>
            <div className={styles["example"]}>
              <p>Exemple</p>
              <img src={imgBrainstorming2} alt="exemple brainstorming sur papier" />
            </div>
          </div>
        </section>

        <section id="2" className={styles["keywords-section"]}>
          <img src={imgBaleine} alt={"baleine"} className={styles["first-whale"]}/>
          <img src={imgBaleine} alt={"baleine"} className={styles["second-whale"]}/>
          <div className={styles["container"]}>
            <h3>Rendu pour cette phase : </h3>
            <p>Renseignez les 6 mots-clés qui résument cette première phase de réflexion</p>
            <p>
              <b>
                Attention, une seule et même réponse pour toute l’équipe. Seul le leader à la main !<br/>
                (Ce contenu sera intégré à la présentation de votre concept, et viendra alimenter le plateau de jeu)
              </b>
            </p>
            <div className={styles["container-inputs"]}>
              <InputText disabled={!isLeader} maxLength="25" name="keyword1" placeholder="Mot-clé 01" ref={keyword1Ref} handleChange={handleKeywordChange}/>
              <InputText disabled={!isLeader} maxLength="25" name="keyword2" placeholder="Mot-clé 02" ref={keyword2Ref} handleChange={handleKeywordChange}/>
              <InputText disabled={!isLeader} maxLength="25" name="keyword3" placeholder="Mot-clé 03" ref={keyword3Ref} handleChange={handleKeywordChange}/>
              <InputText disabled={!isLeader} maxLength="25" name="keyword4" placeholder="Mot-clé 04" ref={keyword4Ref} handleChange={handleKeywordChange}/>
              <InputText disabled={!isLeader} maxLength="25" name="keyword5" placeholder="Mot-clé 05" ref={keyword5Ref} handleChange={handleKeywordChange}/>
              <InputText disabled={!isLeader} maxLength="25" name="keyword6" placeholder="Mot-clé 06" ref={keyword6Ref} handleChange={handleKeywordChange}/>
            </div>
            {isLeader &&
              <Link to="/game/crazy6">
                <button className={getNextClassName()}>Étape suivante</button>
              </Link>
            }
          </div>
        </section>

        <section id="3" className={styles["section-help"]}>
          <Help documents={[
            {type : "excel", label : "Un mémo, pour vous aider à récapituler vos idées", file : brainstormingXLSX},
            {type : "pdf", label : "Best practices", file : docBestPractices},
            {type : "discussion", label : "Joignez nos experts", file : brainstormingVideo, experts : <ListExpert/>},
            {type : "pdf", label : "L'univers terrestre", file : docTerrestre, img : imgTerrestre},
            {type : "pdf", label : "L'univers aquatique", file : docAqua, img : imgAqua},
            {type : "pdf", label : "L'univers sous-terrain", file : docSouterrain, img : imgSouterrain}
          ]}
          tool="Pour mieux gérer votre tempête d’idées :"/>
        </section>
      </div>
    </div>
  )
}

const ListExpert = () => {
  return (
    <ul>
      <li><a target="_blank"  href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_MDkwYTkxMWQtY2ZkMi00MWJjLTk1MTItYTE0NjFkYmExZTlj%40thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%2522d183af0c-d17a-41b8-a3b1-13e45bf37a7b%2522%252c%2522Oid%2522%253a%2522a444d608-8ca9-4274-8070-44d345d37e02%2522%257d%26anon%3Dtrue&type=meetup-join&deeplinkId=a423533f-9fcc-4b12-9e8e-cc2960fd82e3&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true">Expert 1</a></li>
      <li><a target="_blank"  href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_MDdmMmQ4YWItZjc3ZS00YWEyLTg2OTctZDliMTkyNTUzMTZl%40thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%2522d183af0c-d17a-41b8-a3b1-13e45bf37a7b%2522%252c%2522Oid%2522%253a%2522a12d62b8-1020-4aa3-b0d0-79f0b4bc7fe8%2522%257d%26anon%3Dtrue&type=meetup-join&deeplinkId=917c0a55-5e5e-417d-832d-e9ddecb66818&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true">Expert 2</a></li>
      <li><a target="_blank"  href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_Y2RjYWRlM2YtYmM4NS00ZWYzLWEyOWItMDg2MzI4OTU5ZDc1%40thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%2522d183af0c-d17a-41b8-a3b1-13e45bf37a7b%2522%252c%2522Oid%2522%253a%2522060c043a-ff9f-4928-89b5-448c45778afe%2522%257d%26anon%3Dtrue&type=meetup-join&deeplinkId=8539f4d4-dbcb-46c6-9abb-6be35696222a&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true">Expert 3</a></li>
    </ul>
  )
}
