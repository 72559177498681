export default function Front2({color}) {
	return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">
		<g>
			<g>
				<path style={{fill: color}} d="M46.72,39.24c-0.62-0.49-2.25-2.04-3.43-3.18l1.24-1.24l-5.77-5.77l-7.01,7.01l0,0c-2.42,0.85-11.59,3.87-11.59,3.87
					s0.13,4.08,4.77,8.72c4.61,4.61,8.72,4.77,8.72,4.77s3.02-9.18,3.87-11.59l2.02-2.02v0c1.07,1.95,3.12,5.44,5.86,9
					c1.6-2.02,2.78-3.86,3.68-5.42C47.84,41.15,47.21,39.64,46.72,39.24z"/>
				<path style={{fill: color}} d="M55.56,52.38c-0.33-0.36-0.64-0.71-0.94-1.06c-1.07,1.3-2.23,2.59-3.46,3.79c4.98,4.44,6.39,9.06,6.78,11.55
					c0.2,1.28,1.1,2.47,2.32,3.04c1.59,0.74,3.6,1.43,4.46,0.69c0.93-0.8,2.47-2.47,2.47-2.47S68.53,66.57,55.56,52.38z"/>
			</g>
			<path style={{fill: color}} d="M68.25,36.06L68.25,36.06l-7.01-7.01l-5.77,5.77l1.24,1.24c-1.18,1.14-2.81,2.69-3.43,3.18
				c-1.02,0.81-2.61,6.32-8.84,13.13c-12.97,14.2-11.63,15.54-11.63,15.54s1.54,1.67,2.47,2.47c0.86,0.74,2.87,0.05,4.46-0.69
				c1.22-0.57,2.12-1.76,2.32-3.04c0.39-2.5,1.8-7.13,6.79-11.56c5.59-4.96,9.87-12.15,11.6-15.29l2.02,2.02
				c0.85,2.42,3.87,11.59,3.87,11.59s4.12-0.17,8.72-4.77c4.64-4.64,4.77-8.72,4.77-8.72S70.67,36.9,68.25,36.06z"/>
		</g>
		</svg>

	)
}
