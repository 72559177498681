import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { differenceInSeconds } from 'date-fns'
import styles from "./waiting.module.scss"
import { dateStart } from '../../../config';
import logo from '../../../assets/images/logo.svg'
import video from '../../../assets/videos/teaser_keran_final.mp4'

import teaserPoster from '../../../assets/images/poster-teaser.png'

export default function Waiting ({launchGame}){
  const [ countDown, setCountDown ] = useState()

  useEffect(()=>{
    // years, month, days, hours, minutes, seconds
    let startingDate = dateStart

    let timer = setInterval(()=>{

      let nowDate = new Date();
      let distanceInSeconds = differenceInSeconds(nowDate, startingDate)
      if( distanceInSeconds <= 0){

        distanceInSeconds = Math.abs(distanceInSeconds)
        let days = Math.floor(distanceInSeconds / 86400)
        let restInSeconds = distanceInSeconds - (days*86400)

        let hours = Math.floor(restInSeconds / 3600)
        restInSeconds = restInSeconds - ( hours * 3600)

        let minutes = Math.floor(restInSeconds / 60)
        let seconds = restInSeconds - ( minutes * 60)

        setCountDown({
          days : days < 10 ? "0"+days : days.toString(),
          hours : hours < 10 ? "0"+hours : hours.toString(),
          minutes : minutes < 10 ? "0"+minutes : minutes.toString(),
          seconds : seconds < 10 ? "0"+seconds : seconds.toString(),
        })

        if(distanceInSeconds === 0){
          launchGame()
          clearInterval(timer)
        }

      }

    },1000)


  },[])


  return (
    <div className={styles["waiting"]}>
    <img className={styles["logo"]} src={logo} alt="logo keran"/>
      { countDown &&
        <div className={styles["count-down"]}>
          <label>L'évenement commence dans</label>
          <p>{`${countDown.days}j ${countDown.hours}h ${countDown.minutes}min ${countDown.seconds}s`}</p>
        </div>
      }
      <div className={styles["content"]}>
        <div className={styles["container-video"]}>
          <video controls poster={teaserPoster}>
            <source src={video}
            type="video/mp4"/>
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
        <div className={styles["container-intro"]}>
          <h1>DEMANDEZ LE PROGRAMME !</h1>
          <p>
            ★ Un temps d’information : Réunion plénière<br/>
            ★ Un temps d’échange et de création :<br/>
            Participez à une expérience nouvelle !<br/>
            Découvrez sans plus tarder l’univers dans lequel nous vous proposons de plonger en regardant la vidéo ci-contre !<br/>
            ★ Un temps festif : le temps de trinquer ensemble..(en respectant les mesures sanitaires en vigueur)<br/>
          </p>
          <NavLink to={"/teams"} className={styles["button"]}><button>Créer / Rejoindre une équipe</button></NavLink>

        </div>
      </div>
    </div>
  )
}
