export default function Front3({color}) {
	return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">
			<path style={{fill : color }} d="M63.01,65.75c0.81-1.12,1.25-2.54,1.12-4.06c-0.24-2.91-2.59-5.26-5.5-5.51c-0.31-0.03-0.61-0.03-0.9-0.01
				c-1.22,0.08-2.37-0.58-2.91-1.68l-2.08-4.25c2.96-4.55,6.18-9.47,6.85-10.43c2.75-3.95-0.36-10.78-0.36-10.78L50.8,46.26
				l-8.43-17.23c0,0-3.11,6.83-0.36,10.78c0.67,0.96,3.88,5.88,6.85,10.43L47,54.04c-0.64,1.3-1.96,2.15-3.41,2.12
				c-0.21,0-0.42,0-0.63,0.02c-2.91,0.25-5.26,2.59-5.5,5.51c-0.31,3.68,2.69,6.75,6.35,6.56c3.07-0.16,5.56-2.66,5.72-5.73
				c0.09-1.8-0.61-3.43-1.78-4.6c0.56-0.86,1.69-2.61,3.05-4.69c1.36,2.09,2.49,3.83,3.05,4.69c-1.17,1.16-1.86,2.8-1.77,4.6
				c0.15,3.07,2.65,5.57,5.72,5.73c1.36,0.07,2.63-0.31,3.67-1l0.25,0.31c0.37,0.46,1.05,0.54,1.52,0.17c0.46-0.37,0.54-1.05,0.17-1.51
				L63.01,65.75z M43.47,66.3c-2.26,0-4.09-1.83-4.09-4.09s1.83-4.09,4.09-4.09s4.09,1.83,4.09,4.09C47.57,64.47,45.73,66.3,43.47,66.3
				z M50.74,50.06c-0.63,0-1.14-0.51-1.14-1.14s0.51-1.14,1.14-1.14s1.14,0.51,1.14,1.14S51.37,50.06,50.74,50.06z M61.53,64.45
				c-0.3,0.46-0.7,0.86-1.16,1.17c-0.65,0.43-1.43,0.69-2.27,0.69c-2.26,0-4.09-1.83-4.09-4.09s1.83-4.09,4.09-4.09
				s4.09,1.83,4.09,4.09C62.2,63.04,61.95,63.81,61.53,64.45z"/>
		</svg>
	)
}
