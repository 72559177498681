import { useRef, useEffect } from 'react';
import { useHistory  } from 'react-router-dom'

export default function useHurryUp(team, delayMin = 10, modalHurryUpRef, step) {
  const timer = useRef()
  const history = useHistory()

  //HANDLE HURRY UP POPUP
  useEffect(()=>{
    if(team.stepStart === "" || !modalHurryUpRef.current || team.step !== step) return;
    let dateNow = Date.now();
    let dateStepStart = new Date(team.stepStart)
    let distanceTimestamp = Math.floor((dateNow - dateStepStart.getTime())/1000)
    let delay = delayMin * 60 + 0
    if(delay > distanceTimestamp){

      timer.current = setInterval(()=>{
        let dateNow = Date.now();
        let dateStepStart = new Date(team.stepStart)
        let distanceTimestamp = Math.floor((dateNow - dateStepStart.getTime())/1000)

        if(delay < distanceTimestamp){
          modalHurryUpRef.current.openModal();
          clearInterval(timer.current)
        }

      },1000)

    }
  },[team.stepStart])

  useEffect(()=>{
    return () =>{
      clearInterval(timer.current)
    }
  },[])

}
