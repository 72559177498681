export default function Front8({color}) {
	return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">
			<path style={{fill : color}} d="M73.17,59.42l-5.74-2.39l0.41-0.71l-1.58-0.91c0.74-1.89,1.15-3.95,1.15-6.11s-0.41-4.22-1.15-6.11l1.58-0.91l-0.41-0.71
				l5.74-2.39c1.39-0.58,1.95-2.24,1.2-3.55l0,0c-0.75-1.3-2.48-1.65-3.67-0.73l-4.94,3.77l-0.41-0.71l-1.59,0.92
				c-2.57-3.22-6.31-5.47-10.58-6.11v-1.83h-0.82l0.8-6.16c0.19-1.49-0.97-2.81-2.47-2.81l0,0c-1.5,0-2.66,1.32-2.47,2.81l0.8,6.16
				H48.2v1.83c-4.27,0.64-8.01,2.89-10.58,6.11l-1.59-0.92l-0.41,0.71l-4.94-3.77c-1.19-0.91-2.92-0.57-3.67,0.73l0,0
				c-0.75,1.3-0.19,2.97,1.2,3.55l5.74,2.39l-0.41,0.71l1.58,0.91c-0.74,1.89-1.15,3.95-1.15,6.11s0.41,4.22,1.15,6.11l-1.58,0.91
				l0.41,0.71l-5.74,2.39c-1.39,0.58-1.95,2.24-1.2,3.55l0,0c0.75,1.3,2.48,1.65,3.67,0.73l4.94-3.77l0.41,0.71l1.59-0.92
				c2.57,3.22,6.31,5.47,10.58,6.11v1.83h0.82l-0.8,6.16c-0.19,1.49,0.97,2.81,2.47,2.81l0,0c1.5,0,2.66-1.32,2.47-2.81l-0.8-6.16h0.82
				v-1.83c4.27-0.64,8.01-2.89,10.58-6.11l1.59,0.92l0.41-0.71l4.94,3.77c1.19,0.91,2.92,0.57,3.67-0.73l0,0
				C75.12,61.66,74.55,60,73.17,59.42z M38.44,49.29c0-1.83,0.4-3.57,1.13-5.14l8.05,4.65c-0.03,0.16-0.04,0.32-0.04,0.49
				c0,0.17,0.01,0.33,0.04,0.49l-8.05,4.65C38.85,52.86,38.44,51.12,38.44,49.29z M50.68,50.27c-0.54,0-0.98-0.44-0.98-0.98
				s0.44-0.98,0.98-0.98s0.98,0.44,0.98,0.98S51.22,50.27,50.68,50.27z M53.75,48.8l8.05-4.65c0.72,1.56,1.13,3.3,1.13,5.14
				c0,1.83-0.4,3.57-1.13,5.14l-8.05-4.65c0.03-0.16,0.04-0.32,0.04-0.49C53.79,49.12,53.78,48.96,53.75,48.8z M60.69,42.23l-8.04,4.64
				c-0.25-0.21-0.54-0.37-0.85-0.49V37.1C55.46,37.43,58.67,39.38,60.69,42.23z M49.57,37.1v9.29c-0.31,0.12-0.6,0.28-0.85,0.49
				l-8.04-4.64C42.7,39.38,45.9,37.43,49.57,37.1z M40.68,56.34l8.04-4.64c0.25,0.21,0.54,0.37,0.85,0.49v9.29
				C45.9,61.15,42.7,59.2,40.68,56.34z M51.79,61.48v-9.29c0.31-0.12,0.6-0.28,0.85-0.49l8.04,4.64C58.67,59.2,55.46,61.15,51.79,61.48
				z"/>
		</svg>
	)
}
