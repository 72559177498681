import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import usePrevious from '../../../hooks/usePrevious'
import { sendBadge, createTeam } from '../../../actions/teams'

import iconInfoBox from '../../../assets/images/icon-infobox.svg'
import styles from './create-team.module.scss'
import ContainerTeams from '../ContainerTeams'
import CreateBadge from '../../../components/CreateBadge'
import InputText from '../../../components/InputText'
import Loader from '../../../components/Loader'
import ModalLeader from '../../../components/ModalLeader'

export default function CreateTeam(){
  const [ creationLoading, setCreationLoading ] = useState(false)
  const [ firstCharacter, setFirstCharacter ] = useState(false)
  const [ isDisabled, setIsDisabled ] = useState(true)
  const [ touchFields, setTouchFields ] = useState({})
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const creationWasLoading = usePrevious(creationLoading)
  const dispatch = useDispatch()
  const history = useHistory()
  const inputNameRef = useRef()
  const inputBaselineRef = useRef()
  const createBadgeRef = useRef()


  // HANDLE REDIRECT AFTER CREATED TEAM DONE
  useEffect(()=>{
    if(creationWasLoading && !creationLoading){
      history.push('/teams')
    }
  },[creationLoading,creationWasLoading])

  //HANDLE DISABLED
  useEffect(()=>{
    if(touchFields.name !== "" && touchFields.baseline !== "" && touchFields.badge){
      setIsDisabled(false)
    }else{
      setIsDisabled(true)
    }
  },[touchFields])

  function handleChange(value,name){
    if(name === 'name'){
      setFirstCharacter(value[0])
    }
    let values = { ...touchFields, [name] : value}
    setTouchFields(values)
  }

  function handleChangeDrop(){
    setTouchFields({...touchFields, badge : true})
  }

  async function submit(){
    if(isDisabled) return;

    let formData = new FormData();
    let file = await createBadgeRef.current.getBadge()

    formData.append("file",file)
    formData.append("title", "title")
    formData.append("description", "description")
    formData.append("alt", "alt")

    let badge = await sendBadge(dispatch,formData);

    if(badge._id){
      const obj = {
        ...inputNameRef.current.getObject(),
        ...inputBaselineRef.current.getObject(),
        picture : badge._id
      }

      setCreationLoading(true)
      await createTeam(dispatch,obj,badge)
      setCreationLoading(false)

    }

  }

  return (
    <ContainerTeams>
      { creationLoading && <div className={styles["container-loader"]}><Loader /></div>}
      <div className={`${styles["form-team"]} ${ creationLoading ? styles["disabled"] : ''}`}>
        <div className={styles["center"]}>
          <h1>Création d'équipe</h1>
          <p className={styles["leader-vote"]}>
            Vous êtes leader de l'équipe !
            <img src={iconInfoBox} alt="information leader" onClick={()=>setIsModalOpen(true)}/>
          </p>

        </div>
        <div className={styles["container-inputs"]}>
          <div>
            <InputText name="name" handleChange={handleChange} placeholder="Nom de l'équipe" ref={inputNameRef}/>
          </div>
          <div>
            <InputText name="baseline" handleChange={handleChange} placeholder="Devise de l'équipe" ref={inputBaselineRef}/>
          </div>
        </div>
        <CreateBadge ref={createBadgeRef} firstCharacter={firstCharacter} handleChangeDrop={handleChangeDrop}/>
        <button className={isDisabled ? "circular disabled" : "circular"} style={{marginTop: -50}} onClick={submit}>Ok</button>
      </div>
      { isModalOpen &&
        <ModalLeader toggleClose={() => setIsModalOpen(false)} />
      }
    </ContainerTeams>
  )
}
