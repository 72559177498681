import styles from './modal-user.module.scss'
import iconClose from '../../assets/images/close-modal.svg'

export default function ModalLeader({toggleClose}){
  return (
    <>
      <div className={styles["modal-leader"]}>
        <div className={styles["close"]} onClick={toggleClose}>
          <img src={iconClose} alt="close" />
        </div>
        <h2>Le leader</h2>
        <p>
          Celui qui est capable de déceler le cap dans un ciel sans visibilité et de communiquer sa confiance à tout son équipage, capable d'en obtenir l'adhésion et de faire comprendre à tous combien il est dorénavant nécessaire de relever le défi et de réagir vite...
        </p>
      </div>
      <div className={styles["modal-leader-overlay"]} onClick={toggleClose}>
      </div>
    </>
  )
}
