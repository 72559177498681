import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import styles from './list-teams.module.scss'

import { API_URL, getData } from '../../../actions'
import { ERROR_TEAMS } from '../../../actions/types'

import { getTeams } from '../../../actions/teams'
import { leaveTeam, joinTeam, setUserTeam } from '../../../actions/user'
import { getLeader } from '../../../helpers'

import ContainerTeams from '../ContainerTeams'

export default function ListTeams({ws}){
  const dispatch = useDispatch()
  const [ isLeader, setIsLeader ] = useState()
  const [ teams, setTeams ] = useState();
  const [ wsUpdatedTeamData, setWsUpdatedTeamData] = useState()
  const teamsReducer = useSelector(state => state.teamsReducer)
  const userReducer = useSelector(state => state.userReducer)

  // GET TIME
  useEffect(()=>{
    getTeams(dispatch);
  },[])

  useEffect(()=>{
    if(!ws) return;
    ws.addEventListener("message", listen);

    function listen(message){
      let data = JSON.parse(message.data)
      if(
        data.userId !== userReducer.user._id
      ){
        setWsUpdatedTeamData(data)
      }
    }

    return function cleanupListener() {
      ws.removeEventListener('message', listen)
    }

  },[ws])


  //UDPATE TEAM WITH WS DATA
  useEffect(()=>{
    if(!teams || !wsUpdatedTeamData) return;

    // data.type
    // "team created"
    // "team deleted"
    // "team updated"
    // "leave team"
    // "join team"
    // "vote change"

    if(wsUpdatedTeamData.type === "team created"){

      getTeams(dispatch);

    }if(wsUpdatedTeamData.type === "team deleted"){

      let updatedTeams = teams.filter(team=>{
        return team._id !== wsUpdatedTeamData.teamId
      })
      setTeams(updatedTeams)

    }else if(wsUpdatedTeamData.type === "team updated"){
      let updatedTeams = teams.map(team=>{
        let updatedTeam = team
        if(updatedTeam._id === wsUpdatedTeamData.teamId ){
          updatedTeam = { ...updatedTeam, ...wsUpdatedTeamData.action}
        }
        return updatedTeam
      })
      setTeams(updatedTeams)
    }else if(wsUpdatedTeamData.type === "join team"){
      let updatedTeams = teams.map(team=>{
        let updatedTeam = team
        if(updatedTeam._id === wsUpdatedTeamData.teamId ){
          let updatedUsers = [...team.users, { email : wsUpdatedTeamData.action , _id : wsUpdatedTeamData.userId }]
          updatedTeam = { ...updatedTeam, users : updatedUsers}
        }
        return updatedTeam
      })
      setTeams(updatedTeams)
    }else if(wsUpdatedTeamData.type === "leave team"){
      let updatedTeams = teams.map(team=>{
        let updatedTeam = team
        if(updatedTeam._id === wsUpdatedTeamData.teamId ){
          let updatedUsers = team.users.filter(user => user._id !== wsUpdatedTeamData.userId )
          updatedTeam = { ...updatedTeam, users : updatedUsers}
        }
        return updatedTeam
      })

      if(userReducer.team && (wsUpdatedTeamData.teamId === userReducer.team._id)){
        setUserTeam(dispatch,wsUpdatedTeamData)
      }

      setTeams(updatedTeams)
    }else if(wsUpdatedTeamData.type === "vote change"){

      if(userReducer.team && wsUpdatedTeamData.teamId === userReducer.team._id){
        let url = '/team/'+wsUpdatedTeamData.teamId
        let team;
        getData( ERROR_TEAMS, url, dispatch, true).then((response)=>{
          team = response.data.team
          setUserTeam(dispatch,team,wsUpdatedTeamData.type)

          let updatedTeams = teams.map(t=>{
            let updatedTeam = t
            if(updatedTeam._id === wsUpdatedTeamData.teamId ){
              updatedTeam.votes = team.votes
            }
            return updatedTeam
          })

          setTeams(updatedTeams)

        })

      }

    }


  },[wsUpdatedTeamData])

  useEffect(()=>{

    let leader = getLeader(userReducer.team?.votes)
    setIsLeader(leader === userReducer.user.email)

  },[userReducer.team?.votes])

  useEffect(()=>{
    if(!teamsReducer.teams) return;

    let sortedTeams = sortTeams(teamsReducer.teams)
    setTeams(sortedTeams)

  },[teamsReducer.teams])

  function sortTeams(teams){
    let sortedTeams = teams.filter(team => !team.inGame && team.users.length>0)

    if(sortedTeams.length > 0){
      sortedTeams.sort(function(a,b){
        return new Date(b.date) - new Date(a.date);
      });
    }

    return sortedTeams.reverse()
  }

  return (
    <ContainerTeams>
      <div className="center"><h1>Les équipes existantes</h1></div>
      <ul className={styles["list-teams"]} >
        { teams && teams.map(team =>
          <ItemTeam
             key={`team-${team._id}`}
             userTeam={userReducer.user.team}
             team={team}
             isLeader={isLeader}
          />
        )}
      </ul>
      { !userReducer.user.team &&
        <Link to="/teams/create">
          <button className="circular">
            Créer une équipe
          </button>
        </Link>
      }
    </ContainerTeams>
  )
}

const ItemTeam = ({team, userTeam, isLeader}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [ isOpen, setIsOpen] = useState(false);

  function isUserTeam(){
    return userTeam === team._id
  }

  function joinThisTeam(e,id){
    e.preventDefault();
    e.stopPropagation();
    joinTeam(dispatch,id)
  }

  function leaveMyTeam(e,teamId){
    e.preventDefault();
    e.stopPropagation();

    let deleteTeam = team.users.length === 1

    // leaderTeam(
    // dispatch
    // teamId
    // deleteTeam
    // )

    leaveTeam(dispatch,teamId,deleteTeam)
  }

  function goTo(e,id){
    e.preventDefault();
    e.stopPropagation();
    if(isLeader){
      history.push(`/teams/edit`)
    }else{
      history.push(`/teams/${id}`)
    }
  }

  function userName(email){
    return email.replace(/@(.*)$/,"")
  }

  return (
    <li className={`${isUserTeam() ? styles["current-team"] : ""}`}>
      <div className={styles["col-right"]}>
        <div className={styles["toggle-team"]}>
          {(team.users && team.users.length>0 ) &&
            <>
              { isOpen ? <FiChevronUp onClick={()=> setIsOpen(false)} /> : <FiChevronDown onClick={()=> setIsOpen(true)} />}
            </>
          }
        </div>
        <div className={styles["badge-team"]} onClick={isUserTeam() ? (e) => goTo(e,team._id) : () => {} }>
          <img src={`${API_URL}/${team.picture.path}`} alt={team.baseLine}/>
        </div>
        <div onClick={isUserTeam() ? (e) => goTo(e,team._id) : () => {} }>
          <p className={styles["team-name"]} >{team.name ? team.name : "Non renseigné"}</p>
          <p className={styles["team-baseline"]}>{team.baseline ? team.baseline : "Non renseigné"}</p>
        </div>
      </div>

      <div>
        <span className={styles["count-users"]}><p>{`${team.users.length}/6`}</p></span>

        { userTeam === team._id &&
          <>
            { isLeader ?
              <>
                {team.users.length === 1 ?
                  <div className={styles["btn"]}><a onClick={(e)=>leaveMyTeam(e,team._id)}>Supprimer</a></div> :
                  <div className={styles["btn"]}></div>
                }
              </> :
              <div className={styles["btn"]}><a onClick={(e)=>leaveMyTeam(e,team._id)}>Quitter</a></div>
            }
          </>
        }

        { (team.users.length < 6 && !userTeam) ?
          <div className={styles["btn"]}> <a onClick={(e)=>joinThisTeam(e,team._id)}>Rejoindre</a></div> :
          <>
            { userTeam !== team._id && <div className={styles["btn"]}></div>}
          </>
        }

      </div>
      { isOpen &&
        <div className={styles["list-user"]}>
          { team.users.map(user =>
            <p key={`user-${user._id}`}>{userName(user.email)}</p>
          )}
        </div>
      }
    </li>
  )
}
