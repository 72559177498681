export default function Back5 ({color}){
	return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">
			<g>
				<g>
					<g>
						<g>
							<path style={{fill : color}} d="M25.52,89.99H25.5c-4.62,0.01-8.63-3.2-9.65-7.7v-0.02c-0.67-2.95-2.65-5.43-5.38-6.74l0,0
								c-4.18-2-6.41-6.63-5.37-11.14l0,0c0.68-2.94-0.03-6.03-1.91-8.39l-0.02-0.02c-2.88-3.61-2.88-8.74,0-12.34l0.02-0.02
								c1.88-2.36,2.59-5.45,1.91-8.39v-0.01c-1.04-4.51,1.19-9.14,5.37-11.14l0,0c2.73-1.3,4.71-3.79,5.38-6.74v-0.02
								c1.02-4.51,5.03-7.71,9.65-7.7h0.02c3.02,0,5.87-1.37,7.75-3.74V5.87c2.88-3.62,7.89-4.77,12.05-2.75l0,0
								c2.72,1.32,5.9,1.32,8.63,0l0,0C58.12,1.1,63.12,2.24,66,5.87v0.01c1.88,2.36,4.73,3.74,7.75,3.74h0.02
								c4.62-0.01,8.63,3.2,9.65,7.7v0.02c0.67,2.95,2.65,5.43,5.38,6.74l0,0c4.18,2,6.41,6.63,5.37,11.14l0,0
								c-0.68,2.94,0.03,6.03,1.91,8.39l0.02,0.02c2.89,3.61,2.89,8.74,0,12.34l-0.02,0.02c-1.89,2.36-2.59,5.45-1.91,8.39v0.01
								c1.04,4.51-1.19,9.14-5.37,11.14l0,0c-2.73,1.3-4.71,3.79-5.38,6.74v0.02c-1.02,4.51-5.03,7.71-9.65,7.7h-0.02
								c-3.02,0-5.87,1.37-7.75,3.74v0.01c-2.88,3.62-7.89,4.77-12.05,2.75l0,0c-2.72-1.32-5.9-1.32-8.63,0l0,0
								c-4.17,2.02-9.17,0.88-12.05-2.75v-0.01C31.39,91.36,28.54,89.98,25.52,89.99z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<ellipse style={{fill : "#FFFFFF"}} cx="49.64" cy="49.8" rx="43.53" ry="43.53"/>
					</g>
					<g>
						<path style={{fill : color}} d="M49.64,94.38c-24.58,0-44.57-20-44.57-44.57s20-44.57,44.57-44.57s44.57,20,44.57,44.57
							S74.22,94.38,49.64,94.38z M49.64,7.31C26.21,7.31,7.15,26.37,7.15,49.8s19.06,42.49,42.49,42.49S92.13,73.23,92.13,49.8
							S73.07,7.31,49.64,7.31z"/>
					</g>
				</g>
				<g>
					<g>
						<path style={{fill : color}} d="M49.64,89.01c-21.62,0-39.21-17.59-39.21-39.21s17.59-39.2,39.21-39.2s39.2,17.59,39.2,39.2
							C88.85,71.42,71.26,89.01,49.64,89.01z M49.64,11.85c-20.93,0-37.96,17.03-37.96,37.96s17.03,37.96,37.96,37.96
							S87.6,70.74,87.6,49.81C87.6,28.87,70.57,11.85,49.64,11.85z"/>
					</g>
				</g>
			</g>
	</svg>
	)
}
