import axios from 'axios'
import { API_URL, getData, postFormData, postData } from './index.js'
import {
  GET_TEAMS,
  GET_TEAM,
  CREATE_TEAM,
  SEND_BADGE_TEAM,
  LOADING_TEAMS,
  ERROR_TEAMS
} from './types'

export async function getTeams(dispatch){
  let url = '/team'
  let teams;

  dispatch({
    type : LOADING_TEAMS
  })

  await getData( ERROR_TEAMS, url, dispatch, true).then((response)=>{
    teams = response.data.teams
  })

  dispatch({
    type : GET_TEAMS,
    payload : teams
  })

}

export async function likeTeam( team, likes){
  let url = '/team/'+team._id
  let resTeam;

  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;
    let token = localStorage.getItem('token');

    let config = {
      timeout: 1000 * 60,
      headers : {
         Authorization: `JWT ${token}`
       }
    };

    axios.put(requestUrl, { ...team, likes}, config)
    .then((response) => {
      resTeam = response.data.team
      delete resTeam.updatedAt
      delete resTeam.createdAt
      delete resTeam.__v
      resolve(resTeam)
    })
    .catch((error) => {
      reject()
    });

  })


}

export async function getTeam(dispatch,id){
  let url = '/team/'+id
  let team;

  dispatch({
    type : LOADING_TEAMS
  })

  await getData( ERROR_TEAMS, url, dispatch, true).then((response)=>{
    team = response.data.team
  })

  dispatch({
    type : GET_TEAM,
    payload : team
  })

}

export async function sendBadge(dispatch,data){
  let url = '/file'
  let file;

  return new Promise(async (resolve,reject)=>{
    await postFormData( ERROR_TEAMS, url, dispatch, data, true).then((response)=>{
      let file = response.files[0];
      resolve(file)
    }).catch(()=>{
      reject()
    })

    dispatch({
      type : SEND_BADGE_TEAM,
      payload : file
    })

  })

}

export async function createTeam(dispatch,data,badge){

  let url = '/team'
  let team;

  return new Promise(async (resolve,reject)=>{

    dispatch({
      type : LOADING_TEAMS
    })

    await postData( ERROR_TEAMS, url, dispatch, data, true).then((response)=>{
      team = response.team
      resolve(team)
    }).catch(()=>{
      reject()
    })

    dispatch({
      type : CREATE_TEAM,
      payload : {
        ...team, picture : {
        _id : badge._id,
        alt : badge.alt,
        path : badge.path,
      }
    }})

  })

}
