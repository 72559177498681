import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import thumb from './thumbs-up-solid.svg'
import styles from './button-like.module.scss'

import { likeTeam } from '../../actions/teams'

export default function ButtonLike({project}){
  const userReducer = useSelector(reducers => reducers.userReducer )
  const [count , setCount ] = useState(0)
  const [team , setTeam ] = useState()

  useEffect(()=>{
    if(!project) return;
    setTeam(project)
  },[project])

  useEffect(()=>{
    if(team?.likes) setCount(team.likes.length)
  },[team?.likes])

  async function likes(){
    if(!team) return;
    let arrLikes = [ ...team.likes ]
    if(arrLikes.includes(userReducer.user.email)){
      arrLikes = arrLikes.filter(d => d !== userReducer.user.email)
    }else{
      arrLikes.push(userReducer.user.email)
    }

    let res = await likeTeam(team,arrLikes)
    setTeam(res)
  }

  return (
    <div className={styles["button-like"]} onClick={() => likes()}>
      <img src={thumb} alt="like" />
      <label>J'aime ce projet ({count})</label>
    </div>
  )
}
