//= =====================
// USER Actions
//= =====================

export const SIGNIN_USER = 'SIGNIN_USER',
             GET_USER = 'GET_USER',
             PITCH_LOADING = 'PITCH_LOADING',
             USER_VOTE = 'USER_VOTE',
             UPDATE_USER_TEAM = 'UPDATE_USER_TEAM',
             USER_JOIN_TEAM = 'USER_JOIN_TEAM',
             USER_LEAVE_TEAM = 'USER_LEAVE_TEAM',
             SET_USER_TEAM = 'SET_USER_TEAM',
             LOADING_USER = 'LOADING_USER',
             ERROR_USER = 'ERROR_USER';

//= =====================
// TEAMS Actions
//= =====================

export const GET_TEAMS = 'GET_TEAMS',
             GET_TEAM = 'GET_TEAM',
             SEND_BADGE_TEAM = 'SEND_BADGE_TEAM',
             CREATE_TEAM = 'CREATE_TEAM',
             LOADING_TEAMS  = 'LOADING_TEAMS ',
             ERROR_TEAMS  = 'ERROR_TEAMS ';
