export default function Front0 ({color}) {
	return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">

		<path style={{display:"none"}} d="M73.17,59.42l-5.74-2.39l0.41-0.71l-1.58-0.91c0.74-1.89,1.15-3.95,1.15-6.11c0-2.16-0.41-4.22-1.15-6.11
			l1.58-0.91l-0.41-0.71l5.74-2.39c1.39-0.58,1.95-2.24,1.2-3.55l0,0c-0.75-1.3-2.48-1.65-3.67-0.73l-4.94,3.77l-0.41-0.71l-1.59,0.92
			c-2.57-3.22-6.31-5.47-10.58-6.11v-1.83h-0.82l0.8-6.16c0.19-1.49-0.97-2.81-2.47-2.81l0,0c-1.5,0-2.66,1.32-2.47,2.81l0.8,6.16
			h-0.82v1.83c-4.27,0.64-8.01,2.89-10.58,6.11l-1.59-0.92l-0.41,0.71l-4.94-3.77c-1.19-0.91-2.92-0.57-3.67,0.73h0
			c-0.75,1.3-0.19,2.97,1.2,3.55l5.74,2.39l-0.41,0.71l1.58,0.91c-0.74,1.89-1.15,3.95-1.15,6.11c0,2.16,0.41,4.22,1.15,6.11
			l-1.58,0.91l0.41,0.71l-5.74,2.39c-1.39,0.58-1.95,2.24-1.2,3.55h0c0.75,1.3,2.48,1.65,3.67,0.73l4.94-3.77l0.41,0.71l1.59-0.92
			c2.57,3.22,6.31,5.47,10.58,6.11v1.83h0.82l-0.8,6.16c-0.19,1.49,0.97,2.81,2.47,2.81l0,0c1.5,0,2.66-1.32,2.47-2.81l-0.8-6.16h0.82
			v-1.83c4.27-0.64,8.01-2.89,10.58-6.11l1.59,0.92l0.41-0.71l4.94,3.77c1.19,0.91,2.92,0.57,3.67-0.73l0,0
			C75.12,61.66,74.55,60,73.17,59.42z M38.44,49.29c0-1.83,0.4-3.57,1.13-5.14l8.05,4.65c-0.03,0.16-0.04,0.32-0.04,0.49
			c0,0.17,0.01,0.33,0.04,0.49l-8.05,4.65C38.85,52.86,38.44,51.12,38.44,49.29z M50.68,50.27c-0.54,0-0.98-0.44-0.98-0.98
			c0-0.54,0.44-0.98,0.98-0.98c0.54,0,0.98,0.44,0.98,0.98C51.66,49.83,51.22,50.27,50.68,50.27z M53.75,48.8l8.05-4.65
			c0.72,1.56,1.13,3.3,1.13,5.14c0,1.83-0.4,3.57-1.13,5.14l-8.05-4.65c0.03-0.16,0.04-0.32,0.04-0.49
			C53.79,49.12,53.78,48.96,53.75,48.8z M60.69,42.23l-8.04,4.64c-0.25-0.21-0.54-0.37-0.85-0.49V37.1
			C55.46,37.43,58.67,39.38,60.69,42.23z M49.57,37.1v9.29c-0.31,0.12-0.6,0.28-0.85,0.49l-8.04-4.64
			C42.7,39.38,45.9,37.43,49.57,37.1z M40.68,56.34l8.04-4.64c0.25,0.21,0.54,0.37,0.85,0.49v9.29C45.9,61.15,42.7,59.2,40.68,56.34z
			 M51.79,61.48v-9.29c0.31-0.12,0.6-0.28,0.85-0.49l8.04,4.64C58.67,59.2,55.46,61.15,51.79,61.48z"/>
		<g id="XMLID_13_" style={{display:"none"}}>
			<polygon id="XMLID_1127_" style={{display:"inline"}} points="70.25,29.19 72.17,31.11 73.96,25.01 67.86,26.81 69.78,28.72 50.68,47.82
				31.59,28.72 33.5,26.81 27.41,25.01 29.2,31.11 31.12,29.19 50.21,48.29 34.46,64.04 31.08,63.46 27.41,67.13 31.18,67.79
				31.84,71.57 35.52,67.89 34.93,64.51 50.68,48.76 66.44,64.51 65.85,67.89 69.53,71.57 70.18,67.79 73.96,67.13 70.28,63.46
				66.91,64.04 51.15,48.29 	"/>
		</g>
		<g style={{display:"none"}}>
			<g style={{display:"inline"}}>
				<g>
					<path style={{fill : '#FFFFFF'}} d="M66.16,41.91V33.3c1.38-1.72,1.87-4.43,0.38-6.61c-1.11-1.62-3.02-2.47-4.93-2.34
						c-0.32-1.52-1.38-2.83-2.92-3.4c-1.36-0.51-2.83-0.33-4.01,0.38c-1.78-3.22-5.56-5-9.25-4.08c-2.15,0.53-3.96,1.89-5.11,3.83
						c-0.43,0.73-0.75,1.51-0.95,2.32c-0.26-0.18-0.54-0.34-0.82-0.49c-1.59-0.79-3.39-0.93-5.06-0.37c-3.72,1.24-5.18,5.33-4.2,8.38
						c0.54,1.69,1.71,2.78,2.69,3.43v37.2l0.58,0.38c6.39,4.2,13.09,4.83,16.51,4.83c7.97,0,13.77-3.03,16.51-4.83l0.58-0.38V58.22
						c4.09-0.46,7.29-3.94,7.29-8.16S70.25,42.37,66.16,41.91z M66.16,55.63V44.51c2.67,0.44,4.72,2.76,4.72,5.56
						C70.88,52.86,68.83,55.18,66.16,55.63z"/>
				</g>
			</g>
			<g style={{display:"inline"}}>
				<g>
					<g>
						<path d="M42.12,46.73h-2.05v-3.59h-3.29v26.1c4.85,2.72,9.7,3.15,12.29,3.15c5.48,0,9.71-1.71,12.29-3.15v-26.1H42.12V46.73z
							 M42.12,59.05h-2.05v-9.92h2.05V59.05z"/>
					</g>
				</g>
				<g>
					<g>
						<path d="M73.45,50.06c0-4.21-3.19-7.69-7.29-8.16V33.3c1.38-1.72,1.87-4.43,0.38-6.61c-1.11-1.62-3.02-2.47-4.93-2.34
							c-0.32-1.52-1.38-2.83-2.92-3.4c-1.36-0.51-2.83-0.33-4.01,0.38c-1.78-3.22-5.56-5-9.25-4.08c-2.15,0.53-3.96,1.89-5.11,3.83
							c-0.43,0.73-0.75,1.51-0.95,2.32c-0.26-0.18-0.54-0.34-0.82-0.49c-1.59-0.79-3.39-0.93-5.06-0.37c-3.72,1.24-5.18,5.33-4.2,8.38
							c0.54,1.69,1.71,2.78,2.69,3.43v37.2l0.58,0.38c6.39,4.2,13.09,4.83,16.51,4.83c7.97,0,13.77-3.03,16.51-4.83l0.58-0.38V58.22
							C70.25,57.76,73.45,54.28,73.45,50.06z M34.3,24.97c1.02-0.34,2.12-0.26,3.1,0.23c0.98,0.49,3.39,1.94,3.39,1.94
							s0.94-3.4,1.74-4.76c1.01-1.71,2.76-2.76,4.78-2.81c1.07-0.02,2.13,0.29,3.05,0.84c1.29,0.78,1.74,1.62,2.33,2.7
							c0.57,1.04,0.87,1.99,1.04,2.67c0.74-2.04,2.71-2.95,4.07-2.43c0.41,0.16,0.73,0.43,0.97,0.74c0.53,0.68,0.71,1.57,0.57,2.42
							c-0.06,0.34-0.11,0.68-0.17,1.01l1.69-0.4c1.3-0.54,2.8-0.11,3.57,1.01c0.93,1.36,0.28,3.12-0.55,3.87
							c-0.49,0.45-1.18,0.57-1.76,0.61H34.14c-0.58-0.26-1.94-1.01-2.41-2.49C31.14,28.29,32.05,25.72,34.3,24.97z M63.59,70.14
							c-2.69,1.66-7.74,4.04-14.52,4.04c-3.03,0-8.87-0.54-14.52-4.04V35.19h26.61l0.18,0c0.11,0,0.25,0.01,0.4,0.01
							c0.13,0,0.29,0,0.45-0.01h1.42V70.14z M66.16,55.63V44.51c2.67,0.44,4.72,2.76,4.72,5.56C70.88,52.86,68.83,55.18,66.16,55.63z"
							/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<path style={{fillRule:"evenodd",clipRule:"evenodd", fill : color }} d="M52.81,48.73c5,1.07,8.81,2.85,11.09,5.38c2.24,2.48,2.81,5.63,1.91,9.19c0.43-3.32-0.38-6.35-2.6-8.83
				C60.95,51.96,57.38,50.06,52.81,48.73L52.81,48.73z M49.9,46.2c-2-0.91,3.25-1.79-2.16-1.29c2.91-1.53,6.28-1.95,9.16-1.74
				c2.9,0.21,5.32,0.98,6.24,1.69c-1.02,1.57-7.19,2.59-9.15,2.59C52.87,47.43,50.72,46.48,49.9,46.2L49.9,46.2z M36.25,34.44
				c-0.31-0.31-0.15-0.72,0.36-0.91c0.5-0.19,1.17-0.09,1.48,0.22c0.31,0.31,0.15,0.72-0.36,0.91C37.22,34.85,36.56,34.75,36.25,34.44
				L36.25,34.44z M35.65,33.83c-0.04,0.26,0.1,0.52,0.38,0.73c-0.19-0.09-0.14,0.01-0.5-0.35c-0.36-0.36-0.28-1.35,0.68-1.71
				c0.95-0.36,2.19-0.18,2.77,0.4c0.58,0.58-0.16,1.53-0.66,1.72c-0.5,0.19-0.1,0.03-0.15,0.05c0.03-0.01,0.05-0.01,0.07-0.02
				c1.38-0.52,0.79-1.4-0.21-1.7C37.18,32.69,35.8,32.96,35.65,33.83z M30.24,40.01c-2.68,1.37,0.53,3.06,9.19,3.53
				C29.79,43.79,25.74,40.83,30.24,40.01z M33.21,34.94c1.45,0.42,2.66,1.11,2.85,2.31C35.65,36.21,34.62,35.48,33.21,34.94z
				 M49.47,43.14c-2.08,1.25-4.98,2.32-7.03,2.09c2.46,0.11,4.91-1.28,5.4-1.82C48.76,43.28,48.76,43.28,49.47,43.14z M63.66,46.54
				c-0.03,0-0.05,0.01-0.08,0c-0.14-0.46,0.46-0.27,0.5-0.2C64.7,47.44,63.69,47.13,63.66,46.54L63.66,46.54z M62.95,45.74
				c0,0,0.01-0.3,0.04-0.3c0.51-0.06,0.48,0.52,0.44,0.52c-0.2,0.02-0.07,0.02-0.18,0.04C63.01,46.05,62.99,45.84,62.95,45.74z
				 M65.32,50.03c0-0.02,0.01-0.14,0.04-0.14C65.65,49.87,65.3,50.44,65.32,50.03z M68.03,52.99c0.03,0.04,0.04,0.89-0.02,0.99
				C67.35,54.98,67.66,52.47,68.03,52.99L68.03,52.99z M66.78,52.33c-0.1-0.04-0.03-0.13-0.02-0.18c0.21-0.84,0.45,0.11,0.45,0.12
				C67.25,52.74,66.79,52.34,66.78,52.33L66.78,52.33z M65.91,50.65c0.01-0.05,0.09-0.1,0.12-0.06
				C66.41,51.04,65.82,51.02,65.91,50.65z M65.99,49.2c0.26,0,0.55,0.16,0.58,0.34C66.91,51.33,65.3,49.19,65.99,49.2L65.99,49.2z
				 M64.6,48.92c0.04,0,0.06-0.01,0.11-0.02C64.95,49.05,64.44,49.51,64.6,48.92L64.6,48.92z M63.43,48.15
				c0.02-0.02,0.04-0.04,0.06-0.07C63.94,47.86,63.8,48.98,63.43,48.15L63.43,48.15z M64.49,47.78c0.19-0.33,0.6-0.11,0.63,0.04
				C65.38,49.06,64.3,48.11,64.49,47.78L64.49,47.78z M62.01,46.46C62.57,45.96,62.83,47.26,62.01,46.46L62.01,46.46z M63.07,46.97
				c0.02-0.04,0.13-0.06,0.14-0.01C63.39,47.57,62.9,47.28,63.07,46.97L63.07,46.97z M58.54,42.84c0.02-0.04,0.03-0.03,0.07-0.02
				C59,42.91,58.4,43.14,58.54,42.84z M59.03,42.36c0-0.12,0.29-0.11,0.34-0.06C60.14,43.06,59.03,42.81,59.03,42.36z M57.36,41.95
				c0.01-0.02,0.34-0.21,0.64-0.01C59,42.62,57.02,42.46,57.36,41.95L57.36,41.95z M51.7,42.16c0.02-0.04,0.19-0.06,0.23-0.02
				C52.32,42.58,51.59,42.4,51.7,42.16z M53.08,41.93c0.06-0.23-0.08-0.28,0.42-0.09C54.33,42.16,52.95,42.37,53.08,41.93L53.08,41.93
				z M54.47,41.95c-0.02-0.03,0.01-0.07,0.04-0.08C55.15,41.74,54.8,42.44,54.47,41.95z M56.08,41.94c0.02-0.01,0.03-0.02,0.05-0.04
				C56.61,41.82,56.3,42.4,56.08,41.94z M56.3,40.86c0.05-0.21,0.35-0.2,0.55-0.12c0.32,0.11,0.19,0.32,0.18,0.32
				c0,0.26-0.37,0.16-0.45,0.18C56.46,41.16,56.27,41.02,56.3,40.86z M35.25,38.84c-1.01-2.79-7.87-6.17-6.39-2.77
				c0.53,1.22,1.67,1.75,2.27,3.46c-0.83,0.27-2.78-0.16-4.04-0.52c-0.52-0.2-1.65,0-1.72,0.58c-0.28,2.35,1.46,3.77,3.64,4.65
				c2.24,0.9,5.58,1.38,8.04,1.49c3.37,0.82,6.25,0.78,9.4-0.44c-2.11,0.63-5.49,1.11-7.76,0.43c1.54-0.07,2.88-0.32,3.78-0.79
				c-1.49,0.41-3.53,0.53-5.99,0.27c-2.44-0.25-5.29-0.88-8.39-1.97c5.55,1.33,9.54,1.94,11.89,1.82c0.91-0.05,1.57-0.19,1.98-0.45
				c-2.27,0.85-19.56-1.57-13.3-4.66c-0.65-0.15-1.34-0.36-1.47-0.63c0.88,0.48,2.37,0.65,4.04,0.51c0,0,0,0.01,0,0.01
				c0.18-0.02,0.37-0.04,0.57-0.07l0,0c1.22-0.18,2.18-0.39,2.91-0.63c0.18,1.19,1.15,1.87,2.13,1.6c1-0.28,0.95-1.27,0.11-2.08
				c0.83,1.18,0.38,1.75-0.34,1.81c-0.91,0.07-1.25-0.53-1.35-1.54L35.25,38.84z M51.45,46.13c1.96,1.34,4.56,0.65,6.78,0.3
				C55.24,46.86,52.86,46.85,51.45,46.13z M51.63,45.51c2.05,1.2,4.59,0.33,6.79-0.17C55.46,45.98,53.09,46.13,51.63,45.51z
				 M42.6,47.9c-2.82,1.39-2.56,3.66-1.78,5.49C40.07,51.08,40.61,49.13,42.6,47.9z M43.16,48.16c-2.52,1.25-2.29,3.27-1.59,4.91
				C40.9,51.01,41.38,49.27,43.16,48.16z M39.09,71.96c4.52,4.33,8.06-1.16,11.65-1.63C46.86,70.87,43.84,75.58,39.09,71.96z
				 M45.04,54.53c1.75-0.25,3.5-0.24,4.97,0.65c1.15,0.7,1.68,2.37,1.89,4.59C51.33,56.04,49.24,54.06,45.04,54.53z M46.25,57.56
				c2.36,1.02,3.98,2.67,4.71,5.08C49.97,60.38,48.36,58.72,46.25,57.56z M49.67,67.97c-1.97,1.66-4.17,2.37-6.65,1.94
				C45.49,70.03,47.69,69.33,49.67,67.97z M50.79,65.69c-1.92,1.16-3.91,1.47-5.98,0.77C46.91,66.9,48.89,66.6,50.79,65.69z
				 M45.81,61.15c2.22,0.3,3.97,1.3,5.15,3.15C49.59,62.64,47.85,61.63,45.81,61.15z M42.75,64.18c-2.35,0.16-3.64,1.52-3.89,3.23
				C39.34,65.82,40.49,64.65,42.75,64.18z M53.9,60.93c-0.35-0.5-0.43-0.95,0.47-1.17c2.05-0.65,4.15-1.06,6.26-1.37
				C57.03,59.23,53.83,60.07,53.9,60.93z M73.99,50.89c2.09-2.11,6.08-1.64,7.32,0.84c2.15,4.29-5.76,0.46-7.24,2.9
				c-0.13,1.39-0.4,2.84-0.79,4.35c0.85-8.04-1.38-13.99-5.6-18.19c-4.13-4.12-9.85-6.44-17.18-7.27c8.32,1.32,16.6,6.28,20.29,14.05
				c3.82,8.03,1.94,19.31-7.77,21.95c-2.78,0.76-5.7,0.75-8.76,0.22c-1.69-0.29-2.48-0.87-2.69-1.55c-0.11,0.53-0.17,1.01-0.2,1.41
				c2.62,0.87,5.78,1.21,9.05,0.85c3.32-0.37,6.71-1.6,9.62-5.17c-2.77,3.83-6.23,5.22-9.59,5.73c-3.34,0.51-6.56,0.31-9.1-0.42
				c0,0.03-0.01,0.04-0.01,0.05c-2.08-0.5-6.49,5.19-10.8,3.16c-4.69-2.2-2.84-9.43,1.76-9.91c6.04,0.33-4.66-12.37,7.02-9.42
				c3.39,0.85,2.68,8.67,4.6,9.6c-0.7,0.61-1.21,1.31-1.59,2c0.6-0.66,1.45-1.23,2.28-1.54c4.58,1.83,8.15-0.77,8.71-4.63
				c-1.28,3.92-6.84,4.94-9.22,2.14c-4-4.7,8.16-1.82,7.66-6.25c-0.07-0.63-0.34-1.17-0.92-1.73c-2.9-2.46-8.85-4.14-15.28-4.92
				c0.17,0.11,0.34,0.24,0.53,0.37c0.39,2.98-2.56,4.71-4.86,5.64c-2.34-3.94-1.44-6.11,0.99-7.67c1.48-0.17,4.67-0.88,5.32-2.04
				c-0.36,1.17-0.76,1.71-1.95,2.06c0.33-0.06,0.65-0.12,0.99-0.18c0.75,0.47,1.92,0.82,3.51,1.05c-1.13-0.01-2.76-0.26-4.87-0.75
				c1.55,0.83,4.33,1.3,7.24,2.03c4.76,1.14,7.42,2.41,8.95,3.7c2.21,1.44,4.08,5.03,4.05,8.4c-0.02,3.35-1.79,6.38-6.51,6.98
				c9.97-0.03,11.58-10.48,8.63-17.62c0.11,1.66-1.46-0.59-0.22-0.48c-0.34-0.74-0.73-1.46-1.18-2.16c0.01,0.33-0.26,0.37-0.5,0.04
				c-0.2-0.28-0.16-0.51,0.07-0.69c-0.2-0.28-0.41-0.57-0.61-0.84c-0.42,0.6-0.66-0.19-0.35-0.44c-0.66-0.82-1.38-1.6-2.19-2.34
				c-0.18,0.06-0.62-0.24-0.42-0.37c-0.25-0.22-0.49-0.42-0.75-0.63c-0.03,0.03-0.06,0.06-0.12,0.08c-0.27,0.08,0.04,0.25-0.22,0.21
				c-0.33-0.05-0.84-0.67-0.54-0.96c-0.52-0.37-1.06-0.73-1.63-1.06c-0.12,0.14-0.55-0.1-0.57-0.1c-0.41-0.07-0.54-0.28-0.45-0.47
				c-0.43-0.23-0.87-0.45-1.33-0.65c-0.04,0.03-0.1,0.06-0.22,0.07c-0.53,0.05-0.83-0.24-0.86-0.53c-0.29-0.11-0.59-0.22-0.89-0.33
				c-0.15,0.33-0.9,0.15-1.04,0.06c-0.03-0.02-0.07-0.04-0.11-0.07c-0.11,0.33-0.26,0.63-0.47,0.92c0.05,0.02,0.11,0.04,0.17,0.07
				c0.63,0.36,0.36,1.01-0.13,0.57c-0.13-0.12-0.23-0.25-0.27-0.37c-1.36,1.51-4.3,2.37-8.76,2.64c13.36-1.11,8.35-7.7,1.53-11.22
				c13.15,7.68-1.19,10.7-4.84,9.53c7.82,0.23,9.59-2.58,8.47-5.04c-1.12-2.46-5.12-4.56-7.17-4.42c3.33,2.39,3.34,5.84,1.06,8.16
				c2.76-3.48,0.03-7.36-3.85-7.96c0.43,0.47,0.62,0.95,0.59,1.46c-1.03-3.2-7-1.04-4.41,1.09c-1.34-1-8.23-2.92-7.81-0.07
				c0,0.01,0,0.03,0,0.04c0.1,0.68,0.63,1.58,1.58,2.69c-1.36-1.48-2.12-2.67-2.27-3.59c-0.16-0.47-0.06-0.91,0.23-1.18
				c1.14-1.04,11.7-1.94,13.54-2.05c2.83-0.16,5.3-0.1,7.45,0.17C59.26,32.52,72.22,38.5,73.99,50.89z M81.27,52.67
				c0.18,0.48,0.16,0.85-0.61,0.86c-1.79,0.12-3.57,0.03-5.35-0.13C78.39,53.43,81.16,53.38,81.27,52.67z M73.91,56.1L73.91,56.1
				L73.91,56.1z M54.27,40.64c-0.01-0.05-0.01-0.35,0.47-0.26c0.82,0.15,1.04,1,0.36,0.83C54.62,41.08,54.36,41.07,54.27,40.64
				L54.27,40.64z"/>
		</g>
		</svg>

	)
}
