import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { vote ,setUserTeam } from '../../../actions/user'
import { API_URL, getData } from '../../../actions'
import { ERROR_TEAMS } from '../../../actions/types'
import { getLeader } from '../../../helpers'
import iconInfoBox from '../../../assets/images/icon-infobox.svg'
import iconLeader from '../../../assets/images/icon-leader.svg'
import iconVote from '../../../assets/images/icon-vote.svg'

import styles from './view-team.module.scss'

import ContainerTeams from '../ContainerTeams'
import Loader from '../../../components/Loader'
import Badge from '../../../components/Badge'
import ModalLeader from '../../../components/ModalLeader'

export default function ViewTeam({ws}) {
  const params = useParams();
  const dispatch = useDispatch()
  const [ team, setTeam ] = useState();
  const [ leader, setLeader ] = useState();
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ wsUpdatedTeamData, setWsUpdatedTeamData] = useState()

  const teamsReducer = useSelector(state => state.teamsReducer)
  const userReducer = useSelector(state => state.userReducer)

  useEffect(()=>{
    if(!userReducer.team) return;

    setTeam(userReducer.team)
    let leader = getLeader(userReducer.team?.votes)
    setLeader(leader)

    return () => {
      setLeader(null)
      setTeam(null)
    }
  },[userReducer.team])

  useEffect(()=>{
    if(!ws) return;

    ws.addEventListener("message", listen);

    function listen(message){
      let data = JSON.parse(message.data)

      if(
        data.teamId === userReducer.user.team &&
        data.userId !== userReducer.user._id
      ){
        setWsUpdatedTeamData(data)
      }
    }

    return function cleanupListener() {
      ws.removeEventListener('message', listen)
    }


  },[ws])

  //UDPATE TEAM WITH WS DATA
  useEffect(()=>{
    if(!team || !wsUpdatedTeamData) return;

    // data.type
    // "team updated"
    // "leave team"
    // "join team"
    // "vote change"

    if(wsUpdatedTeamData.type === "team updated"){
      let updatedTeam = team
      updatedTeam = { ...updatedTeam, ...wsUpdatedTeamData.action}
      setTeam(updatedTeam)
    }else if(wsUpdatedTeamData.type === "join team"){
      let updatedTeam = team
      let updatedUsers = [...team.users, { email : wsUpdatedTeamData.action , _id : wsUpdatedTeamData.userId }]
      updatedTeam = { ...updatedTeam, users : updatedUsers}
      setTeam(updatedTeam)
    }else if(wsUpdatedTeamData.type === "leave team"){
      let updatedTeam = team
      let updatedUsers = team.users.filter(user => user._id !== wsUpdatedTeamData.userId )
      updatedTeam = { ...updatedTeam, users : updatedUsers}
      setTeam(updatedTeam)
    }else if(wsUpdatedTeamData.type === "vote change"){

      let url = '/team/'+wsUpdatedTeamData.teamId
      let team;
      getData( ERROR_TEAMS, url, dispatch, true).then((response)=>{
        team = response.data.team
        setUserTeam(dispatch,team,wsUpdatedTeamData.type)

        team = response.data.team
        let leader = getLeader(team.votes)
        setLeader(leader)
        setTeam(team)
      })

    }

  },[wsUpdatedTeamData])


  function userName(email){
    return email.replace(/@(.*)$/,"")
  }

  function voteForUser(id){
    if(!userReducer.user.team) return;
    vote(dispatch,id)
  }

  return (
    <>
      <ContainerTeams>
        { team &&
          <div className={styles["view-team"]}>
            <div className={`${styles["center"]} center`}>
              <h1>{team.name}</h1>
              <p>{team.baseline}</p>
              <p className={`${styles["info"]}`}>Vous pouvez changer de leader à tout moment.<br/>Pour voter pour un nouveau leader, cliquez sur son nom.</p>
              <p className={styles["leader-vote"]}>
                Votez pour votre leader !
                <img src={iconInfoBox} alt="information leader" onClick={()=>setIsModalOpen(true)}/>
              </p>
            </div>
            <div className={styles["list-users"]}>
              { team.users &&
                <ul>
                  {team.users.map( user => {
                    return (
                      <li key={`user-${user._id}`} onClick={() => voteForUser(user._id)}>
                        { userReducer.user.leaderVote === user._id && <label className={styles["user-vote"]}>Vote<img src={iconVote} alt="vote"/> </label>}
                        {userName(user.email)}
                        { leader === user.email && <label className={styles["leader"]}><img src={iconLeader} alt="leader"/>Élu</label>}
                      </li>
                    )
                  })}
                </ul>
              }
            </div>
            <div className={styles["container-badge"]}>
              <Badge team={team} noProblematic large teamBadge/>
            </div>
          </div>
        }
        { (!team && userReducer.isLoading) &&
          <div className={styles["view-team"]}>
            <Loader/>
          </div>
        }
      </ContainerTeams>
      { isModalOpen &&
        <ModalLeader toggleClose={() => setIsModalOpen(false)} />
      }
    </>

  )
}
