import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Link , useHistory } from 'react-router-dom'
import { API_URL } from '../../actions'
import { getLeader } from '../../helpers'
import styles from './badge.module.scss'

import Modal from '../Modal'

import {
  actions,
  situations,
  purposes
} from '../../config/problematic'

export default function Badge({
  teamBadge = false,
  noProblematic,
  linkProject = false,
  team = null,
  large = false,
  noLink = false, ws
}){
  const userReducer = useSelector(state => state.userReducer)
  const teamsReducer = useSelector(state => state.teamsReducer)
  const history = useHistory()
  const modalProblematic = useRef()
  const modalKeywords = useRef()

  const [ badgeTeam, setBadgeTeam ] = useState()
  const [ isLeader, setIsLeader ] = useState()
  const [ problematic , setProblematic ] = useState({ action : null, creation : null, world : null})
  const [ withProblematic, setWithProblematic] = useState(false)

  useEffect(()=>{

    let leader = getLeader(userReducer.team?.votes)
    setIsLeader(leader === userReducer.user.email)

  },[userReducer.team?.votes])

  useEffect(()=>{
    if(team){
      setBadgeTeam(team)
    }
  },[team])

  //INIT PROBLEMATIC
  useEffect(()=>{
    if(!userReducer.team || !userReducer.team.problematic || noProblematic) return;
    let obj = {
      action : actions.find(action => action.match === userReducer.team.problematic.action),
      creation : purposes.find(purpose => purpose.match === userReducer.team.problematic.creation),
      world : situations.find(situation => situation.match === userReducer.team.problematic.world),
    }
    if(obj.action && obj.creation && obj.world && userReducer.team.step != "problematic"){
      setWithProblematic(true)
    }
    setProblematic(obj)
  },[userReducer.lastUpdate])

  useEffect(()=>{
    if(!team){
      setBadgeTeam(userReducer.team)
    }
  },[userReducer.team])

  useEffect(()=>{
    if(!ws) return;
    ws.addEventListener("message", listen);
    function listen(message){
      let data = JSON.parse(message.data)

      if((data.type === "team updated" && teamBadge) &&
        userReducer.team._id !== data.teamId ||
        (team && team._id !== data.teamId)
      ){
        return ;
      }

      if(data.type === "team updated" && data.action && (data.action.picture || data.action.name)){
        let teamUpdated = userReducer.team
        if(!teamUpdated){
          teamUpdated = teamsReducer.teams.find(team=> team._id === data.teamId)
        }
        let updatedData = {...teamUpdated}
        if(data.action.picture) updatedData = { ...updatedData, picture : data.action.picture}
        if(data.action.name) updatedData = { ...updatedData, name : data.action.name}
        setBadgeTeam(updatedData)
      }
    }

    return function cleanupListener() {
      ws.removeEventListener('message', listen)
    }

  },[ws,teamsReducer.teams])

  function withLink(children){
    let url;

    if(noLink){
      return (
        <div className={`${styles["badge"]} ${large ? styles["large"] : ''} `}>
          { (withProblematic || linkProject) &&
            <div className={styles["container-problematic"]} >
              {(history.location.pathname === "/game/crazy6" || history.location.pathname === "/game/pitch") &&
                <label style={{cursor : "pointer"}} onClick={(e) => openModalKeywords(e)}>Mots-clés</label>
              }
              {withProblematic && <label style={{cursor : "pointer"}} onClick={(e) => openModalProblematic(e)}>Votre mission</label>}
              {(linkProject && userReducer.team && userReducer.team.step === "result") && <Link to={`project/${userReducer.team._id}`}><label>Notre projet</label></Link> }
            </div>
          }
          {children}
        </div>
      )
    }

    if(isLeader){
      url = `/teams/edit`
    }else{
      url = `/teams/${badgeTeam._id}`
    }

    return (
      <div onClick={(e) => goTo(e,url)} style={{cursor : "pointer"}} className={`${styles["badge"]} ${large ? styles["large"] : ''} `}>
      <div className={styles["container-problematic"]} >
        {(!isLeader && /\/game/.test(history.location.pathname)) &&
          <label style={{cursor : "pointer", marginTop : "20px"}} onClick={(e) => goTo(e,url)}>Changer de leader</label>
        }
        { (withProblematic || linkProject) &&
          <>
            {(history.location.pathname === "/game/crazy6" || history.location.pathname === "/game/pitch") &&
              <label style={{cursor : "pointer"}} onClick={(e) => openModalKeywords(e)}>Mots-clés</label>
            }
            {withProblematic && <label style={{cursor : "pointer"}} onClick={(e) => openModalProblematic(e)}>Votre mission</label>}
            {(linkProject && userReducer.team && userReducer.team.step === "result") && <label style={{cursor : "pointer"}} onClick={(e) => goTo(e,`project/${userReducer.team._id}`)}>Notre projet</label> }
          </>
        }
      </div>

        {children}
      </div>
    )

  }

  function openModalKeywords(e){
    e.stopPropagation();
    e.preventDefault();
    modalKeywords.current.openModal()
  }

  function openModalProblematic(e){
    e.stopPropagation();
    e.preventDefault();
    modalProblematic.current.openModal()
  }

  function goTo(e,to){
    e.stopPropagation();
    e.preventDefault();
    history.push(to)
  }

  return (
    <>
      { badgeTeam &&
        <>
          <Modal ref={modalKeywords}>
            <div className={styles["modal-container-keywords"]}>
              <h2>Mots-clés :</h2>
              {(userReducer.team && (userReducer.team.idea && userReducer.team.idea.length>0)) &&
                <ul >
                  {userReducer.team.idea.map((d,i)=>(
                    <li key={`keywords-${i}`}>- {Object.values(d)[0]}</li>
                  ))}
                </ul>
              }
            </div>
          </Modal>
          <Modal ref={modalProblematic}>
            <div className={styles["modal-container-problematic"]}>
              <h2>La problématique de votre équipe :</h2>
              <p>
                Imaginez, rêvez, inventez un(e) <span>{problematic.creation?.match} </span>
                qui permette de <span>{problematic.action?.match}</span> dans une communauté vivant <span>{problematic.world?.match}</span>
              </p>
            </div>
          </Modal>
          {
            withLink([
              <React.Fragment key={badgeTeam.picture._id}>
                {badgeTeam.picture.path &&
                  <div className={styles["container-image"]}>
                    <img src={`${API_URL}/${badgeTeam.picture.path}`} alt={badgeTeam.baseLine}/>
                  </div>
                }
              </React.Fragment>,
              <div key={`letter ${badgeTeam.picture._id}`} className={styles["first-letter"]}><p>{badgeTeam.name[0]}</p></div>
            ])
          }
        </>


      }
    </>
  )
}
