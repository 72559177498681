import { useState, useEffect } from 'react'
import {useTransition, animated, config} from 'react-spring';
import styles from './slider.module.scss'
import usePrevious from '../../hooks/usePrevious'

export default function Slider({slides,height = 520}){
  const [index, setIndex] = useState(0)
  const prevIndex = usePrevious(index)

  const transitions = useTransition(slides[index], index, {
    from: (d) => {
      let y = prevIndex > index ? "-100%" : "100%"
      return { opacity: 1, transform : 'translate3d(0,'+y+',0)' }
    },
    enter: () => {
      return { opacity: 1, transform : 'translate3d(0,0,0)' }
    },
    leave: () => {
      let y = prevIndex > index ? "100%" : "-100%"
      return { opacity: 1, transform : 'translate3d(0,'+y+',0)' }
    },
    config : config.stiff
  })


  function navigateTo(index){
    setIndex(index)
  }


  return (
    <div className={styles["container-slider"]}>
      <div className={styles["slider"]} style={{height : `${height}px`}}>
        {transitions.map(({ item, props, key }) => (
          <animated.div
            key={key}
            className={styles["slide"]}
            style={{ ...props, backgroundColor: `${item.color}` }}
          >
            {item}
          </animated.div>
        ))}
      </div>
      <div className={styles["slider-navigation"]}>
        <ul>
          {slides.map((d,i)=>{
            return (
              <li key={`dot-${i}`} className={index === i ? styles["active"] : ""} onClick={() => setIndex(i)}></li>
            )
          })}
        </ul>
      </div>

    </div>
  )
}
