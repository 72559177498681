export default function Front6({color}) {
	return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">
			<g>
				<g>
					<g>
						<path style={{fill : "#FFFFFF" }} d="M66.16,41.91V33.3c1.38-1.72,1.87-4.43,0.38-6.61c-1.11-1.62-3.02-2.47-4.93-2.34
							c-0.32-1.52-1.38-2.83-2.92-3.4c-1.36-0.51-2.83-0.33-4.01,0.38c-1.78-3.22-5.56-5-9.25-4.08c-2.15,0.53-3.96,1.89-5.11,3.83
							c-0.43,0.73-0.75,1.51-0.95,2.32c-0.26-0.18-0.54-0.34-0.82-0.49c-1.59-0.79-3.39-0.93-5.06-0.37c-3.72,1.24-5.18,5.33-4.2,8.38
							c0.54,1.69,1.71,2.78,2.69,3.43v37.2l0.58,0.38c6.39,4.2,13.09,4.83,16.51,4.83c7.97,0,13.77-3.03,16.51-4.83l0.58-0.38V58.22
							c4.09-0.46,7.29-3.94,7.29-8.16S70.25,42.37,66.16,41.91z M66.16,55.63V44.51c2.67,0.44,4.72,2.76,4.72,5.56
							C70.88,52.86,68.83,55.18,66.16,55.63z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path style={{fill : color}} d="M42.12,46.73h-2.05v-3.59h-3.29v26.1c4.85,2.72,9.7,3.15,12.29,3.15c5.48,0,9.71-1.71,12.29-3.15v-26.1H42.12V46.73z
								 M42.12,59.05h-2.05v-9.92h2.05V59.05z"/>
						</g>
					</g>
					<g>
						<g>
							<path style={{fill : color}} d="M73.45,50.06c0-4.21-3.19-7.69-7.29-8.16v-8.6c1.38-1.72,1.87-4.43,0.38-6.61c-1.11-1.62-3.02-2.47-4.93-2.34
								c-0.32-1.52-1.38-2.83-2.92-3.4c-1.36-0.51-2.83-0.33-4.01,0.38c-1.78-3.22-5.56-5-9.25-4.08c-2.15,0.53-3.96,1.89-5.11,3.83
								c-0.43,0.73-0.75,1.51-0.95,2.32c-0.26-0.18-0.54-0.34-0.82-0.49c-1.59-0.79-3.39-0.93-5.06-0.37c-3.72,1.24-5.18,5.33-4.2,8.38
								c0.54,1.69,1.71,2.78,2.69,3.43v37.2l0.58,0.38c6.39,4.2,13.09,4.83,16.51,4.83c7.97,0,13.77-3.03,16.51-4.83l0.58-0.38V58.22
								C70.25,57.76,73.45,54.28,73.45,50.06z M34.3,24.97c1.02-0.34,2.12-0.26,3.1,0.23s3.39,1.94,3.39,1.94s0.94-3.4,1.74-4.76
								c1.01-1.71,2.76-2.76,4.78-2.81c1.07-0.02,2.13,0.29,3.05,0.84c1.29,0.78,1.74,1.62,2.33,2.7c0.57,1.04,0.87,1.99,1.04,2.67
								c0.74-2.04,2.71-2.95,4.07-2.43c0.41,0.16,0.73,0.43,0.97,0.74c0.53,0.68,0.71,1.57,0.57,2.42c-0.06,0.34-0.11,0.68-0.17,1.01
								l1.69-0.4c1.3-0.54,2.8-0.11,3.57,1.01c0.93,1.36,0.28,3.12-0.55,3.87c-0.49,0.45-1.18,0.57-1.76,0.61H34.14
								c-0.58-0.26-1.94-1.01-2.41-2.49C31.14,28.29,32.05,25.72,34.3,24.97z M63.59,70.14c-2.69,1.66-7.74,4.04-14.52,4.04
								c-3.03,0-8.87-0.54-14.52-4.04V35.19h26.61h0.18c0.11,0,0.25,0.01,0.4,0.01c0.13,0,0.29,0,0.45-0.01h1.42v34.95H63.59z
								 M66.16,55.63V44.51c2.67,0.44,4.72,2.76,4.72,5.56C70.88,52.86,68.83,55.18,66.16,55.63z"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}
