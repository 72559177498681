export default function Back3 ({color}){
	return (
		<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			 viewBox="0 0 100 100" style={{enableBackground : "new 0 0 100 100"}} xmlSpace="preserve">
		<g id="XMLID_7_">
			<path id="XMLID_x5F_2_x5F_" style={{fill : "none"}} d="M4.7,49.3c0-25,20.3-45.3,45.3-45.3s45.3,20.3,45.3,45.3"/>
			<path id="XMLID_752_" style={{fill : "none"}} d="M49.9,14.1c-19.5,0-35.4,15.9-35.4,35.4s15.9,35.4,35.4,35.4s35.4-15.9,35.4-35.4
				S69.5,14.1,49.9,14.1z M82.6,49.5c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8
				C82.2,48.8,82.6,49.1,82.6,49.5z M81.8,48.7C81.8,48.7,81.8,48.7,81.8,48.7c-0.5,0-0.8-0.3-0.8-0.7c0-0.4,0.3-0.8,0.8-0.8
				s0.8,0.3,0.8,0.7C82.6,48.3,82.2,48.6,81.8,48.7z M82.3,45.4c0.1,0.4-0.2,0.8-0.7,0.9c0,0-0.1,0-0.1,0c-0.4,0-0.7-0.3-0.8-0.7
				c-0.1-0.4,0.2-0.8,0.7-0.9C81.9,44.7,82.3,45,82.3,45.4z M81.9,43c0.1,0.4-0.2,0.8-0.6,0.9c-0.1,0-0.1,0-0.2,0
				c-0.4,0-0.7-0.3-0.8-0.6c-0.1-0.4,0.2-0.8,0.6-0.9C81.5,42.3,81.9,42.6,81.9,43z M81.4,40.7c0.1,0.4-0.1,0.9-0.5,1
				c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7-0.2-0.8-0.6c-0.1-0.4,0.1-0.9,0.5-1C80.8,40,81.3,40.2,81.4,40.7z M80.7,38.4
				c0.2,0.4-0.1,0.9-0.5,1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6-0.2-0.7-0.5c-0.2-0.4,0.1-0.9,0.5-1C80.1,37.7,80.5,38,80.7,38.4z
				 M79.7,36.1c0.2,0.4,0,0.9-0.4,1c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.2-0.7-0.5c-0.2-0.4,0-0.9,0.4-1
				C79.1,35.5,79.6,35.7,79.7,36.1z M78.7,34c0.2,0.4,0.1,0.9-0.3,1.1c-0.1,0.1-0.2,0.1-0.4,0.1c-0.3,0-0.6-0.2-0.7-0.4
				c-0.2-0.4-0.1-0.9,0.3-1.1C78,33.4,78.4,33.6,78.7,34z M77.4,31.9L77.4,31.9c0.2,0.4,0.1,0.9-0.2,1.1c-0.1,0.1-0.3,0.1-0.4,0.1
				c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.4-0.1-0.9,0.2-1.1C76.7,31.4,77.2,31.5,77.4,31.9z M76,29.9c0.3,0.4,0.2,0.8-0.2,1.1
				c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.5-0.1-0.6-0.3c-0.3-0.4-0.2-0.8,0.2-1.1C75.3,29.5,75.8,29.5,76,29.9z M73.4,27.9
				c0.3-0.3,0.8-0.2,1.1,0.1c0.3,0.3,0.2,0.8-0.1,1.1c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.6-0.3C73,28.7,73.1,28.2,73.4,27.9z
				 M71.7,26.2c0.3-0.3,0.8-0.3,1.1,0c0.3,0.3,0.3,0.8,0,1.1c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.2
				C71.4,27.1,71.4,26.6,71.7,26.2z M69.9,24.7c0.3-0.3,0.8-0.4,1.1-0.1s0.4,0.8,0.1,1.1C71,25.9,70.8,26,70.6,26
				c-0.2,0-0.4-0.1-0.5-0.2C69.7,25.5,69.7,25,69.9,24.7z M68.1,23.3c0.2-0.4,0.8-0.4,1.1-0.2c0.3,0.2,0.4,0.8,0.2,1.1
				c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.3,0-0.5-0.1C67.9,24.1,67.8,23.6,68.1,23.3z M28.7,24.7c0.3-0.3,0.8-0.2,1.1,0.1
				s0.2,0.8-0.1,1.1c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.5-0.1-0.6-0.3C28.4,25.5,28.4,25,28.7,24.7z M27,26.3c0.3-0.3,0.8-0.3,1.1,0
				c0.3,0.3,0.3,0.8,0,1.1c-0.1,0.2-0.4,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.6-0.2C26.6,27.1,26.6,26.6,27,26.3z M25.3,28.1
				c0.3-0.3,0.8-0.4,1.1-0.1c0.3,0.3,0.4,0.8,0.1,1.1c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.5-0.2C25,28.9,25,28.4,25.3,28.1z
				 M23.8,30c0.3-0.4,0.8-0.4,1.1-0.2c0.4,0.3,0.4,0.8,0.2,1.1c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.3-0.1-0.5-0.2
				C23.6,30.8,23.5,30.3,23.8,30z M22.4,32c0.2-0.4,0.7-0.5,1.1-0.2c0.4,0.2,0.5,0.7,0.2,1.1c-0.1,0.2-0.4,0.4-0.7,0.4
				c-0.1,0-0.3,0-0.4-0.1C22.3,32.8,22.2,32.3,22.4,32z M21.2,34.1c0.2-0.4,0.7-0.5,1.1-0.3c0.4,0.2,0.5,0.7,0.3,1.1
				c-0.1,0.3-0.4,0.4-0.7,0.4c-0.1,0-0.2,0-0.4-0.1C21.1,34.9,21,34.5,21.2,34.1C21.2,34.1,21.2,34.1,21.2,34.1z M20.1,36.2
				c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.7,0.4,1c-0.1,0.3-0.4,0.5-0.7,0.5c-0.1,0-0.2,0-0.3-0.1C20.1,37.1,19.9,36.6,20.1,36.2z
				 M19.2,38.5c0.1-0.4,0.6-0.6,1-0.5c0.4,0.1,0.6,0.6,0.5,1c-0.1,0.3-0.4,0.5-0.7,0.5c-0.1,0-0.2,0-0.3,0
				C19.3,39.3,19,38.9,19.2,38.5z M18.5,40.8c0.1-0.4,0.5-0.7,1-0.5s0.7,0.5,0.5,1c-0.1,0.3-0.4,0.6-0.8,0.6c-0.1,0-0.1,0-0.2,0
				C18.6,41.7,18.3,41.2,18.5,40.8z M18.8,42.5c0.4,0.1,0.7,0.5,0.6,0.9c-0.1,0.4-0.4,0.6-0.8,0.6c0,0-0.1,0-0.1,0
				c-0.4-0.1-0.7-0.5-0.6-0.9C18,42.7,18.4,42.5,18.8,42.5z M18.4,44.9c0.4,0.1,0.7,0.4,0.7,0.9c0,0.4-0.4,0.7-0.8,0.7
				c0,0-0.1,0-0.1,0c-0.4,0-0.7-0.4-0.7-0.9C17.6,45.1,18,44.8,18.4,44.9z M18.1,47.2c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8
				c0,0,0,0,0,0c-0.4,0-0.8-0.4-0.8-0.8C17.3,47.5,17.7,47.2,18.1,47.2z M18.1,49.6c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8
				c0,0,0,0,0,0c-0.4,0-0.8-0.3-0.8-0.8C17.3,50,17.6,49.6,18.1,49.6z M17.4,52.8c0-0.4,0.3-0.8,0.7-0.9c0.4,0,0.8,0.3,0.9,0.7
				c0,0.4-0.3,0.8-0.7,0.9c0,0,0,0-0.1,0C17.8,53.5,17.5,53.2,17.4,52.8z M17.8,55.2c-0.1-0.4,0.2-0.8,0.6-0.9
				c0.4-0.1,0.8,0.2,0.9,0.6c0.1,0.4-0.2,0.8-0.6,0.9c0,0-0.1,0-0.1,0C18.2,55.8,17.8,55.6,17.8,55.2z M18.3,57.6
				c-0.1-0.4,0.1-0.8,0.6-1c0.4-0.1,0.9,0.2,1,0.6c0.1,0.4-0.1,0.8-0.6,1c-0.1,0-0.1,0-0.2,0C18.7,58.2,18.4,57.9,18.3,57.6z M19,59.9
				c-0.1-0.4,0.1-0.9,0.5-1c0.4-0.1,0.9,0.1,1,0.5c0.1,0.4-0.1,0.9-0.5,1c-0.1,0-0.2,0-0.2,0C19.4,60.4,19.1,60.2,19,59.9z M19.8,62.2
				c-0.2-0.4,0-0.9,0.4-1c0.4-0.2,0.9,0,1,0.4c0.2,0.4,0,0.9-0.4,1c-0.1,0-0.2,0.1-0.3,0.1C20.2,62.6,19.9,62.5,19.8,62.2z M21.5,64.8
				c-0.3,0-0.6-0.2-0.7-0.4c-0.2-0.4,0-0.9,0.3-1.1s0.9,0,1.1,0.3c0.2,0.4,0,0.9-0.4,1.1C21.8,64.8,21.6,64.8,21.5,64.8z M22.7,66.8
				c-0.3,0-0.5-0.1-0.7-0.4c-0.2-0.4-0.1-0.9,0.3-1.1c0.4-0.2,0.9-0.1,1.1,0.3c0.2,0.4,0.1,0.8-0.3,1.1C23,66.8,22.8,66.8,22.7,66.8z
				 M24,68.8c-0.2,0-0.5-0.1-0.6-0.3c-0.3-0.3-0.2-0.8,0.2-1.1s0.8-0.2,1.1,0.2c0.2,0.3,0.2,0.8-0.2,1.1C24.3,68.8,24.1,68.8,24,68.8z
				 M25.9,70.5c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.5-0.1-0.6-0.3c-0.3-0.3-0.2-0.8,0.1-1.1c0.3-0.3,0.8-0.2,1.1,0.1
				C26.3,69.7,26.3,70.2,25.9,70.5z M27.5,72.2c-0.1,0.2-0.4,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.6-0.2c-0.3-0.3-0.3-0.8,0-1.1
				c0.3-0.3,0.8-0.3,1.1,0C27.9,71.4,27.9,71.9,27.5,72.2z M29.3,73.8c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.5-0.2
				c-0.3-0.3-0.4-0.8-0.1-1.1c0.3-0.3,0.8-0.3,1.1-0.1C29.6,73,29.6,73.5,29.3,73.8z M31.1,75.3c-0.2,0.2-0.4,0.3-0.6,0.3
				c-0.2,0-0.3-0.1-0.5-0.2c-0.4-0.3-0.4-0.8-0.1-1.1c0.3-0.3,0.8-0.4,1.1-0.1C31.4,74.5,31.4,74.9,31.1,75.3z M31.1,24.6
				c-0.2,0-0.5-0.1-0.6-0.3c-0.3-0.4-0.2-0.8,0.2-1.1s0.8-0.2,1.1,0.2c0.3,0.4,0.2,0.8-0.2,1.1C31.4,24.6,31.3,24.6,31.1,24.6z
				 M33.1,76.6C33,76.9,32.7,77,32.5,77c-0.2,0-0.3,0-0.4-0.1c-0.4-0.2-0.5-0.7-0.2-1.1c0.2-0.4,0.7-0.5,1.1-0.2
				C33.2,75.8,33.3,76.3,33.1,76.6z M33.1,23.3c-0.3,0-0.5-0.1-0.7-0.4c-0.2-0.4-0.1-0.9,0.2-1.1c0.4-0.2,0.8-0.1,1.1,0.2
				c0.2,0.4,0.1,0.9-0.2,1.1C33.3,23.2,33.2,23.3,33.1,23.3z M35.2,77.8c-0.1,0.3-0.4,0.4-0.7,0.4c-0.1,0-0.3,0-0.4-0.1c0,0,0,0,0,0
				c-0.4-0.2-0.5-0.7-0.3-1.1c0.2-0.4,0.7-0.5,1.1-0.3C35.2,76.9,35.4,77.4,35.2,77.8z M35.5,22c-0.1,0.1-0.2,0.1-0.4,0.1
				c-0.3,0-0.5-0.1-0.7-0.4c-0.2-0.4-0.1-0.9,0.3-1.1c0.4-0.2,0.9-0.1,1.1,0.3C36,21.3,35.9,21.8,35.5,22z M37.3,78.8
				c-0.1,0.3-0.4,0.5-0.7,0.5c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0,0c-0.4-0.2-0.6-0.7-0.4-1.1c0.2-0.4,0.7-0.6,1-0.4
				C37.3,77.9,37.5,78.4,37.3,78.8z M37.5,21c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.2-0.7-0.5c-0.2-0.4,0-0.9,0.4-1
				c0.4-0.2,0.9,0,1,0.4C38.1,20.4,38,20.8,37.5,21z M39.5,79.7c-0.1,0.3-0.4,0.5-0.7,0.5c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0,0
				c-0.4-0.2-0.6-0.6-0.5-1c0.2-0.4,0.6-0.6,1-0.5C39.5,78.8,39.7,79.3,39.5,79.7z M39.7,20.2c-0.1,0-0.2,0-0.3,0
				c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.4,0.1-0.9,0.5-1c0.4-0.1,0.9,0.1,1,0.5C40.3,19.6,40.1,20,39.7,20.2z M41.8,80.4
				c-0.1,0.3-0.4,0.6-0.8,0.6c-0.1,0-0.2,0-0.2,0c-0.4-0.1-0.7-0.6-0.5-1c0.1-0.4,0.6-0.7,1-0.5C41.7,79.5,41.9,79.9,41.8,80.4z
				 M41.9,19.5c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7-0.2-0.8-0.6c-0.1-0.4,0.1-0.9,0.6-1c0.4-0.1,0.8,0.1,1,0.6C42.6,19,42.3,19.4,41.9,19.5
				z M44.1,80.9c-0.1,0.4-0.4,0.6-0.8,0.6c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.7-0.5-0.6-0.9s0.5-0.7,0.9-0.6C43.9,80,44.2,80.4,44.1,80.9z
				 M44.2,19c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7-0.3-0.8-0.6c-0.1-0.4,0.2-0.8,0.6-0.9s0.8,0.2,0.9,0.6C44.9,18.5,44.6,18.9,44.2,19z
				 M45.6,81.9C45.6,81.9,45.6,81.9,45.6,81.9c-0.5-0.1-0.8-0.5-0.8-0.9s0.5-0.7,0.9-0.7v0c0.4,0.1,0.7,0.4,0.7,0.9
				C46.4,81.6,46,81.9,45.6,81.9z M46.4,18.6C46.4,18.6,46.4,18.6,46.4,18.6c-0.5,0-0.8-0.3-0.9-0.7c-0.1-0.4,0.3-0.8,0.7-0.9
				s0.8,0.3,0.9,0.7S46.9,18.6,46.4,18.6z M48,82.1C48,82.1,48,82.1,48,82.1c-0.5,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.7
				c0.4,0,0.8,0.4,0.7,0.8C48.8,81.8,48.4,82.1,48,82.1z M48.7,18.5C48.7,18.5,48.7,18.5,48.7,18.5c-0.5,0-0.8-0.3-0.8-0.8
				s0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8C49.5,18.1,49.2,18.5,48.7,18.5z M50.4,82.2C50.4,82.2,50.4,82.2,50.4,82.2
				c-0.5,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8C51.2,81.8,50.8,82.2,50.4,82.2z M51.1,18.5
				C51,18.5,51,18.5,51.1,18.5c-0.5,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8C51.8,18.1,51.5,18.5,51.1,18.5z
				 M52.8,82.1C52.8,82.1,52.7,82.1,52.8,82.1c-0.5,0-0.8-0.3-0.9-0.7c0-0.4,0.3-0.8,0.7-0.9c0.4,0,0.8,0.3,0.9,0.7
				C53.5,81.7,53.2,82,52.8,82.1z M53.4,18.6C53.4,18.6,53.4,18.6,53.4,18.6c-0.5-0.1-0.8-0.4-0.8-0.9c0-0.4,0.4-0.8,0.9-0.7
				c0,0,0,0,0,0c0.4,0,0.7,0.4,0.7,0.9C54.2,18.3,53.8,18.6,53.4,18.6z M55.2,81.8L55.2,81.8c0,0-0.1,0-0.1,0c-0.4,0-0.7-0.3-0.8-0.7
				c-0.1-0.4,0.2-0.8,0.7-0.9h0c0.4-0.1,0.8,0.2,0.9,0.7C55.9,81.3,55.6,81.7,55.2,81.8z M55.8,19c0,0-0.1,0-0.1,0
				c-0.4-0.1-0.7-0.5-0.6-0.9c0.1-0.4,0.5-0.7,0.9-0.6c0.4,0.1,0.7,0.5,0.6,0.9C56.5,18.7,56.1,19,55.8,19z M57.6,81.3
				c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7-0.2-0.8-0.6c-0.1-0.4,0.2-0.8,0.6-0.9c0.4-0.1,0.8,0.2,1,0.6C58.2,80.8,58,81.2,57.6,81.3z
				 M58.8,18.9c-0.1,0.4-0.4,0.6-0.8,0.6c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.7-0.5-0.6-1c0.1-0.4,0.5-0.7,1-0.6
				C58.7,18,58.9,18.5,58.8,18.9z M59.9,80.7c-0.1,0-0.2,0-0.2,0c-0.3,0-0.7-0.2-0.8-0.6c-0.1-0.4,0.1-0.9,0.5-1
				c0.4-0.1,0.9,0.1,1,0.5C60.5,80.1,60.3,80.5,59.9,80.7z M60.3,20.2c-0.1,0-0.2,0-0.3,0c-0.4-0.1-0.6-0.6-0.5-1
				c0.1-0.4,0.6-0.6,1-0.5c0.4,0.1,0.6,0.6,0.5,1C61,20,60.7,20.2,60.3,20.2z M62.2,79.8c-0.1,0-0.2,0.1-0.3,0.1
				c-0.3,0-0.6-0.2-0.7-0.5c-0.2-0.4,0-0.9,0.4-1c0.4-0.2,0.9,0,1,0.4C62.8,79.2,62.6,79.7,62.2,79.8z M62.5,21c-0.1,0-0.2,0-0.3-0.1
				c-0.4-0.2-0.6-0.6-0.4-1c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1C63.1,20.9,62.8,21,62.5,21z M64.4,78.8
				c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.2-0.7-0.4c-0.2-0.4,0-0.9,0.3-1.1c0.4-0.2,0.9,0,1.1,0.3C64.9,78.2,64.8,78.6,64.4,78.8z
				 M64.7,22c-0.1,0-0.2,0-0.4-0.1c-0.4-0.2-0.5-0.7-0.3-1.1c0.2-0.4,0.7-0.5,1.1-0.3c0.4,0.2,0.5,0.7,0.3,1.1
				C65.2,21.9,65,22,64.7,22z M66.5,77.7c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.5-0.1-0.7-0.4c-0.2-0.4-0.1-0.8,0.3-1.1
				c0.4-0.2,0.9-0.1,1.1,0.3C67,77,66.9,77.5,66.5,77.7z M66.7,23.2c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.5-0.7-0.2-1.1
				c0.2-0.4,0.7-0.5,1.1-0.2s0.5,0.7,0.2,1.1C67.2,23.1,67,23.2,66.7,23.2z M68.6,76.4c-0.1,0.1-0.3,0.1-0.5,0.1
				c-0.2,0-0.5-0.1-0.7-0.3c-0.2-0.3-0.2-0.8,0.2-1.1s0.8-0.2,1.1,0.2C69,75.6,68.9,76.1,68.6,76.4z M70.5,74.9
				c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.5-0.1-0.6-0.3c-0.3-0.3-0.2-0.8,0.1-1.1c0.3-0.3,0.8-0.2,1.1,0.1
				C70.9,74.2,70.8,74.7,70.5,74.9z M72.3,73.3L72.3,73.3c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.6-0.2c-0.3-0.3-0.3-0.8,0-1.1h0
				c0.3-0.3,0.8-0.3,1.1,0C72.7,72.5,72.6,73,72.3,73.3z M74,71.6c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.5-0.2
				c-0.3-0.3-0.3-0.8-0.1-1.1c0.3-0.3,0.8-0.3,1.1-0.1C74.3,70.8,74.3,71.3,74,71.6z M75.6,69.8c-0.2,0.2-0.4,0.3-0.6,0.3
				c-0.2,0-0.3-0.1-0.5-0.2c-0.3-0.3-0.4-0.8-0.1-1.1c0.3-0.3,0.8-0.4,1.1-0.1C75.8,68.9,75.9,69.4,75.6,69.8z M77,67.8
				c-0.2,0.2-0.4,0.3-0.7,0.3c-0.2,0-0.3,0-0.4-0.1c-0.4-0.2-0.5-0.7-0.2-1.1h0c0.2-0.4,0.7-0.5,1.1-0.2C77.2,66.9,77.3,67.4,77,67.8z
				 M78.3,65.8c-0.2,0.2-0.4,0.4-0.7,0.4c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.5-0.7-0.3-1.1c0.2-0.4,0.7-0.5,1.1-0.3
				C78.4,64.9,78.5,65.4,78.3,65.8z M79.4,63.6C79.3,63.9,79,64,78.7,64c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.7-0.4-1
				c0.2-0.4,0.7-0.6,1.1-0.4C79.4,62.7,79.6,63.2,79.4,63.6z M80.4,61.4c-0.1,0.3-0.4,0.5-0.7,0.5c-0.1,0-0.2,0-0.3-0.1
				c-0.4-0.2-0.6-0.6-0.4-1s0.6-0.6,1-0.5C80.3,60.5,80.6,61,80.4,61.4z M81.2,59.1c-0.1,0.3-0.4,0.6-0.8,0.6c-0.1,0-0.2,0-0.2,0
				c-0.4-0.1-0.7-0.6-0.5-1c0.1-0.4,0.6-0.7,1-0.5C81.1,58.2,81.3,58.7,81.2,59.1z M81.8,56.7c-0.1,0.4-0.4,0.6-0.8,0.6
				c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.7-0.5-0.6-0.9c0.1-0.4,0.5-0.7,0.9-0.6C81.6,55.9,81.9,56.3,81.8,56.7z M81.5,55c0,0-0.1,0-0.1,0
				c-0.4-0.1-0.7-0.5-0.7-0.9c0.1-0.4,0.5-0.7,0.9-0.7c0.4,0.1,0.7,0.5,0.7,0.9C82.2,54.8,81.8,55,81.5,55z M81.7,52.7
				C81.7,52.7,81.7,52.7,81.7,52.7c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.7c0.4,0,0.8,0.4,0.7,0.8C82.5,52.4,82.1,52.7,81.7,52.7z"/>
			<path id="XMLID_1171_" style={{fill : color}} d="M86.9,49.5c0-20.4-16.6-37-37-37s-37,16.6-37,37s16.6,37,37,37S86.9,69.9,86.9,49.5z M49.9,85
				c-19.5,0-35.4-15.9-35.4-35.4s15.9-35.4,35.4-35.4S85.4,30,85.4,49.5S69.5,85,49.9,85z"/>
			<path id="XMLID_1172_" style={{fill : color}} d="M50.3,80.6c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8c0,0,0,0,0,0c0.4,0,0.8-0.4,0.8-0.8
				C51.1,81,50.8,80.6,50.3,80.6z"/>
			<path id="XMLID_1173_" style={{fill : color}} d="M48.7,16.9c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8c0,0,0,0,0,0c0.4,0,0.8-0.4,0.8-0.8
				C49.5,17.2,49.1,16.9,48.7,16.9z"/>
			<path id="XMLID_1174_" style={{fill : color}} d="M45.8,80.3L45.8,80.3c-0.4-0.1-0.8,0.2-0.9,0.7c-0.1,0.4,0.2,0.8,0.7,0.9c0,0,0.1,0,0.1,0
				c0.4,0,0.7-0.3,0.8-0.7C46.5,80.8,46.2,80.4,45.8,80.3z"/>
			<path id="XMLID_1175_" style={{fill : color}} d="M46.2,17.1c-0.4,0-0.8,0.4-0.7,0.9c0,0.4,0.4,0.7,0.8,0.7c0,0,0.1,0,0.1,0c0.4,0,0.7-0.4,0.7-0.9
				C47.1,17.3,46.7,17,46.2,17.1z"/>
			<path id="XMLID_1176_" style={{fill : color}} d="M51.1,16.9c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8c0,0,0,0,0,0c0.4,0,0.8-0.3,0.8-0.8
				C51.9,17.3,51.5,16.9,51.1,16.9z"/>
			<path id="XMLID_1177_" style={{fill : color}} d="M48,80.6c-0.4,0-0.8,0.3-0.8,0.7c0,0.4,0.3,0.8,0.7,0.8c0,0,0,0,0,0c0.4,0,0.8-0.3,0.8-0.7
				C48.8,81,48.5,80.6,48,80.6z"/>
			<path id="XMLID_1178_" style={{fill : color}} d="M57.2,79.8c-0.4,0.1-0.7,0.5-0.6,0.9c0.1,0.3,0.4,0.6,0.8,0.6c0.1,0,0.1,0,0.2,0c0.4-0.1,0.7-0.5,0.6-0.9
				C58,79.9,57.6,79.7,57.2,79.8z"/>
			<path id="XMLID_1179_" style={{fill : color}} d="M55.9,17.4c-0.4-0.1-0.8,0.2-0.9,0.6c-0.1,0.4,0.2,0.8,0.6,0.9c0,0,0.1,0,0.1,0c0.4,0,0.7-0.3,0.8-0.6
				C56.6,17.9,56.3,17.5,55.9,17.4z"/>
			<path id="XMLID_1180_" style={{fill : color}} d="M54.9,80.2L54.9,80.2c-0.4,0.1-0.7,0.5-0.7,0.9c0.1,0.4,0.4,0.7,0.8,0.7c0,0,0.1,0,0.1,0c0,0,0,0,0,0
				c0.4-0.1,0.7-0.5,0.7-0.9C55.8,80.4,55.4,80.2,54.9,80.2z"/>
			<path id="XMLID_1181_" style={{fill : color}} d="M52.7,80.5c-0.4,0-0.8,0.4-0.7,0.9c0,0.4,0.4,0.7,0.8,0.7c0,0,0,0,0.1,0c0.4,0,0.8-0.4,0.7-0.9
				C53.5,80.8,53.1,80.5,52.7,80.5z"/>
			<path id="XMLID_1182_" style={{fill : color}} d="M53.5,17.1C53.5,17.1,53.5,17.1,53.5,17.1c-0.4,0-0.8,0.3-0.9,0.7s0.3,0.8,0.7,0.9c0,0,0,0,0.1,0
				c0.4,0,0.7-0.3,0.8-0.7C54.2,17.5,53.9,17.1,53.5,17.1z"/>
			<path id="XMLID_1183_" style={{fill : color}} d="M43.9,17.4c-0.4,0.1-0.7,0.5-0.6,0.9c0.1,0.4,0.4,0.6,0.8,0.6c0,0,0.1,0,0.2,0c0.4-0.1,0.7-0.5,0.6-0.9
				C44.7,17.6,44.3,17.4,43.9,17.4z"/>
			<path id="XMLID_1184_" style={{fill : color}} d="M58.3,17.9c-0.4-0.1-0.8,0.1-1,0.6c-0.1,0.4,0.1,0.9,0.6,1c0.1,0,0.1,0,0.2,0c0.3,0,0.7-0.2,0.8-0.6
				C58.9,18.5,58.7,18,58.3,17.9z"/>
			<path id="XMLID_1185_" style={{fill : color}} d="M38,20c-0.2-0.4-0.6-0.6-1-0.4c-0.4,0.2-0.6,0.6-0.4,1c0.1,0.3,0.4,0.5,0.7,0.5c0.1,0,0.2,0,0.3-0.1
				C38,20.8,38.1,20.4,38,20z"/>
			<path id="XMLID_1186_" style={{fill : color}} d="M36.9,77.8c-0.4-0.2-0.9,0-1,0.4c-0.2,0.4,0,0.9,0.4,1.1c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.3,0.1
				c0.3,0,0.6-0.2,0.7-0.5C37.5,78.4,37.3,77.9,36.9,77.8z"/>
			<path id="XMLID_1187_" style={{fill : color}} d="M34.9,76.7c-0.4-0.2-0.9-0.1-1.1,0.3c-0.2,0.4-0.1,0.9,0.3,1.1c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.4,0.1
				c0.3,0,0.5-0.1,0.7-0.4C35.4,77.4,35.2,76.9,34.9,76.7z"/>
			<path id="XMLID_1188_" style={{fill : color}} d="M33.7,22.1c-0.2-0.4-0.7-0.5-1.1-0.2c-0.4,0.2-0.5,0.7-0.2,1.1c0.2,0.2,0.4,0.4,0.7,0.4
				c0.1,0,0.3,0,0.4-0.1C33.8,22.9,34,22.5,33.7,22.1z"/>
			<path id="XMLID_1189_" style={{fill : color}} d="M39,78.7c-0.4-0.2-0.9,0.1-1,0.5c-0.2,0.4,0.1,0.9,0.5,1c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.1
				c0.3,0,0.6-0.2,0.7-0.5C39.7,79.3,39.5,78.8,39,78.7z"/>
			<path id="XMLID_1190_" style={{fill : color}} d="M41.5,18c-0.4,0.1-0.7,0.5-0.6,1c0.1,0.4,0.4,0.6,0.8,0.6c0.1,0,0.1,0,0.2,0c0.4-0.1,0.7-0.5,0.6-1
				C42.3,18.1,41.9,17.9,41.5,18z"/>
			<path id="XMLID_1191_" style={{fill : color}} d="M43.5,79.9c-0.4-0.1-0.8,0.2-0.9,0.6s0.2,0.8,0.6,0.9c0,0,0.1,0,0.2,0c0.4,0,0.7-0.2,0.8-0.6
				C44.2,80.4,43.9,80,43.5,79.9z"/>
			<path id="XMLID_1192_" style={{fill : color}} d="M41.2,79.4c-0.4-0.1-0.9,0.1-1,0.5s0.1,0.9,0.5,1c0.1,0,0.2,0,0.2,0c0.3,0,0.7-0.2,0.8-0.6
				C41.9,79.9,41.7,79.5,41.2,79.4z"/>
			<path id="XMLID_1193_" style={{fill : color}} d="M39.2,18.7c-0.4,0.1-0.6,0.6-0.5,1c0.1,0.3,0.4,0.5,0.7,0.5c0.1,0,0.2,0,0.3,0c0.4-0.1,0.6-0.6,0.5-1
				C40,18.8,39.6,18.5,39.2,18.7z"/>
			<path id="XMLID_1194_" style={{fill : color}} d="M35.8,21c-0.2-0.4-0.7-0.5-1.1-0.3c-0.4,0.2-0.5,0.7-0.3,1.1c0.1,0.3,0.4,0.4,0.7,0.4
				c0.1,0,0.2,0,0.4-0.1C35.9,21.8,36,21.3,35.8,21z"/>
			<path id="XMLID_1195_" style={{fill : color}} d="M78,64.7c-0.4-0.2-0.9-0.1-1.1,0.3c-0.2,0.4-0.1,0.9,0.3,1.1c0.1,0.1,0.3,0.1,0.4,0.1
				c0.3,0,0.5-0.1,0.7-0.4C78.5,65.4,78.4,64.9,78,64.7z"/>
			<path id="XMLID_1196_" style={{fill : color}} d="M79.1,62.5c-0.4-0.2-0.9,0-1.1,0.4c-0.2,0.4,0,0.9,0.4,1c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.2,0.7-0.5
				C79.6,63.2,79.4,62.7,79.1,62.5z"/>
			<path id="XMLID_1197_" style={{fill : color}} d="M75.7,66.9L75.7,66.9c-0.2,0.4-0.2,0.8,0.2,1.1c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.5-0.1,0.7-0.3
				c0.2-0.4,0.2-0.8-0.2-1.1C76.4,66.5,75.9,66.6,75.7,66.9z"/>
			<path id="XMLID_1198_" style={{fill : color}} d="M72.9,70.5c-0.3,0.3-0.3,0.8,0.1,1.1c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.6-0.3
				c0.3-0.3,0.3-0.8-0.1-1.1S73.2,70.2,72.9,70.5z"/>
			<path id="XMLID_1199_" style={{fill : color}} d="M74.3,68.8c-0.3,0.3-0.2,0.8,0.1,1.1c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.5-0.1,0.6-0.3
				c0.3-0.3,0.2-0.8-0.1-1.1C75.1,68.4,74.6,68.4,74.3,68.8z"/>
			<path id="XMLID_1200_" style={{fill : color}} d="M79.9,60.4c-0.4-0.2-0.9,0-1,0.5c-0.2,0.4,0.1,0.9,0.4,1c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.2,0.7-0.5
				C80.6,61,80.3,60.5,79.9,60.4z"/>
			<path id="XMLID_1201_" style={{fill : color}} d="M81.6,53.5c-0.4-0.1-0.8,0.2-0.9,0.7c-0.1,0.4,0.2,0.8,0.7,0.9c0,0,0.1,0,0.1,0c0.4,0,0.7-0.3,0.8-0.7
				C82.3,53.9,82,53.5,81.6,53.5z"/>
			<path id="XMLID_1202_" style={{fill : color}} d="M81.8,51.1c-0.4,0-0.8,0.3-0.8,0.7s0.3,0.8,0.7,0.8c0,0,0,0,0.1,0c0.4,0,0.8-0.3,0.8-0.7
				C82.6,51.5,82.2,51.1,81.8,51.1z"/>
			<path id="XMLID_1203_" style={{fill : color}} d="M80.7,58.1c-0.4-0.1-0.9,0.1-1,0.5c-0.1,0.4,0.1,0.9,0.5,1c0.1,0,0.2,0,0.2,0c0.3,0,0.7-0.2,0.8-0.6
				C81.3,58.7,81.1,58.2,80.7,58.1z"/>
			<path id="XMLID_1204_" style={{fill : color}} d="M81.2,55.8c-0.4-0.1-0.8,0.2-0.9,0.6c-0.1,0.4,0.2,0.8,0.6,0.9c0.1,0,0.1,0,0.2,0c0.4,0,0.7-0.2,0.8-0.6
				C81.9,56.3,81.6,55.9,81.2,55.8z"/>
			<path id="XMLID_1205_" style={{fill : color}} d="M71.2,72.2L71.2,72.2c-0.3,0.3-0.3,0.8,0,1.1c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.2c0,0,0,0,0,0
				c0.3-0.3,0.3-0.8,0-1.1C72.1,71.9,71.6,71.9,71.2,72.2z"/>
			<path id="XMLID_1206_" style={{fill : color}} d="M63.7,77.4c-0.4,0.2-0.5,0.7-0.3,1.1c0.1,0.3,0.4,0.4,0.7,0.4c0.1,0,0.2,0,0.3-0.1
				c0.4-0.2,0.6-0.7,0.3-1.1C64.6,77.4,64.1,77.2,63.7,77.4z"/>
			<path id="XMLID_1207_" style={{fill : color}} d="M65,20.6c-0.4-0.2-0.9-0.1-1.1,0.3c-0.2,0.4,0,0.9,0.3,1.1c0.1,0.1,0.2,0.1,0.4,0.1
				c0.3,0,0.6-0.1,0.7-0.4C65.6,21.2,65.4,20.8,65,20.6z"/>
			<path id="XMLID_1208_" style={{fill : color}} d="M62.8,19.5c-0.4-0.2-0.9,0-1,0.4s0,0.9,0.4,1c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.2,0.7-0.5
				C63.4,20.1,63.2,19.7,62.8,19.5z"/>
			<path id="XMLID_1209_" style={{fill : color}} d="M61.6,78.4c-0.4,0.2-0.6,0.6-0.4,1c0.1,0.3,0.4,0.5,0.7,0.5c0.1,0,0.2,0,0.3-0.1c0.4-0.2,0.6-0.6,0.4-1
				C62.5,78.4,62,78.2,61.6,78.4z"/>
			<path id="XMLID_1210_" style={{fill : color}} d="M60.6,18.6c-0.4-0.1-0.9,0.1-1,0.5c-0.1,0.4,0.1,0.9,0.5,1c0.1,0,0.2,0,0.3,0c0.3,0,0.6-0.2,0.7-0.5
				C61.2,19.2,61,18.8,60.6,18.6z"/>
			<path id="XMLID_1211_" style={{fill : color}} d="M59.4,79.2c-0.4,0.1-0.7,0.6-0.5,1c0.1,0.3,0.4,0.6,0.8,0.6c0.1,0,0.2,0,0.2,0c0.4-0.1,0.6-0.6,0.5-1
				C60.3,79.2,59.8,79,59.4,79.2z"/>
			<path id="XMLID_1212_" style={{fill : color}} d="M69.5,73.7c-0.3,0.3-0.4,0.8-0.1,1.1c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.3-0.1,0.5-0.2
				c0.3-0.3,0.4-0.8,0.1-1.1C70.3,73.5,69.8,73.4,69.5,73.7z"/>
			<path id="XMLID_1213_" style={{fill : color}} d="M67.7,75.1c-0.3,0.2-0.4,0.7-0.2,1.1c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.3-0.1,0.5-0.1
				c0.3-0.2,0.4-0.7,0.2-1.1C68.5,74.9,68,74.8,67.7,75.1z"/>
			<path id="XMLID_1214_" style={{fill : color}} d="M65.7,76.3c-0.4,0.2-0.5,0.7-0.3,1.1c0.2,0.2,0.4,0.4,0.7,0.4c0.1,0,0.3,0,0.4-0.1
				c0.4-0.2,0.5-0.7,0.3-1.1C66.6,76.2,66.1,76.1,65.7,76.3z"/>
			<path id="XMLID_1215_" style={{fill : color}} d="M67.1,21.8c-0.4-0.2-0.8-0.1-1.1,0.2c-0.2,0.4-0.1,0.9,0.2,1.1c0.1,0.1,0.3,0.1,0.4,0.1
				c0.3,0,0.5-0.1,0.7-0.4C67.6,22.5,67.5,22,67.1,21.8z"/>
			<path id="XMLID_1216_" style={{fill : color}} d="M81.7,47.1c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.7,0.8,0.7c0,0,0,0,0,0c0.4,0,0.8-0.4,0.8-0.8
				C82.5,47.4,82.2,47.1,81.7,47.1z"/>
			<path id="XMLID_1217_" style={{fill : color}} d="M27.5,27.7c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1
				C27.1,27.6,27.3,27.7,27.5,27.7z"/>
			<path id="XMLID_1218_" style={{fill : color}} d="M29.2,26.1c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.4-0.8,0.1-1.1s-0.8-0.4-1.1-0.1s-0.4,0.8-0.1,1.1
				C28.8,26,29,26.1,29.2,26.1z"/>
			<path id="XMLID_1219_" style={{fill : color}} d="M68.7,24.5c0.2,0,0.5-0.1,0.6-0.3c0.3-0.4,0.2-0.8-0.2-1.1s-0.8-0.2-1.1,0.2s-0.2,0.9,0.2,1.1
				C68.4,24.5,68.5,24.5,68.7,24.5z"/>
			<path id="XMLID_1220_" style={{fill : color}} d="M72.3,27.6c0.2,0,0.4-0.1,0.6-0.2c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1
				C71.9,27.5,72.1,27.6,72.3,27.6z"/>
			<path id="XMLID_1221_" style={{fill : color}} d="M70.6,26c0.2,0,0.4-0.1,0.6-0.3c0.3-0.3,0.2-0.8-0.1-1.1c-0.3-0.3-0.8-0.2-1.1,0.1
				c-0.3,0.3-0.2,0.8,0.1,1.1C70.2,25.9,70.4,26,70.6,26z"/>
			<path id="XMLID_1222_" style={{fill : color}} d="M21.5,35.1c0.1,0.1,0.2,0.1,0.4,0.1c0.3,0,0.6-0.2,0.7-0.4c0.2-0.4,0.1-0.9-0.3-1.1
				c-0.4-0.2-0.9-0.1-1.1,0.3c0,0,0,0,0,0C21,34.5,21.1,34.9,21.5,35.1z"/>
			<path id="XMLID_1223_" style={{fill : color}} d="M73.9,29.3c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.4-0.8,0.1-1.1c-0.3-0.3-0.8-0.4-1.1-0.1
				c-0.3,0.3-0.4,0.8-0.1,1.1C73.5,29.2,73.7,29.3,73.9,29.3z"/>
			<path id="XMLID_1224_" style={{fill : color}} d="M22.6,33.1c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.5-0.1,0.7-0.4c0.2-0.4,0.1-0.9-0.2-1.1
				c-0.4-0.2-0.9-0.1-1.1,0.2C22.2,32.3,22.3,32.8,22.6,33.1z"/>
			<path id="XMLID_1225_" style={{fill : color}} d="M24.4,31.2c0.2,0,0.5-0.1,0.6-0.3c0.3-0.4,0.2-0.8-0.2-1.1c-0.4-0.3-0.8-0.2-1.1,0.2
				c-0.3,0.4-0.2,0.8,0.2,1.1C24.1,31.2,24.2,31.2,24.4,31.2z"/>
			<path id="XMLID_1226_" style={{fill : color}} d="M25.9,29.4c0.2,0,0.4-0.1,0.6-0.3c0.3-0.3,0.2-0.8-0.1-1.1c-0.3-0.3-0.8-0.2-1.1,0.1
				c-0.3,0.3-0.2,0.8,0.1,1.1C25.5,29.3,25.7,29.4,25.9,29.4z"/>
			<path id="XMLID_1227_" style={{fill : color}} d="M76.1,32.7c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.3,0,0.4-0.1c0.4-0.2,0.5-0.7,0.2-1.1c0,0,0,0,0,0
				c-0.2-0.4-0.7-0.5-1.1-0.2C76,31.9,75.9,32.4,76.1,32.7z"/>
			<path id="XMLID_1228_" style={{fill : color}} d="M79.9,41.1c0.1,0.3,0.4,0.6,0.8,0.6c0.1,0,0.1,0,0.2,0c0.4-0.1,0.7-0.5,0.5-1c-0.1-0.4-0.6-0.7-1-0.5
				C80,40.2,79.8,40.7,79.9,41.1z"/>
			<path id="XMLID_1229_" style={{fill : color}} d="M80.8,45.6c0.1,0.4,0.4,0.7,0.8,0.7c0,0,0.1,0,0.1,0c0.4-0.1,0.7-0.5,0.7-0.9c-0.1-0.4-0.4-0.7-0.9-0.7
				C81,44.8,80.7,45.2,80.8,45.6z"/>
			<path id="XMLID_1230_" style={{fill : color}} d="M81,42.4c-0.4,0.1-0.7,0.5-0.6,0.9c0.1,0.4,0.4,0.6,0.8,0.6c0.1,0,0.1,0,0.2,0c0.4-0.1,0.7-0.5,0.6-0.9
				C81.9,42.6,81.5,42.3,81,42.4z"/>
			<path id="XMLID_1231_" style={{fill : color}} d="M81.8,48.8c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8
				C82.6,49.1,82.2,48.8,81.8,48.8z"/>
			<path id="XMLID_1232_" style={{fill : color}} d="M31.8,75.8c-0.2,0.4-0.1,0.8,0.2,1.1c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.5-0.1,0.7-0.3
				c0.2-0.4,0.1-0.8-0.2-1.1C32.5,75.3,32,75.4,31.8,75.8z"/>
			<path id="XMLID_1233_" style={{fill : color}} d="M20.5,37.3c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.2,0.7-0.5c0.2-0.4,0-0.9-0.4-1c-0.4-0.2-0.9,0-1,0.4
				C19.9,36.6,20.1,37.1,20.5,37.3z"/>
			<path id="XMLID_1234_" style={{fill : color}} d="M79.2,38.9c0.1,0.3,0.4,0.5,0.7,0.5c0.1,0,0.2,0,0.3,0c0.4-0.2,0.6-0.6,0.5-1s-0.6-0.6-1-0.5
				C79.2,38,79,38.5,79.2,38.9z"/>
			<path id="XMLID_1235_" style={{fill : color}} d="M77.3,34.7c0.1,0.3,0.4,0.4,0.7,0.4c0.1,0,0.2,0,0.4-0.1c0.4-0.2,0.5-0.7,0.3-1.1
				c-0.2-0.4-0.7-0.5-1.1-0.3C77.2,33.8,77.1,34.3,77.3,34.7z"/>
			<path id="XMLID_1236_" style={{fill : color}} d="M74.8,30.8c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.3-0.1,0.5-0.2c0.3-0.3,0.4-0.8,0.2-1.1
				c-0.3-0.4-0.8-0.4-1.1-0.2S74.5,30.5,74.8,30.8z"/>
			<path id="XMLID_1237_" style={{fill : color}} d="M78.3,36.8c0.1,0.3,0.4,0.5,0.7,0.5c0.1,0,0.2,0,0.3-0.1c0.4-0.2,0.6-0.7,0.4-1c-0.2-0.4-0.7-0.6-1-0.4
				C78.3,35.9,78.1,36.4,78.3,36.8z"/>
			<path id="XMLID_1238_" style={{fill : color}} d="M19.7,39.5c0.1,0,0.2,0,0.3,0c0.3,0,0.6-0.2,0.7-0.5c0.1-0.4-0.1-0.9-0.5-1c-0.4-0.2-0.9,0.1-1,0.5
				C19,38.9,19.3,39.3,19.7,39.5z"/>
			<path id="XMLID_1239_" style={{fill : color}} d="M21.3,61.5c-0.2-0.4-0.6-0.6-1-0.4c-0.4,0.2-0.6,0.6-0.4,1c0.1,0.3,0.4,0.5,0.7,0.5c0.1,0,0.2,0,0.3-0.1
				C21.2,62.4,21.4,62,21.3,61.5z"/>
			<path id="XMLID_1240_" style={{fill : color}} d="M23.4,65.7c-0.2-0.4-0.7-0.5-1.1-0.3c-0.4,0.2-0.5,0.7-0.3,1.1c0.1,0.2,0.4,0.4,0.7,0.4
				c0.1,0,0.3,0,0.4-0.1C23.5,66.5,23.6,66,23.4,65.7z"/>
			<path id="XMLID_1241_" style={{fill : color}} d="M22.2,63.6c-0.2-0.4-0.7-0.5-1.1-0.3c-0.4,0.2-0.5,0.7-0.3,1.1c0.1,0.3,0.4,0.4,0.7,0.4
				c0.1,0,0.2,0,0.4-0.1C22.3,64.5,22.4,64,22.2,63.6z"/>
			<path id="XMLID_1242_" style={{fill : color}} d="M24.9,69.3c-0.3,0.3-0.4,0.8-0.1,1.1c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.5-0.2
				c0.3-0.3,0.4-0.8,0.1-1.1C25.8,69.1,25.3,69,24.9,69.3z"/>
			<path id="XMLID_1243_" style={{fill : color}} d="M29.9,74.3c-0.3,0.3-0.2,0.8,0.1,1.1c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.5-0.1,0.6-0.3
				c0.3-0.3,0.2-0.8-0.1-1.1C30.7,73.9,30.2,74,29.9,74.3z"/>
			<path id="XMLID_1244_" style={{fill : color}} d="M31.7,23.4c-0.3-0.4-0.8-0.4-1.1-0.2c-0.4,0.2-0.4,0.8-0.2,1.1c0.2,0.2,0.4,0.3,0.6,0.3
				c0.2,0,0.3,0,0.5-0.1C31.9,24.2,32,23.7,31.7,23.4z"/>
			<path id="XMLID_1245_" style={{fill : color}} d="M28.1,72.8c-0.3,0.3-0.3,0.8,0.1,1.1c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.6-0.3
				c0.3-0.3,0.3-0.8-0.1-1.1C28.9,72.4,28.4,72.5,28.1,72.8z"/>
			<path id="XMLID_1246_" style={{fill : color}} d="M26.5,71.1c-0.3,0.3-0.3,0.8,0,1.1c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1
				C27.3,70.8,26.8,70.8,26.5,71.1z"/>
			<path id="XMLID_1247_" style={{fill : color}} d="M24.6,67.6c-0.2-0.3-0.7-0.4-1.1-0.2c-0.4,0.2-0.4,0.7-0.2,1.1c0.1,0.2,0.4,0.3,0.6,0.3
				c0.2,0,0.3-0.1,0.5-0.2C24.8,68.4,24.9,67.9,24.6,67.6z"/>
			<path id="XMLID_1248_" style={{fill : color}} d="M18.1,48.8C18.1,48.8,18.1,48.8,18.1,48.8c0.5,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8
				c-0.4,0-0.8,0.3-0.8,0.8S17.6,48.8,18.1,48.8z"/>
			<path id="XMLID_1249_" style={{fill : color}} d="M18.5,44.1c0,0,0.1,0,0.1,0c0.4,0,0.7-0.3,0.8-0.6c0.1-0.4-0.2-0.8-0.6-0.9c-0.4-0.1-0.8,0.2-0.9,0.6
				C17.8,43.6,18.1,44,18.5,44.1z"/>
			<path id="XMLID_1250_" style={{fill : color}} d="M20.5,59.4c-0.1-0.4-0.6-0.6-1-0.5c-0.4,0.1-0.6,0.6-0.5,1c0.1,0.3,0.4,0.5,0.8,0.5c0.1,0,0.2,0,0.2,0
				C20.4,60.2,20.6,59.8,20.5,59.4z"/>
			<path id="XMLID_1251_" style={{fill : color}} d="M19,41.8c0.1,0,0.1,0,0.2,0c0.3,0,0.7-0.2,0.8-0.6c0.1-0.4-0.1-0.8-0.5-1c-0.4-0.1-0.9,0.1-1,0.5
				C18.3,41.2,18.6,41.7,19,41.8z"/>
			<path id="XMLID_1252_" style={{fill : color}} d="M18.2,46.4C18.2,46.4,18.3,46.4,18.2,46.4c0.5,0,0.8-0.3,0.9-0.7c0.1-0.4-0.2-0.8-0.7-0.9
				c-0.4-0.1-0.8,0.2-0.9,0.7C17.5,46,17.8,46.4,18.2,46.4z"/>
			<path id="XMLID_1253_" style={{fill : color}} d="M19.2,58.1c0.4-0.1,0.7-0.5,0.6-1c-0.1-0.4-0.5-0.7-1-0.6c-0.4,0.1-0.7,0.5-0.6,1
				c0.1,0.3,0.4,0.6,0.8,0.6C19.1,58.2,19.2,58.2,19.2,58.1z"/>
			<path id="XMLID_1254_" style={{fill : color}} d="M18.7,55.8c0.4-0.1,0.7-0.5,0.6-0.9c-0.1-0.4-0.5-0.7-0.9-0.6c-0.4,0.1-0.7,0.5-0.6,0.9
				c0.1,0.4,0.4,0.7,0.8,0.7C18.6,55.8,18.6,55.8,18.7,55.8z"/>
			<path id="XMLID_1255_" style={{fill : color}} d="M18.3,53.5c0.4,0,0.8-0.4,0.7-0.9c0-0.4-0.4-0.8-0.9-0.7c-0.4,0-0.8,0.4-0.7,0.9
				C17.5,53.2,17.8,53.5,18.3,53.5C18.3,53.5,18.3,53.5,18.3,53.5z"/>
			<path id="XMLID_1256_" style={{fill : color}} d="M18.1,51.2C18.1,51.2,18.1,51.2,18.1,51.2c0.5,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8
				c-0.4,0-0.8,0.4-0.8,0.8C17.3,50.8,17.7,51.2,18.1,51.2z"/>
			<path id="XMLID_1257_" style={{fill : color}} d="M12,79.5c-6.8-8.6-10.4-19-10.4-29.9c0-10.7,3.4-20.8,9.9-29.3l-1.3-0.9C3.5,28.1,0,38.5,0,49.5
				c0,11.3,3.7,22,10.7,30.9L12,79.5z"/>
			<path id="XMLID_1258_" style={{fill : color}} d="M89.2,80.4c7-8.9,10.7-19.6,10.7-30.9c0-11-3.5-21.5-10.2-30.2l-1.3,1c6.5,8.5,9.9,18.6,9.9,29.3
				c0,11-3.6,21.3-10.4,29.9L89.2,80.4z"/>
		</g>
		</svg>
	)
}
