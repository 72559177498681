import { parse } from 'date-fns'
// import { zonedTimeToUtc, utcToZonedTime, format, parseFromTimeZone } from 'date-fns-timezone'
import { parseFromTimeZoneParis } from '../helpers'

const valueDateStart = parse(
  '22/01/2021 15:30:00',
  'dd/MM/yyyy HH:mm:ss',
  new Date()
)

const valuedateEndStream1 = parse(
  '22/01/2021 17:07:00',
  'dd/MM/yyyy HH:mm:ss',
  new Date()
)

// const utcDate = parseFromTimeZoneParis(valuedateEndStream1, { timeZone: 'Europe/Paris' });

const valuedateStartStream2 = parse(
  '22/01/2021 18:22:00',
  'dd/MM/yyyy HH:mm:ss',
  new Date()
)

const valuedateEndStream2 = parse(
  '22/01/2021 19:00:00',
  'dd/MM/yyyy HH:mm:ss',
  new Date()
)

// const date = parse(
//   '15/01/2021 14:30:10',
//   'dd/MM/yyyy HH:mm:ss',
//   new Date()
// )


// DEMAIN 10H
// EMAIL LIEN TEST
//

export const dateStart = parseFromTimeZoneParis(valueDateStart)
export const dateEndStream1 = parseFromTimeZoneParis(valuedateEndStream1)
export const dateStartStream2 = parseFromTimeZoneParis(valuedateStartStream2)
export const dateEndStream2 = parseFromTimeZoneParis(valuedateEndStream2)
