import { useState, forwardRef, useImperativeHandle } from "react"
import styles from "./input-file.module.scss"

const InputFile = ({ name, accept = "image/png, image/jpeg" ,handleChange = () => {} }, ref) => {
  const [nameFile, setNameFile] = useState();
  const [selectedFile, setSelectedFile] = useState("");

  useImperativeHandle(ref,()=>({
    getFile : () => selectedFile,
    getObjectFile : () => ({ [name] : selectedFile}),
    resetFile : () => {
      setSelectedFile("")
      setNameFile("")
    }
  }))

  function handleChangeFile(e){
    setSelectedFile(e.target.files[0])
    setNameFile(e.target.files[0].name)
    handleChange && handleChange(e.target.files[0],name)
  }

  return (
    <div className={styles["input-file"]}>
      <label>
        Joindre
        <input
          accept={accept}
          style={{ display: "none" }}
          type="file"
          onChange={(e) => handleChangeFile(e)}
        />
      </label>
      { nameFile && <div className={styles["file-name"]}>{nameFile}</div> }

    </div>
  )

};

export default forwardRef(InputFile);
