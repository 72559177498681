import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useSpring, animated, config} from 'react-spring';
import { NavLink, useHistory } from "react-router-dom";
import styles from "./launch.module.scss"
import { editUserTeam } from '../../../actions/user'
import { dateStart, dateEndStream1 } from '../../../config'
import _ from 'lodash';
import Modal from '../../../components/Modal'
import ModalHurryUp from '../../../components/ModalHurryUp'


export default function Launch ({ws}){
  const userReducer = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const history = useHistory();
  const modalRef = useRef();
  const modalHurryUpRef = useRef();

  const [emailLeader, setEmailLeader ] = useState(null)
  const [isLeader, setIsLeader] = useState(null)
  const [ showJoinTeam, setShowJoinTeam] = useState(false)
  const [ animateIn, setAnimateIn] = useState(false)

  const props = useSpring({
    transform: animateIn ? 'translate3d(0,0%,0)' : 'translate3d(0,100%,0)',
    opacity : animateIn ? "1" : "0"
  })

  function startGame(){
    if(!userReducer.team) return;
    editUserTeam(dispatch,{ inGame : true}, userReducer.team._id)
    history.push("/game/problematic/action")
  }

  useEffect(()=>{
    if(!userReducer.team?.votes) return;
    let leader = _.maxBy(Object.entries(userReducer.team.votes),_.last)
    if(leader){
      setIsLeader(userReducer.user.email === leader[0])
      setEmailLeader(leader[0])
    }else{
      setIsLeader(null)
      setEmailLeader(null)
    }
  },[userReducer.team?.votes])

  //HANDLE HURRY UP POPUP
  useEffect(()=>{
    let dateNow = Date.now();
    let stepStart = new Date(dateEndStream1)
    let distanceTimestamp = Math.floor((dateNow - stepStart.getTime())/1000)
    let delay = 5 * 60
    // let delay = 10 * 60

    if(distanceTimestamp >= 0) setShowJoinTeam(true)

    if(delay > distanceTimestamp){

      let timer = setInterval(()=>{
        let dateNow = Date.now();
        let stepStart = new Date(dateEndStream1)
        let distanceTimestamp = Math.floor((dateNow - stepStart.getTime())/1000)
        if(distanceTimestamp === 0 ){
          setShowJoinTeam(true)
        }

        if(delay < distanceTimestamp && modalHurryUpRef.current){
          modalHurryUpRef.current.openModal();
          clearInterval(timer)
        }

      },1000)

    }
  },[])



  //MENU EN GRAS
  // CRAZY 6 METTRE LA VIDEO

  // LOGIC USE TO FIRST RENDER THE ANIMATED PART WHEN showJoinTeam === true
  // THEM SET animateIn to true to trigger the animation
  useEffect(()=>{
    if(showJoinTeam) setAnimateIn(true)
  },[showJoinTeam])


  useEffect(()=>{
    if(!ws || !userReducer.team) return;
    ws.addEventListener("message", listen);

    function listen(message){
      let data = JSON.parse(message.data)
      if((userReducer.team && userReducer.team._id === data.teamId) &&
        data.type === "" && data.step === "launching"
      ){
        if(modalRef.current) modalRef.current.openModal()
      }
    }

    return function cleanupListener() {
      ws.removeEventListener('message', listen)
    }

  },[ws,userReducer.team])


  function openModal(){
    if(userReducer.team && !isLeader) return;
    isLeader && editUserTeam(dispatch,{ step : "launching"}, userReducer.team._id)
    modalRef.current.openModal()
  }

  return (
    <div className={styles["launch"]}>
      <ModalHurryUp ref={modalHurryUpRef} />
      { (userReducer.team && emailLeader) &&
        <Modal defaultState={false} ref={modalRef}>
          <div className={styles["content-modal"]}>
            <h2>Ready to go ?</h2>
            <p><b>IMPORTANT : Pour communiquer, le leader doit créer une conversation Teams avec tous les membres de l’équipe.</b></p>
            { isLeader ?
              <>
                <p>
                  Vous êtes le leader de l'équipe ? Vous seul.e pouvez valider les choix et les étapes. Vos coéquipiers sont synchronisés et suivent le même parcours que vous.
                </p>
                <p>
                  <b>
                    L'équipe peut voter pour un autre leader<br/> à tout moment du jeu !
                  </b>
                </p>
                <p>
                  Une fois lancé.e, plus de retour en arrière possible et impossible de rejoindre l'équipe au milieu du jeu.
                </p>
                <button className={"primary"} onClick={() => modalRef.current.closeModal()}>Annuler</button>
                <button className={"secondary"} onClick={startGame}>C'est parti !</button>
              </> :
              <>
                <p>
                  <span className={styles["email-leader"]}>{emailLeader.replace(/@(.*)$/,"")}</span> est le leader de l'équipe. C'est donc lui qui valide les choix et les étapes. Vous êtes synchronisé.es et suivez le même parcours que tous vos coéquipiers. Vous ne pouvez pas valider les choix mais pouvez consulter  comme vous le souhaitez les différentes informations qui vous aideront à réussir votre mission.
                </p>
                <p>
                  Bon jeu !
                </p>
              </>
            }

          </div>
        </Modal>
      }

      <div className={showJoinTeam ? `${styles["content-video"]} ${styles["min"]}` : `${styles["content-video"]}`}>
        <iframe src="https://www.creacast.com/play.php?su=videlio-paris-ch6" width="100%" height="100%" scrolling="no" frameBorder="0" allowFullScreen></iframe>
        <iframe src="https://app.sli.do/event/hkckcyj9" height="100%" width="400px" frameBorder="0"></iframe>
      </div>

      { showJoinTeam &&

        <animated.div style={props} className={styles["content"]}>

          <div className={styles["container-intro"]}>
            <h1>UtopiK</h1>
            <h3>ET SI NOUS RÊVIONS DE DEMAIN ?<br/>UNE VIE NOUVELLE POUR 2121</h3>
            <p>
              Un univers futuriste voire surréaliste. Un monde utopique, fruit de votre imagination sans limite.
            </p>
            <p>
              <b>
                Rejoignez ou créez votre équipe de 6 personnes maxi pour participer ensemble à cette expérience créative.
                Vous utiliserez Teams pour échanger avec l'ensemble de l'équipe.
              </b>
            </p>
            <p>
              Prenez le temps de bien lire les instructions. Faites le grand saut !
            </p>
            <p>Rappelez-vous, vous pouvez changer de leader à tout moment !</p>
          </div>
          <div className={styles["container-button"]}>
            <NavLink to="/teams"><button>Créer / Rejoindre une équipe</button></NavLink>
            <button className={"secondary"} style={(userReducer.team && isLeader) ? {} : {pointerEvents : "none"}} onClick={() => openModal()}>
              {(userReducer.team && isLeader) ?
                "Lancer le jeu" : "Le leader de votre équipe lance le jeu"
              }
            </button>
          </div>
        </animated.div>
      }
    </div>
  )
}
