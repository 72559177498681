import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect, Link, useParams, useHistory } from 'react-router-dom'
import styles from './problematic.module.scss'
import iconClose from '../../../assets/images/close-modal.svg'

import {
  actions,
  situations,
  purposes
} from '../../../config/problematic'

import { editUserTeam } from '../../../actions/user'
import { getPathCurrentStep } from '../../../helpers'
import useHurryUp from '../../../hooks/useHurryUp'
import usePrevious from '../../../hooks/usePrevious'

import video from '../../../assets/videos/lancement_jeu_compressed.mp4'

import ModalHurryUp from '../../../components/ModalHurryUp'

export default function Problematic({isLeader}){
  const userReducer = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const modalRef = useRef()
  const params = useParams()
  const history = useHistory()
  const [ problematic, setProblematic ] = useState()
  const [ isModalOpen, setIsModalOpen ] = useState(null)
  const [ answers , setAnswers ] = useState({ action : null, creation : null, world : null})
  const prevLeader = usePrevious(isLeader)

  // useHurryUp(userReducer.team,10,modalRef,"problematic")
  useHurryUp(userReducer.team,5,modalRef,"problematic")

  // KEEP PROBLEMATIC UP TO DATE
  // USE A TIMESTAMP TO DETECT EVEN NESTED CHANGE
  useEffect(()=>{
    if(!userReducer.team.problematic) return;
    setProblematic(userReducer.team.problematic)
    setAnswers({
      action : actions.find(action => action.match === userReducer.team.problematic.action),
      creation : purposes.find(purpose => purpose.match === userReducer.team.problematic.creation),
      world : situations.find(situation => situation.match === userReducer.team.problematic.world),
    })
  },[userReducer.lastUpdate])

  useEffect(()=>{
    if(history.location.pathname === "/game/problematic/action"){
      setIsModalOpen(true)
    }
  },[])

  useEffect(()=>{
    if(isLeader || !userReducer.team.problematic) return;
    let path;
    switch (userReducer.team.problematic.step) {
      case "action":
        path = "/game/problematic/action"
        break;
      case "world":
        path = "/game/problematic/world"
        break;
      case "creation":
        path = "/game/problematic/creation"
        break;
      case "result":
        path = "/game/problematic/result"
        break;
    }

    if(/problematic/.test(history.location.pathname)){
      history.location.pathname !== path && history.push(path)
    }

  },[userReducer.lastUpdate])

  //INIT
  useEffect(()=>{
    if(!userReducer.team.problematic) return;
    setAnswers({
      action : actions.find(action => action.match === userReducer.team.problematic.action),
      creation : purposes.find(purpose => purpose.match === userReducer.team.problematic.creation),
      world : situations.find(situation => situation.match === userReducer.team.problematic.world),
    })
  },[])

  useEffect(()=>{
    if(!isLeader) return

    // RETURN IF THIS USE EFFECT IT TRIGGER ON LEADER CHANGE
    if(prevLeader === false && isLeader) return;

    let step;
    switch (history.location.pathname) {
      case "/game/problematic/action":
        step = "action"
        break;
      case "/game/problematic/world":
        step = "world"
        break;
      case "/game/problematic/creation":
        step = "creation"
        break;
      case "/game/problematic/result":
        step = "result"
        break;
    }

    let updatedProblematic;
    if(!problematic){
      updatedProblematic = { ...userReducer.team.problematic, step}
    }else{
      updatedProblematic = { ...problematic, step}
    }

    //DETECT IF WE OPEN PROBLEMATIC STEP FOR THE FIRST TIME
    if(userReducer.team.step === "launching"){
      editUserTeam(dispatch, { problematic : updatedProblematic, step : "problematic", stepStart : new Date() } ,userReducer.team._id)
    }else if(userReducer.team.step === "problematic"){
      editUserTeam(dispatch, { problematic : updatedProblematic, step : "problematic" } ,userReducer.team._id)
    }

  },[isLeader,history.location.pathname])

  function handleAnswers(key,value){
    if(!isLeader) return;
    let updatedProblematic = { ...userReducer.team.problematic, [key] : value.match}
    editUserTeam(dispatch, { problematic : updatedProblematic, step : 'problematic' } ,userReducer.team._id)
    setAnswers({...answers, [key] : value})
  }

  return (
    <div className={styles["problematic"]}>
      <ModalHurryUp ref={modalRef}/>
      <div className={styles["background"]}>
      </div>
      <div className={styles["global"]}>
        <div className={styles["container-title"]}>
          <h1>Diagnostic</h1>
          { params[0] !== "result" &&
            <p>
              {`${["action","world","creation"].indexOf(params[0])+1}/3`}
            </p>
          }
        </div>
        <Switch>
          <Route exact path='/game/problematic/action'>
            <div className={styles["container-step"]}>
              <p>Nous devons diagnostiquer vos carences.<br/>Pour nous aider, pouvez-vous nous dire ce qui vous a manqué le plus ces derniers mois ?</p>
              <p>
                <b>Attention, une seule et même réponse pour toute l’équipe. Seul le leader à la main !</b>
              </p>
              <div className={isLeader ? styles["container-answers"] : `${styles["container-answers"]} ${styles["disabled"]}`}>
                { actions.map((action,i) => <button className={answers.action?.value === action.value ? "selected" : ""} key={`action-${i}`} onClick={() => handleAnswers('action',action) }>{action.value}</button>)}
                {(isLeader && answers.action) && <Link to="/game/problematic/world"><button className="secondary">Valider</button></Link>}
              </div>
            </div>
          </Route>
          <Route exact path='/game/problematic/world'>
            <div className={styles["container-step"]}>
              <p>
                Nous allons tenter de définir les ressources nécessaires à votre régénération :<br/>
                Dans quelle situation vous projetez vous le plus facilement ?
              </p>
              <p>
                <b>Attention, une seule et même réponse pour toute l’équipe. Seul le leader à la main !</b>
              </p>
              <div className={styles["container-answers"]}>
                { situations.map((situation,i) =>
                  <button className={answers.world?.value === situation.value ? "selected" : ""} key={`situation-${i}`} onClick={() => handleAnswers('world',situation) } >
                    <span dangerouslySetInnerHTML={{__html: situation.value} } />
                  </button>)
                }
              </div>
              {(isLeader && answers.world) && <Link to="/game/problematic/creation"><button className="secondary">Valider</button></Link>}
            </div>
          </Route>
          <Route exact path='/game/problematic/creation'>
            <div className={styles["container-step"]}>
              <p>
                Pour trouver rapidement votre place au sein de votre communauté,<br/>
                nous devons définir quel sera votre rôle parmi vos paires...
              </p>
              <p>
              N’oubliez pas, vous devrez inventer des solutions nouvelles pour contribuer à un mode de vie vertueux et durable...
              </p>
              <p>
                Choisissez votre objectif :
              </p>
              <p>
                <b>Attention, une seule et même réponse pour toute l’équipe. Seul le leader à la main !</b>
              </p>
              <div className={styles["container-answers"]}>
                { purposes.map((purpose,i) => <button className={answers.creation?.value === purpose.value ? "selected" : ""} key={`purpose-${i}`} onClick={() => handleAnswers('creation',purpose) }>{purpose.value}</button>)}
              </div>
              {(isLeader && answers.creation) && <Link to="/game/problematic/result"><button className="secondary">Valider</button></Link>}
            </div>
          </Route>

          { (Object.values(answers).filter(Boolean).length < 3 && (userReducer.team.problematic && userReducer.team.problematic.step !== "result")) &&
            <Redirect to="/game/problematic/action" />
          }

          <Route exact path='/game/problematic/result'>
            <div className={styles["container-result"]}>
              <label>Résultat :</label>
              <p style={{fontSize:40}}>
                Imaginez, rêvez, inventez un(e) <span>{answers.creation?.match} </span><br/>
                qui permette de <span>{answers.action?.match}</span> dans une communauté vivant <span>{answers.world?.match}</span>
              </p>
              <p style={{fontSize:18, textAlign:'left'}}>
                <b>Une dernière précision avant de commencer.</b><br/>
                La communauté que vous allez rejoindre a su s’adapter à son nouveau milieu et se développer <b>sans l’aide du numérique</b> et <b>sans énergie fossile.</b> Au fil du temps, elle a créé les bases d’une vie harmonieuse et apaisée, en osmose avec la nature qui l’entoure.<br/>
                Mais il reste encore beaucoup à faire !
              </p>
              { isLeader &&
                <div className={styles["container-button"]}>
                  <Link to="/game/idea"><button className="secondary" style={{marginBottom:10}}>Accepter cette mission</button></Link>
                  <Link to="/game/problematic/action"><button>Repasser le diagnostic</button></Link>
                </div>
              }
            </div>
          </Route>
        </Switch>
      </div>
      { isModalOpen &&
        <div className={styles["content-modal"]}>
          <video controls onEnded={() => setIsModalOpen(false)}>
            <source src={video}
            type="video/mp4"/>
            Sorry, your browser doesn't support embedded videos.
          </video>
          <div className={styles["close"]} onClick={() => setIsModalOpen(false)}>
            <img src={iconClose} alt="close" />
          </div>
        </div>
      }
      { history.location.pathname === "/game/problematic/action" &&
        <label onClick={() => setIsModalOpen(true)} className={styles["button-modal-video"]}>Revoir la video d'intro</label>
      }
    </div>
  )
}
