import {
  SIGNIN_USER,
  GET_USER,
  USER_VOTE,
  PITCH_LOADING,
  CREATE_TEAM,
  SET_USER_TEAM,
  UPDATE_USER_TEAM,
  USER_JOIN_TEAM,
  USER_LEAVE_TEAM,
  LOADING_USER,
  ERROR_USER
} from '../actions/types'

const initialState = {
  user : null,
  team : null,
  emailSended : false,
  isLoading : false,
  error : null,
  pitchLoading : 54,
  lastUpdate : Date.now()
}

let updatedTeam;
let updatedUser;
let updatedUsers;

const user = (state = initialState, action) => {
    switch(action.type){
      case SIGNIN_USER :
        return { ...state, emailSended : action.payload, isLoading : false }
      case GET_USER :
        updatedUser = Object.assign({},action.payload.user)
        delete updatedUser.createdAt
        delete updatedUser.updatedAt
        return { ...state, user : updatedUser, team : action.payload.team, isLoading : false }
      case CREATE_TEAM :

        updatedTeam = action.payload
        updatedTeam.votes = { [state.user.email] : 1}
        updatedUser = { ...state.user, team : action.payload._id, leaderVote : state.user._id }

        return { ...state, team : updatedTeam, user : updatedUser, isLoading : false }
      case USER_VOTE :
        updatedUser = Object.assign({},action.payload.user)
        delete updatedUser.createdAt
        delete updatedUser.updatedAt
        return { ...state, user : updatedUser, team : action.payload.team, isLoading : false }
      case SET_USER_TEAM :
        let obj = Object.assign({},action.payload)
        delete obj.teamId
        if(action.typeAction === "vote change"){
          updatedTeam = { ...state.team, votes : obj.votes }
        }else if(obj.type === "leave team"){

          updatedUsers = state.team.users.filter(user => user._id !== obj.userId)
          updatedTeam = { ...state.team, users : updatedUsers}
          let removedUser = state.team.users.find(user => user._id === obj.userId)

          // REMOVE VOTES FOR THE USER WHO LEAVE THE TEAM
          let votes = Object.assign({},updatedTeam.votes)
          if(removedUser) delete votes[removedUser.email]

          updatedTeam = { ...updatedTeam, votes}
        }else if(obj.type === "join team"){
          updatedTeam = { ...state.team, users : [ ...state.team.users, {email : obj.action, _id : obj.userId} ]}
        }else{
          updatedTeam = { ...state.team, [obj.step] : obj.action[obj.step], step : obj.step, stepStart : obj.stepStart}
        }
        return { ...state, team : updatedTeam, lastUpdate : Date.now() }
      case PITCH_LOADING :
        return { ...state, pitchLoading : action.payload }
      case UPDATE_USER_TEAM :
        return { ...state, team : action.payload, lastUpdate : Date.now() , isLoading : false}
      case USER_LEAVE_TEAM :
        updatedUser = Object.assign({},action.payload.user)
        delete updatedUser.createdAt
        delete updatedUser.updatedAt
        return { ...state, user : updatedUser, team : null, isLoading : false }
      case USER_JOIN_TEAM :
        updatedUser = Object.assign({},action.payload.user)
        delete updatedUser.createdAt
        delete updatedUser.updatedAt
        return { ...state, user : updatedUser, team : action.payload.team, isLoading : false }
      case LOADING_USER :
        return { ...state, isLoading : true}
      case ERROR_USER :
        return { ...state, error : action.payload, isLoading : false }
      default:
          return state
    }
}

export default user
