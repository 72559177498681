// style={{enableBackground : "new 0 0 100 100"}}
export default function Back0 ({color}){
  return (
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="100%" height="100%"
    	 viewBox={`0 0 100 100`} xmlSpace="preserve">
    <g id="XMLID_15_">
    	<path style={{fill : color}}  id="XMLID_960_" d="M49.79,1.12C22.78,1.12,0.8,23.1,0.8,50.11c0,27.01,21.97,48.98,48.98,48.98
    		c27.01,0,48.98-21.97,48.98-48.98C98.77,23.1,76.8,1.12,49.79,1.12z M1.66,50.11c0-26.54,21.59-48.13,48.13-48.13
    		c26.54,0,48.13,21.59,48.13,48.13c0,26.54-21.59,48.13-48.13,48.13C23.25,98.23,1.66,76.64,1.66,50.11z"/>
    	<path style={{fill : color}}  id="XMLID_968_" d="M92.59,49.79c0-23.34-18.99-42.32-42.32-42.32c-23.34,0-42.32,18.99-42.32,42.32s18.99,42.32,42.32,42.32
    		C73.61,92.11,92.59,73.12,92.59,49.79z M8.8,49.79c0-22.87,18.6-41.47,41.47-41.47c22.87,0,41.47,18.6,41.47,41.47
    		s-18.6,41.47-41.47,41.47C27.4,91.25,8.8,72.65,8.8,49.79z"/>
    	<path style={{fill : color}}  id="XMLID_969_" d="M59.2,93.58c-0.46,0.09-0.76,0.55-0.66,1.02c0.09,0.39,0.45,0.68,0.84,0.68c0.06,0,0.12-0.01,0.18-0.03
    		c0.46-0.09,0.75-0.56,0.66-1.01C60.12,93.77,59.66,93.48,59.2,93.58z"/>
    	<path style={{fill : color}}  id="XMLID_970_" d="M56.54,6.15c0.04,0,0.09,0.01,0.13,0.01c0.42,0,0.78-0.31,0.85-0.73c0.07-0.47-0.25-0.91-0.72-0.98
    		c-0.46-0.07-0.9,0.25-0.98,0.72C55.75,5.64,56.08,6.08,56.54,6.15z"/>
    	<path style={{fill : color}}  id="XMLID_971_" d="M17,19.67c0.22,0,0.45-0.09,0.62-0.27c0.33-0.34,0.32-0.89-0.03-1.22c-0.34-0.33-0.88-0.31-1.21,0.03
    		c-0.33,0.33-0.32,0.88,0.03,1.21C16.57,19.59,16.78,19.67,17,19.67z"/>
    	<path style={{fill : color}}  id="XMLID_972_" d="M61.46,7.19c0.08,0.02,0.15,0.03,0.23,0.03c0.38,0,0.72-0.25,0.82-0.63c0.12-0.46-0.15-0.93-0.6-1.05
    		c-0.46-0.13-0.93,0.15-1.05,0.6C60.74,6.6,61.01,7.06,61.46,7.19z"/>
    	<path style={{fill : color}}  id="XMLID_973_" d="M54.05,5.84c0.03,0,0.05,0,0.08,0c0.44,0,0.81-0.33,0.86-0.77c0.04-0.47-0.3-0.89-0.77-0.93
    		c-0.47-0.05-0.89,0.3-0.93,0.77C53.23,5.38,53.58,5.79,54.05,5.84z"/>
    	<path style={{fill : color}}  id="XMLID_974_" d="M49.01,5.65L49.01,5.65c0.49-0.01,0.87-0.4,0.86-0.87c-0.01-0.47-0.4-0.85-0.87-0.84
    		c-0.47,0.01-0.85,0.39-0.84,0.87C48.15,5.27,48.54,5.65,49.01,5.65z"/>
    	<path style={{fill : color}}  id="XMLID_975_" d="M51.54,5.67c0.01,0,0.03,0,0.03,0c0.45,0,0.84-0.36,0.86-0.82c0.02-0.47-0.35-0.87-0.82-0.89
    		c-0.48-0.02-0.87,0.35-0.89,0.82C50.7,5.25,51.07,5.66,51.54,5.67z"/>
    	<path style={{fill : color}}  id="XMLID_976_" d="M63.87,7.92c0.09,0.03,0.18,0.04,0.27,0.04c0.36,0,0.69-0.22,0.81-0.58c0.15-0.45-0.09-0.93-0.55-1.09
    		c0.01,0,0.01,0,0.01,0c-0.45-0.15-0.94,0.09-1.09,0.54C63.17,7.28,63.42,7.76,63.87,7.92z"/>
    	<path style={{fill : color}}  id="XMLID_977_" d="M75.58,13.69c0.27,0,0.54-0.13,0.7-0.37c0.27-0.39,0.17-0.93-0.21-1.19c-0.39-0.27-0.93-0.18-1.19,0.21
    		c-0.27,0.39-0.17,0.93,0.21,1.19C75.24,13.64,75.41,13.69,75.58,13.69z"/>
    	<path style={{fill : color}}  id="XMLID_978_" d="M72.98,12.16c0.14,0.09,0.29,0.13,0.45,0.13c0.29,0,0.57-0.15,0.73-0.4c0.25-0.41,0.13-0.93-0.28-1.18
    		c-0.4-0.25-0.93-0.12-1.17,0.28C72.45,11.39,72.58,11.92,72.98,12.16z"/>
    	<path style={{fill : color}}  id="XMLID_979_" d="M77.65,15.21c0.26,0,0.51-0.11,0.68-0.33c0.29-0.38,0.22-0.92-0.15-1.2c-0.38-0.3-0.92-0.23-1.21,0.15
    		c-0.29,0.37-0.22,0.91,0.15,1.2C77.28,15.15,77.46,15.21,77.65,15.21z"/>
    	<path style={{fill : color}}  id="XMLID_980_" d="M68.55,9.78c0.12,0.06,0.24,0.09,0.37,0.09c0.32,0,0.63-0.19,0.77-0.5c0.2-0.43,0.02-0.94-0.41-1.14
    		c-0.43-0.2-0.94-0.02-1.14,0.42C67.94,9.08,68.12,9.58,68.55,9.78z"/>
    	<path style={{fill : color}}  id="XMLID_981_" d="M70.8,10.91c0.13,0.07,0.27,0.1,0.4,0.1c0.31,0,0.61-0.16,0.75-0.45c0.23-0.41,0.07-0.93-0.34-1.16
    		c-0.42-0.22-0.94-0.07-1.17,0.35C70.23,10.16,70.38,10.69,70.8,10.91z"/>
    	<path style={{fill : color}}  id="XMLID_982_" d="M66.23,8.78c0.11,0.04,0.21,0.06,0.32,0.06c0.34,0,0.67-0.21,0.8-0.54c0.18-0.44-0.03-0.94-0.48-1.11
    		c-0.44-0.17-0.93,0.04-1.11,0.48C65.58,8.11,65.8,8.61,66.23,8.78z"/>
    	<path style={{fill : color}}  id="XMLID_983_" d="M18.82,17.87c0.21,0,0.42-0.08,0.58-0.23c0.34-0.33,0.36-0.87,0.04-1.21c-0.33-0.35-0.87-0.37-1.22-0.04
    		c-0.34,0.33-0.36,0.87-0.03,1.22C18.36,17.77,18.59,17.87,18.82,17.87z"/>
    	<path style={{fill : color}}  id="XMLID_984_" d="M22.76,14.58c0.17,0,0.35-0.06,0.51-0.17c0.39-0.28,0.46-0.82,0.18-1.2c-0.28-0.38-0.82-0.46-1.2-0.18
    		c-0.38,0.28-0.46,0.82-0.18,1.2C22.23,14.46,22.49,14.58,22.76,14.58z"/>
    	<path style={{fill : color}}  id="XMLID_985_" d="M24.85,13.11c0.16,0,0.33-0.04,0.48-0.15c0.39-0.26,0.5-0.79,0.24-1.18c-0.26-0.39-0.8-0.51-1.19-0.24
    		c-0.39,0.26-0.51,0.79-0.24,1.18C24.3,12.97,24.57,13.11,24.85,13.11z"/>
    	<path style={{fill : color}}  id="XMLID_986_" d="M27.02,11.76c0.15,0,0.3-0.03,0.44-0.11c0.4-0.24,0.55-0.76,0.31-1.17c-0.24-0.41-0.76-0.55-1.17-0.31
    		c-0.41,0.24-0.55,0.76-0.31,1.17C26.45,11.61,26.73,11.76,27.02,11.76z"/>
    	<path style={{fill : color}}  id="XMLID_987_" d="M79.63,16.85c0.24,0,0.47-0.1,0.64-0.29c0.31-0.36,0.27-0.9-0.09-1.21c0.01,0,0.01,0,0.01,0
    		c-0.36-0.32-0.9-0.27-1.22,0.08c-0.31,0.36-0.27,0.9,0.09,1.21C79.22,16.78,79.42,16.85,79.63,16.85z"/>
    	<path style={{fill : color}}  id="XMLID_988_" d="M84.39,78.04L84.39,78.04c-0.3,0.36-0.24,0.9,0.13,1.2c0.15,0.13,0.34,0.19,0.53,0.19
    		c0.25,0,0.5-0.1,0.67-0.32c0.3-0.37,0.24-0.91-0.13-1.2C85.22,77.62,84.68,77.67,84.39,78.04z"/>
    	<path style={{fill : color}}  id="XMLID_989_" d="M20.74,16.17c0.19,0,0.39-0.07,0.55-0.2c0.36-0.31,0.41-0.85,0.11-1.21c-0.31-0.37-0.85-0.41-1.21-0.11
    		c-0.37,0.31-0.41,0.85-0.11,1.21C20.25,16.07,20.49,16.17,20.74,16.17z"/>
    	<path style={{fill : color}}  id="XMLID_990_" d="M43.9,6.02c0.03,0,0.08,0,0.11,0c0.47-0.07,0.8-0.5,0.74-0.96c-0.06-0.47-0.49-0.81-0.96-0.75
    		c-0.47,0.06-0.8,0.5-0.74,0.96C43.1,5.71,43.47,6.02,43.9,6.02z"/>
    	<path style={{fill : color}}  id="XMLID_991_" d="M46.45,5.76c0.02,0,0.03,0,0.06,0c0.47-0.03,0.82-0.45,0.79-0.92s-0.45-0.83-0.92-0.79
    		c-0.47,0.03-0.82,0.45-0.79,0.92C45.63,5.42,46,5.76,46.45,5.76z"/>
    	<path style={{fill : color}}  id="XMLID_992_" d="M41.36,6.42c0.05,0,0.11,0,0.16-0.01c0.46-0.09,0.77-0.54,0.69-1s-0.54-0.77-1-0.69
    		c-0.46,0.09-0.77,0.53-0.69,1C40.6,6.14,40.96,6.42,41.36,6.42z"/>
    	<path style={{fill : color}}  id="XMLID_993_" d="M33.96,8.48c0.09,0,0.2-0.02,0.3-0.06c0.44-0.16,0.67-0.66,0.5-1.1c-0.16-0.45-0.65-0.67-1.1-0.51
    		c-0.45,0.16-0.67,0.66-0.51,1.1C33.29,8.27,33.61,8.48,33.96,8.48z"/>
    	<path style={{fill : color}}  id="XMLID_994_" d="M38.86,6.97c0.07,0,0.14-0.01,0.21-0.03c0.46-0.11,0.75-0.58,0.63-1.04c-0.11-0.46-0.57-0.74-1.04-0.63
    		c-0.46,0.11-0.74,0.57-0.63,1.04C38.12,6.71,38.47,6.97,38.86,6.97z"/>
    	<path style={{fill : color}}  id="XMLID_995_" d="M31.59,9.44c0.11,0,0.23-0.03,0.34-0.08c0.44-0.19,0.63-0.69,0.45-1.13c-0.2-0.43-0.7-0.63-1.13-0.44
    		c-0.44,0.19-0.63,0.69-0.45,1.13C30.95,9.25,31.26,9.44,31.59,9.44z"/>
    	<path style={{fill : color}}  id="XMLID_996_" d="M59.02,6.6c0.06,0.02,0.12,0.02,0.18,0.02c0.39,0,0.75-0.27,0.84-0.68c0.09-0.46-0.21-0.92-0.67-1.02
    		c-0.46-0.09-0.92,0.21-1.01,0.67C58.26,6.05,58.56,6.5,59.02,6.6z"/>
    	<path style={{fill : color}}  id="XMLID_997_" d="M93.56,61.93c-0.45-0.13-0.93,0.13-1.06,0.58c-0.13,0.45,0.13,0.93,0.58,1.06
    		c0.09,0.03,0.16,0.03,0.24,0.03c0.38,0,0.72-0.24,0.82-0.62C94.28,62.54,94.01,62.06,93.56,61.93z"/>
    	<path style={{fill : color}}  id="XMLID_998_" d="M94.16,59.44c-0.46-0.1-0.93,0.18-1.03,0.64c-0.11,0.46,0.18,0.93,0.64,1.03
    		c0.06,0.02,0.13,0.03,0.19,0.03c0.39,0,0.75-0.27,0.84-0.67C94.9,60.01,94.62,59.54,94.16,59.44z"/>
    	<path style={{fill : color}}  id="XMLID_999_" d="M94.92,54.38c-0.47-0.06-0.9,0.28-0.95,0.75c-0.05,0.47,0.29,0.89,0.76,0.94
    		c0.03,0.01,0.06,0.01,0.09,0.01c0.43,0,0.8-0.33,0.85-0.76C95.73,54.85,95.39,54.43,94.92,54.38z"/>
    	<path style={{fill : color}}  id="XMLID_1000_" d="M94.61,56.92c-0.47-0.09-0.91,0.23-0.99,0.69c-0.08,0.47,0.24,0.91,0.7,0.99
    		c0.05,0.01,0.09,0.01,0.15,0.01c0.41,0,0.77-0.29,0.84-0.71C95.39,57.44,95.07,57,94.61,56.92z"/>
    	<path style={{fill : color}}  id="XMLID_1001_" d="M94.97,46.5c-0.47,0.03-0.83,0.44-0.8,0.91c0.03,0.45,0.4,0.81,0.85,0.81c0.02,0,0.03,0,0.05,0
    		c0.48-0.03,0.83-0.44,0.81-0.91C95.85,46.84,95.45,46.48,94.97,46.5z"/>
    	<path style={{fill : color}}  id="XMLID_1002_" d="M95.08,51.82c-0.47-0.03-0.87,0.33-0.9,0.81c-0.03,0.47,0.33,0.88,0.81,0.91c0.02,0,0.03,0,0.05,0
    		c0.45,0,0.82-0.35,0.86-0.81C95.92,52.24,95.55,51.84,95.08,51.82z"/>
    	<path style={{fill : color}}  id="XMLID_1003_" d="M95.11,49.06c-0.48,0-0.86,0.39-0.86,0.86c0,0.03,0,0.06,0.01,0.09c-0.01,0.03-0.01,0.06-0.01,0.09
    		c0,0.47,0.39,0.86,0.86,0.86c0.47,0,0.86-0.39,0.86-0.86c0-0.03,0-0.07-0.01-0.09c0.01-0.03,0.01-0.07,0.01-0.1
    		C95.97,49.44,95.58,49.06,95.11,49.06z"/>
    	<path style={{fill : color}}  id="XMLID_1004_" d="M92.82,64.39c-0.45-0.16-0.93,0.07-1.1,0.52c-0.15,0.45,0.08,0.93,0.52,1.09c0.1,0.03,0.2,0.05,0.29,0.05
    		c0.35,0,0.69-0.22,0.81-0.57C93.5,65.04,93.27,64.55,92.82,64.39z"/>
    	<path style={{fill : color}}  id="XMLID_1005_" d="M85.92,76.04c-0.27,0.39-0.19,0.92,0.2,1.19c0.15,0.11,0.33,0.16,0.5,0.16c0.27,0,0.53-0.12,0.69-0.35
    		c0.27-0.39,0.19-0.93-0.2-1.2C86.73,75.57,86.19,75.65,85.92,76.04z"/>
    	<path style={{fill : color}}  id="XMLID_1006_" d="M89.78,71.46c-0.41-0.23-0.93-0.09-1.17,0.33c-0.23,0.42-0.09,0.93,0.33,1.17
    		c0.13,0.07,0.27,0.1,0.41,0.1c0.3,0,0.59-0.15,0.75-0.44C90.35,72.22,90.19,71.69,89.78,71.46z"/>
    	<path style={{fill : color}}  id="XMLID_1007_" d="M88.51,73.69c-0.4-0.26-0.93-0.14-1.18,0.27c-0.26,0.39-0.14,0.93,0.26,1.18
    		c0.15,0.09,0.3,0.13,0.46,0.13c0.28,0,0.56-0.14,0.72-0.39C89.03,74.47,88.91,73.95,88.51,73.69z"/>
    	<path style={{fill : color}}  id="XMLID_1008_" d="M91.94,66.8c-0.43-0.18-0.93,0.03-1.11,0.46c-0.19,0.44,0.02,0.94,0.45,1.12
    		c0.11,0.04,0.22,0.07,0.33,0.07c0.33,0,0.65-0.2,0.79-0.53C92.58,67.49,92.38,66.99,91.94,66.8z"/>
    	<path style={{fill : color}}  id="XMLID_1009_" d="M90.93,69.17c-0.42-0.21-0.93-0.03-1.15,0.39c-0.21,0.43-0.03,0.93,0.39,1.15
    		c0.13,0.05,0.26,0.09,0.38,0.09c0.32,0,0.63-0.18,0.77-0.49C91.53,69.89,91.36,69.37,90.93,69.17z"/>
    	<path style={{fill : color}}  id="XMLID_1010_" d="M83.28,20.44c0.21,0,0.41-0.08,0.57-0.22c0.35-0.33,0.38-0.87,0.06-1.22c-0.32-0.34-0.87-0.37-1.21-0.05
    		c-0.35,0.32-0.38,0.86-0.06,1.21C82.82,20.34,83.05,20.44,83.28,20.44z"/>
    	<path style={{fill : color}}  id="XMLID_1011_" d="M87.24,26.14c0.16,0.25,0.44,0.39,0.72,0.39c0.16,0,0.32-0.05,0.46-0.14c0.39-0.26,0.51-0.79,0.26-1.18
    		c-0.26-0.4-0.79-0.52-1.18-0.27C87.1,25.2,86.99,25.73,87.24,26.14z"/>
    	<path style={{fill : color}}  id="XMLID_1012_" d="M88.54,28.29c0.15,0.27,0.45,0.44,0.75,0.44c0.15,0,0.29-0.04,0.42-0.11c0.41-0.24,0.56-0.76,0.33-1.17
    		c-0.23-0.41-0.75-0.56-1.17-0.32C88.45,27.35,88.31,27.87,88.54,28.29z"/>
    	<path style={{fill : color}}  id="XMLID_1013_" d="M85.82,24.05L85.82,24.05c0.17,0.23,0.44,0.35,0.7,0.35c0.17,0,0.34-0.05,0.5-0.15
    		c0.39-0.28,0.47-0.81,0.19-1.2c-0.27-0.39-0.81-0.47-1.19-0.19C85.63,23.14,85.55,23.67,85.82,24.05z"/>
    	<path style={{fill : color}}  id="XMLID_1014_" d="M81.5,18.59c0.22,0,0.45-0.09,0.62-0.26c0.33-0.33,0.33-0.87-0.02-1.21c-0.33-0.33-0.88-0.33-1.21,0.01
    		c-0.33,0.34-0.33,0.88,0.01,1.22C81.07,18.51,81.29,18.59,81.5,18.59z"/>
    	<path style={{fill : color}}  id="XMLID_1015_" d="M89.71,30.51c0.15,0.3,0.45,0.48,0.77,0.48c0.12,0,0.26-0.03,0.38-0.09c0.42-0.21,0.6-0.73,0.39-1.15
    		c-0.21-0.43-0.72-0.6-1.15-0.39C89.68,29.57,89.51,30.09,89.71,30.51z"/>
    	<path style={{fill : color}}  id="XMLID_1016_" d="M84.3,22.06L84.3,22.06c0.17,0.21,0.41,0.32,0.66,0.32c0.2,0,0.39-0.06,0.55-0.19
    		c0.36-0.3,0.42-0.84,0.12-1.21c-0.3-0.37-0.84-0.42-1.21-0.12C84.05,21.16,84,21.69,84.3,22.06z"/>
    	<path style={{fill : color}}  id="XMLID_1017_" d="M91.66,35.13c0.13,0.34,0.45,0.57,0.81,0.57c0.09,0,0.19-0.02,0.28-0.05c0.45-0.16,0.68-0.65,0.51-1.1
    		c-0.15-0.45-0.64-0.68-1.09-0.51C91.74,34.19,91.51,34.68,91.66,35.13z"/>
    	<path style={{fill : color}}  id="XMLID_1018_" d="M93.59,42.42c0.07,0.41,0.43,0.71,0.84,0.71c0.05,0,0.1-0.01,0.15-0.02c0.47-0.08,0.78-0.52,0.7-0.99
    		c-0.09-0.46-0.53-0.77-0.99-0.69C93.82,41.51,93.51,41.94,93.59,42.42z"/>
    	<path style={{fill : color}}  id="XMLID_1019_" d="M90.76,32.79c0.14,0.33,0.45,0.52,0.79,0.52c0.11,0,0.22-0.03,0.33-0.07c0.44-0.19,0.64-0.69,0.45-1.12
    		c-0.19-0.44-0.69-0.64-1.12-0.46C90.77,31.85,90.57,32.36,90.76,32.79z"/>
    	<path style={{fill : color}}  id="XMLID_1020_" d="M93.73,38.92c-0.46,0.11-0.75,0.57-0.64,1.03c0.09,0.39,0.45,0.66,0.83,0.66c0.07,0,0.14,0,0.2-0.02
    		c0.46-0.11,0.75-0.57,0.64-1.03C94.65,39.1,94.18,38.82,93.73,38.92z"/>
    	<path style={{fill : color}}  id="XMLID_1021_" d="M92.44,37.52c0.11,0.37,0.45,0.61,0.82,0.61c0.08,0,0.16-0.01,0.24-0.03c0.45-0.13,0.72-0.61,0.58-1.06
    		c-0.14-0.45-0.61-0.71-1.06-0.58C92.57,36.59,92.31,37.06,92.44,37.52z"/>
    	<path style={{fill : color}}  id="XMLID_1022_" d="M93.95,44.9c0.05,0.44,0.42,0.76,0.85,0.76c0.03,0,0.07,0,0.1-0.01c0.47-0.05,0.81-0.48,0.75-0.95
    		c-0.06-0.47-0.49-0.81-0.95-0.75C94.24,44.01,93.89,44.44,93.95,44.9z"/>
    	<path style={{fill : color}}  id="XMLID_1023_" d="M29.28,10.53c0.13,0,0.27-0.03,0.39-0.09c0.43-0.21,0.59-0.74,0.38-1.16c-0.21-0.42-0.73-0.59-1.15-0.38
    		c-0.43,0.21-0.59,0.74-0.38,1.16C28.66,10.36,28.97,10.53,29.28,10.53z"/>
    	<path style={{fill : color}}  id="XMLID_1024_" d="M46.69,94.47c-0.47-0.03-0.88,0.33-0.91,0.8c-0.03,0.47,0.32,0.87,0.8,0.91c-0.01,0-0.01,0-0.01,0
    		c0.03,0,0.04,0.01,0.06,0.01c0.45,0,0.82-0.35,0.86-0.81C47.52,94.91,47.16,94.5,46.69,94.47z"/>
    	<path style={{fill : color}}  id="XMLID_1025_" d="M36.82,92.65c-0.45-0.14-0.93,0.12-1.07,0.57c-0.14,0.45,0.12,0.93,0.57,1.07
    		c0.08,0.03,0.16,0.04,0.25,0.04c0.36,0,0.7-0.24,0.81-0.61C37.52,93.27,37.27,92.79,36.82,92.65z"/>
    	<path style={{fill : color}}  id="XMLID_1026_" d="M34.43,91.85c-0.45-0.16-0.93,0.07-1.1,0.51c-0.16,0.44,0.06,0.93,0.51,1.1c0.09,0.03,0.2,0.05,0.29,0.05
    		c0.35,0,0.68-0.21,0.81-0.56C35.1,92.51,34.87,92.01,34.43,91.85z"/>
    	<path style={{fill : color}}  id="XMLID_1027_" d="M44.19,94.22c-0.47-0.06-0.9,0.27-0.96,0.75c-0.06,0.47,0.27,0.9,0.75,0.96c0.03,0,0.07,0.01,0.11,0.01
    		c0.42,0,0.79-0.32,0.85-0.75C44.99,94.71,44.66,94.28,44.19,94.22z"/>
    	<path style={{fill : color}}  id="XMLID_1028_" d="M32.09,90.91c-0.44-0.19-0.94,0.01-1.13,0.45c-0.19,0.43,0.02,0.93,0.45,1.12
    		c0.11,0.05,0.23,0.08,0.34,0.08c0.33,0,0.65-0.2,0.79-0.52C32.72,91.61,32.52,91.1,32.09,90.91z"/>
    	<path style={{fill : color}}  id="XMLID_1029_" d="M41.7,93.84c-0.46-0.09-0.92,0.21-1,0.69c-0.09,0.46,0.22,0.91,0.69,0.99c0.05,0.01,0.1,0.02,0.15,0.02
    		c0.4,0,0.76-0.29,0.84-0.7C42.47,94.37,42.16,93.93,41.7,93.84z"/>
    	<path style={{fill : color}}  id="XMLID_1030_" d="M39.24,93.31c-0.46-0.11-0.93,0.17-1.04,0.63c-0.11,0.46,0.17,0.93,0.63,1.04
    		c0.07,0.02,0.14,0.03,0.2,0.03c0.39,0,0.74-0.27,0.83-0.66C39.98,93.89,39.7,93.42,39.24,93.31z"/>
    	<path style={{fill : color}}  id="XMLID_1031_" d="M20.19,84.45c-0.3,0.36-0.25,0.9,0.11,1.2c0.16,0.14,0.35,0.2,0.55,0.2c0.25,0,0.49-0.1,0.66-0.31
    		c0.3-0.36,0.25-0.9-0.11-1.21C21.03,84.03,20.49,84.08,20.19,84.45z"/>
    	<path style={{fill : color}}  id="XMLID_1032_" d="M29.81,89.84c-0.42-0.21-0.94-0.04-1.15,0.38c-0.21,0.43-0.05,0.94,0.38,1.16
    		c0.12,0.06,0.26,0.09,0.39,0.09c0.31,0,0.62-0.17,0.76-0.47C30.4,90.57,30.23,90.06,29.81,89.84z"/>
    	<path style={{fill : color}}  id="XMLID_1033_" d="M22.18,86.06c-0.28,0.39-0.2,0.93,0.18,1.21c0.15,0.11,0.33,0.16,0.51,0.16c0.27,0,0.52-0.12,0.69-0.34
    		c0.28-0.39,0.2-0.93-0.18-1.2C23,85.61,22.46,85.69,22.18,86.06z"/>
    	<path style={{fill : color}}  id="XMLID_1034_" d="M19.51,82.68c-0.34-0.33-0.89-0.31-1.21,0.04c-0.33,0.34-0.3,0.88,0.04,1.21
    		c0.16,0.15,0.38,0.23,0.58,0.23c0.23,0,0.46-0.09,0.63-0.27C19.88,83.54,19.86,83,19.51,82.68L19.51,82.68z"/>
    	<path style={{fill : color}}  id="XMLID_1035_" d="M27.59,88.64L27.59,88.64c-0.41-0.23-0.93-0.09-1.17,0.32c-0.24,0.41-0.09,0.93,0.31,1.17
    		c0.14,0.08,0.28,0.11,0.43,0.11c0.3,0,0.58-0.15,0.75-0.43C28.15,89.41,28,88.88,27.59,88.64z"/>
    	<path style={{fill : color}}  id="XMLID_1036_" d="M25.45,87.33c-0.39-0.27-0.93-0.15-1.18,0.24c-0.27,0.39-0.15,0.93,0.24,1.19
    		c0.15,0.09,0.31,0.14,0.47,0.14c0.28,0,0.56-0.14,0.72-0.39C25.95,88.12,25.84,87.59,25.45,87.33z"/>
    	<path style={{fill : color}}  id="XMLID_1037_" d="M56.72,94.03c-0.46,0.08-0.79,0.51-0.71,0.99c0.07,0.42,0.43,0.72,0.85,0.72c0.04,0,0.09,0,0.13-0.01
    		c0.47-0.08,0.79-0.51,0.72-0.98C57.63,94.28,57.19,93.96,56.72,94.03z"/>
    	<path style={{fill : color}}  id="XMLID_1038_" d="M73.11,87.97c-0.4,0.25-0.52,0.78-0.27,1.18c0.16,0.27,0.45,0.4,0.73,0.4c0.15,0,0.31-0.03,0.45-0.12
    		c0-0.01,0-0.01,0-0.01c0.39-0.25,0.52-0.77,0.27-1.17C74.05,87.85,73.52,87.72,73.11,87.97z"/>
    	<path style={{fill : color}}  id="XMLID_1039_" d="M70.95,89.23c-0.42,0.23-0.57,0.75-0.35,1.17c0.16,0.28,0.45,0.45,0.75,0.45c0.15,0,0.28-0.03,0.41-0.1
    		c0.42-0.22,0.57-0.75,0.34-1.16C71.88,89.16,71.36,89,70.95,89.23z"/>
    	<path style={{fill : color}}  id="XMLID_1040_" d="M66.4,91.37c-0.45,0.17-0.66,0.68-0.48,1.11c0.14,0.33,0.45,0.54,0.8,0.54c0.1,0,0.21-0.03,0.32-0.07
    		c0.45-0.17,0.65-0.68,0.48-1.11C67.34,91.4,66.84,91.19,66.4,91.37z"/>
    	<path style={{fill : color}}  id="XMLID_1041_" d="M68.7,90.37c-0.43,0.2-0.61,0.71-0.41,1.14c0.15,0.31,0.45,0.49,0.78,0.49c0.12,0,0.24-0.03,0.36-0.09
    		c0.43-0.2,0.62-0.71,0.41-1.14C69.64,90.34,69.13,90.16,68.7,90.37z"/>
    	<path style={{fill : color}}  id="XMLID_1042_" d="M77.23,85.1c-0.37,0.29-0.44,0.82-0.14,1.2c0.16,0.21,0.41,0.33,0.67,0.33c0.19,0,0.38-0.06,0.53-0.18
    		c0.37-0.29,0.44-0.83,0.15-1.21C78.15,84.86,77.61,84.8,77.23,85.1z"/>
    	<path style={{fill : color}}  id="XMLID_1043_" d="M75.22,86.59c-0.39,0.27-0.49,0.81-0.21,1.19c0.16,0.24,0.43,0.37,0.7,0.37c0.16,0,0.33-0.05,0.49-0.15
    		c0.39-0.27,0.48-0.81,0.21-1.19C76.14,86.42,75.61,86.32,75.22,86.59z"/>
    	<path style={{fill : color}}  id="XMLID_1044_" d="M49.21,94.57L49.21,94.57c-0.48-0.01-0.87,0.37-0.87,0.84c-0.01,0.48,0.38,0.87,0.85,0.87h0.01
    		c0.47,0,0.85-0.38,0.86-0.85C50.06,94.97,49.68,94.57,49.21,94.57z"/>
    	<path style={{fill : color}}  id="XMLID_1045_" d="M54.23,94.36c-0.47,0.04-0.81,0.46-0.77,0.93c0.05,0.45,0.42,0.77,0.86,0.77c0.03,0,0.06,0,0.09,0
    		c0.47-0.05,0.81-0.47,0.76-0.94C55.12,94.65,54.7,94.31,54.23,94.36z"/>
    	<path style={{fill : color}}  id="XMLID_1046_" d="M51.72,94.53c-0.47,0.03-0.84,0.42-0.81,0.9c0.02,0.45,0.39,0.81,0.85,0.81c0.02,0,0.03,0,0.04,0
    		c0.47-0.02,0.84-0.42,0.81-0.89C52.59,94.88,52.19,94.51,51.72,94.53z"/>
    	<path style={{fill : color}}  id="XMLID_1047_" d="M16.5,80.89c-0.33,0.33-0.35,0.87-0.02,1.21c0.16,0.18,0.39,0.27,0.62,0.27c0.21,0,0.43-0.08,0.59-0.24
    		c0.34-0.33,0.35-0.87,0.03-1.21C17.39,80.57,16.84,80.55,16.5,80.89z"/>
    	<path style={{fill : color}}  id="XMLID_1048_" d="M64.04,92.24c-0.45,0.15-0.69,0.63-0.53,1.08c0.12,0.36,0.45,0.58,0.81,0.58c0.09,0,0.19-0.01,0.27-0.04
    		c0.45-0.15,0.69-0.63,0.54-1.09C64.97,92.33,64.49,92.09,64.04,92.24z"/>
    	<path style={{fill : color}}  id="XMLID_1049_" d="M61.64,92.98c-0.46,0.12-0.73,0.6-0.6,1.05c0.1,0.38,0.45,0.63,0.82,0.63c0.08,0,0.15-0.01,0.23-0.03
    		c0.45-0.13,0.72-0.6,0.6-1.05C62.57,93.12,62.09,92.85,61.64,92.98z"/>
    	<path style={{fill : color}}  id="XMLID_1050_" d="M14.44,77.09c-0.29-0.38-0.82-0.45-1.2-0.15c-0.38,0.28-0.45,0.82-0.16,1.2
    		c0.17,0.21,0.42,0.33,0.68,0.33c0.19,0,0.37-0.06,0.52-0.18C14.66,78.01,14.73,77.47,14.44,77.09z"/>
    	<path style={{fill : color}}  id="XMLID_1051_" d="M5.68,39.61c0.07,0.02,0.14,0.03,0.21,0.03c0.38,0,0.73-0.26,0.83-0.64H6.72
    		c0.12-0.46-0.15-0.93-0.62-1.05c-0.45-0.12-0.92,0.15-1.04,0.62C4.94,39.02,5.22,39.49,5.68,39.61z"/>
    	<path style={{fill : color}}  id="XMLID_1052_" d="M4.45,49.78c0.01,0,0.02,0,0.03,0c0.46,0,0.84-0.37,0.86-0.84c0.01-0.47-0.36-0.87-0.84-0.87
    		c-0.47-0.02-0.87,0.36-0.87,0.83C3.61,49.36,3.98,49.76,4.45,49.78z"/>
    	<path style={{fill : color}}  id="XMLID_1053_" d="M5.16,42.12c0.05,0.01,0.11,0.02,0.16,0.02c0.4,0,0.76-0.28,0.84-0.69c0.09-0.46-0.21-0.92-0.67-1.01
    		c-0.47-0.09-0.92,0.21-1.01,0.68C4.39,41.58,4.69,42.03,5.16,42.12z"/>
    	<path style={{fill : color}}  id="XMLID_1054_" d="M4.78,44.66c0.04,0,0.08,0.01,0.12,0.01c0.42,0,0.79-0.32,0.85-0.75c0.06-0.46-0.27-0.9-0.74-0.97
    		c-0.46-0.06-0.9,0.27-0.96,0.74C3.98,44.16,4.31,44.59,4.78,44.66z"/>
    	<path style={{fill : color}}  id="XMLID_1055_" d="M7.14,34.7c0.1,0.03,0.21,0.05,0.3,0.05c0.35,0,0.68-0.21,0.81-0.55c0.17-0.44-0.05-0.93-0.5-1.1
    		c-0.44-0.17-0.93,0.05-1.11,0.5C6.48,34.03,6.7,34.52,7.14,34.7z"/>
    	<path style={{fill : color}}  id="XMLID_1056_" d="M4.55,47.21c0.02,0,0.04,0,0.07,0c0.44,0,0.81-0.33,0.85-0.79c0.04-0.47-0.31-0.88-0.78-0.93
    		c-0.47-0.03-0.88,0.32-0.93,0.79C3.73,46.76,4.08,47.17,4.55,47.21z"/>
    	<path style={{fill : color}}  id="XMLID_1057_" d="M6.34,37.14c0.09,0.03,0.17,0.03,0.26,0.03c0.37,0,0.7-0.23,0.82-0.59c0.15-0.45-0.11-0.93-0.56-1.08
    		c-0.45-0.15-0.93,0.1-1.08,0.56C5.64,36.5,5.88,36.99,6.34,37.14z"/>
    	<path style={{fill : color}}  id="XMLID_1058_" d="M11.7,25.51c0.15,0.1,0.31,0.15,0.47,0.15c0.28,0,0.55-0.13,0.72-0.38c0.26-0.39,0.15-0.93-0.24-1.18
    		c-0.39-0.27-0.92-0.16-1.18,0.23C11.21,24.71,11.31,25.25,11.7,25.51z"/>
    	<path style={{fill : color}}  id="XMLID_1059_" d="M13.67,23.57c0.26,0,0.51-0.12,0.69-0.34h-0.01c0.29-0.37,0.21-0.91-0.16-1.2
    		c-0.37-0.28-0.91-0.21-1.2,0.17c-0.28,0.37-0.21,0.91,0.16,1.2C13.31,23.51,13.49,23.57,13.67,23.57z"/>
    	<path style={{fill : color}}  id="XMLID_1060_" d="M8.09,32.3c0.11,0.05,0.23,0.08,0.35,0.08c0.33,0,0.63-0.19,0.78-0.51c0.19-0.44,0-0.94-0.43-1.14
    		c-0.44-0.19-0.94,0-1.13,0.44C7.46,31.6,7.65,32.11,8.09,32.3z"/>
    	<path style={{fill : color}}  id="XMLID_1061_" d="M10.37,27.7c0.14,0.09,0.29,0.12,0.44,0.12c0.29,0,0.57-0.15,0.74-0.42c0.24-0.4,0.11-0.93-0.3-1.17
    		c-0.4-0.24-0.93-0.1-1.17,0.3C9.83,26.94,9.96,27.46,10.37,27.7z"/>
    	<path style={{fill : color}}  id="XMLID_1062_" d="M9.16,29.97c0.13,0.06,0.27,0.09,0.39,0.09c0.32,0,0.61-0.17,0.76-0.46c0.22-0.42,0.06-0.93-0.36-1.16
    		c-0.42-0.21-0.94-0.05-1.16,0.37C8.58,29.24,8.74,29.75,9.16,29.97z"/>
    	<path style={{fill : color}}  id="XMLID_1063_" d="M6.15,62.44c0.46-0.12,0.74-0.59,0.62-1.05c-0.12-0.46-0.59-0.74-1.05-0.62
    		c-0.46,0.12-0.74,0.59-0.62,1.05c0.1,0.38,0.45,0.63,0.83,0.63C6,62.47,6.08,62.46,6.15,62.44z"/>
    	<path style={{fill : color}}  id="XMLID_1064_" d="M8.31,66.18c-0.17-0.44-0.67-0.66-1.11-0.49c-0.45,0.17-0.66,0.67-0.49,1.11
    		c0.13,0.33,0.45,0.54,0.8,0.54c0.1,0,0.21-0.02,0.31-0.06C8.27,67.12,8.48,66.62,8.31,66.18z"/>
    	<path style={{fill : color}}  id="XMLID_1065_" d="M11.62,72.94c-0.24-0.4-0.76-0.54-1.17-0.29c-0.4,0.24-0.54,0.76-0.29,1.17
    		c0.16,0.27,0.45,0.41,0.74,0.41c0.15,0,0.3-0.03,0.44-0.12C11.73,73.87,11.87,73.34,11.62,72.94z"/>
    	<path style={{fill : color}}  id="XMLID_1066_" d="M9.29,68.49c-0.2-0.43-0.7-0.62-1.14-0.42c-0.43,0.19-0.62,0.7-0.42,1.13c0.15,0.32,0.45,0.51,0.78,0.51
    		c0.12,0,0.24-0.03,0.35-0.08C9.29,69.43,9.48,68.93,9.29,68.49z"/>
    	<path style={{fill : color}}  id="XMLID_1067_" d="M10.39,70.74c-0.22-0.41-0.74-0.57-1.16-0.36c-0.42,0.22-0.58,0.75-0.36,1.16C9.03,71.83,9.32,72,9.63,72
    		c0.14,0,0.27-0.03,0.4-0.09C10.44,71.69,10.61,71.16,10.39,70.74z"/>
    	<path style={{fill : color}}  id="XMLID_1068_" d="M12.97,75.05c-0.27-0.39-0.8-0.49-1.19-0.22c-0.39,0.27-0.49,0.8-0.22,1.19c0.16,0.24,0.43,0.37,0.7,0.37
    		c0.17,0.01,0.33-0.04,0.49-0.15C13.13,75.98,13.24,75.45,12.97,75.05z"/>
    	<path style={{fill : color}}  id="XMLID_1069_" d="M15.28,21.58c0.24,0,0.48-0.1,0.65-0.3c0.3-0.36,0.26-0.9-0.09-1.21c-0.37-0.31-0.91-0.27-1.22,0.09
    		c-0.3,0.36-0.26,0.9,0.1,1.21C14.89,21.51,15.09,21.58,15.28,21.58z"/>
    	<path style={{fill : color}}  id="XMLID_1070_" d="M5.05,57.44c0.47-0.07,0.8-0.51,0.73-0.97C5.7,56,5.27,55.67,4.81,55.74c-0.47,0.07-0.8,0.5-0.73,0.97
    		c0.06,0.43,0.43,0.74,0.85,0.74C4.96,57.44,5,57.44,5.05,57.44z"/>
    	<path style={{fill : color}}  id="XMLID_1071_" d="M4.48,52.34c0.01,0,0.02,0,0.03,0c0.48-0.02,0.85-0.41,0.83-0.88c-0.01-0.47-0.4-0.85-0.87-0.83
    		c-0.48,0.02-0.85,0.41-0.83,0.88C3.64,51.97,4.03,52.34,4.48,52.34z"/>
    	<path style={{fill : color}}  id="XMLID_1072_" d="M4.7,54.89c0.47-0.03,0.82-0.45,0.78-0.93c-0.04-0.47-0.45-0.82-0.93-0.78
    		c-0.47,0.04-0.82,0.45-0.78,0.93c0.04,0.45,0.41,0.79,0.86,0.79C4.66,54.9,4.68,54.9,4.7,54.89z"/>
    	<path style={{fill : color}}  id="XMLID_1073_" d="M7.47,63.81c-0.15-0.45-0.63-0.7-1.08-0.56c-0.45,0.15-0.69,0.63-0.55,1.08
    		c0.11,0.36,0.45,0.59,0.81,0.59c0.09,0,0.18-0.01,0.27-0.03C7.37,64.75,7.62,64.26,7.47,63.81z"/>
    	<path style={{fill : color}}  id="XMLID_1074_" d="M14.82,78.95c-0.36,0.31-0.4,0.85-0.09,1.22c0.17,0.2,0.41,0.29,0.65,0.29c0.2,0,0.39-0.06,0.56-0.21
    		c0.36-0.31,0.39-0.85,0.09-1.21C15.71,78.69,15.17,78.64,14.82,78.95z"/>
    	<path style={{fill : color}}  id="XMLID_1075_" d="M5.52,59.96c0.46-0.09,0.76-0.55,0.67-1.01c-0.09-0.46-0.54-0.76-1-0.67c-0.46,0.09-0.76,0.54-0.68,1
    		c0.09,0.41,0.45,0.69,0.85,0.69C5.41,59.97,5.47,59.96,5.52,59.96z"/>
    	<path style={{fill : color}}  id="XMLID_1076_" d="M36.39,7.65c0.09,0,0.17-0.01,0.25-0.03c0.45-0.15,0.71-0.63,0.57-1.07c-0.14-0.45-0.62-0.71-1.07-0.57
    		c-0.45,0.14-0.7,0.62-0.57,1.07C35.69,7.42,36.02,7.65,36.39,7.65z"/>
    	<path style={{fill : color}}  id="XMLID_1077_" d="M81.01,81.76c-0.33,0.33-0.33,0.88,0,1.22c0.16,0.17,0.39,0.26,0.61,0.26c0.21,0,0.43-0.09,0.6-0.25
    		c0.33-0.33,0.34-0.87,0.01-1.22C81.89,81.44,81.35,81.44,81.01,81.76z"/>
    	<path style={{fill : color}}  id="XMLID_1078_" d="M83.96,79.89c-0.35-0.32-0.89-0.28-1.21,0.07c-0.32,0.34-0.29,0.89,0.06,1.21
    		c0.16,0.15,0.37,0.22,0.57,0.22c0.23,0,0.46-0.09,0.63-0.28C84.34,80.75,84.31,80.21,83.96,79.89z"/>
    	<path style={{fill : color}}  id="XMLID_1079_" d="M79.17,83.48c-0.36,0.31-0.39,0.85-0.08,1.21c0.17,0.19,0.4,0.29,0.64,0.29c0.21,0,0.4-0.07,0.57-0.21
    		c0.35-0.32,0.39-0.86,0.08-1.21C80.06,83.2,79.52,83.17,79.17,83.48z"/>
    </g>
    </svg>
  )
}
